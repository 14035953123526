import { useEffect, useRef, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  TableContainer,
  TableHead,
  Typography,
  Modal,
  Box,
} from '@mui/material';
// import { AdminPrivateRoutes } from 'routes/routeConstans';
import ReportIcon from 'assets/iconcomponents/reporticon';
// import CustomBarChart from '../CommonGraph/sideBar';
import Fulllogo from 'assets/png/cthiring_new_logo.png';
import Chart from 'assets/png/chart .svg';
import Print from 'assets/png/print-blue.svg';
import { useDebounce } from 'hooks';
import { getAllListData } from 'services/CommonServices';
import { REPORT_ENDPOINT } from 'utils/Constant';
import StackedBarChart from '../Common/barGraph';
import { ReportFilter } from '../Common/filter';
import CustomBarChart from '../Common/sideBar';
import moment from 'moment';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import Loader from 'components/loader';
import Paginate from 'components/Pagination/Pagination';
import CloseIcon from 'assets/png/close.svg';
import zoomIn from 'assets/png/zoomIn.svg';
import zoomOut from 'assets/png/zoom-out.svg';
import PngIcon from 'assets/iconcomponents/pngIcon';
import PdfExport from 'assets/iconcomponents/pdfExport';
import CustomBarChartNew from '../Common/clientWiseMonthly';

const Defaultparam = {
  fromDate: null,
  toDate: null,
  userId: null,
  branchId: null,
  type: null,
  report: null,
  order: null,
  tableSkip: 0,
  graphSkip: 0,
  sortby: null,
  status: null,
  tableTake: 10,
  graphTake: 10,
  organizationId: null,
  createdBy: null,
};
export const OpeningHandle = () => {
  const [loader, setLoader] = useState<any>(false);
  const [previewOpen, setPreviewOpen] = useState<any>(false);
  const downloadRef0 = useRef<HTMLDivElement>(null);
  // const zoomRef: any = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hideIcon, setHideIcon] = useState(false);

  const rangeToColor: any = {
    '0-10': 'blue',
    '10-30': 'red',
    '30-50': 'green',
    '50-100': 'purple',
    'above 100': 'yellow',
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [tableData, setTableData] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [roleData, setRoleData] = useState<any>();
  const [branchData, setBranchData] = useState<any>();
  const [graphTotal, setGraphTotal] = useState<any>();
  const [tableTotal, setTableTotal] = useState<any>();
  const [employeeData, setEmployeeData] = useState<any>();
  const [employeeName, setEmployeeName] = useState<any>([]);
  // const [zoomLevel, setZoomLevel] = useState(100); // Percentage zoom level
  const [scale, setScale] = useState(1); // Initial zoom level
  const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));

  const handleClose = () => {
    setPreviewOpen(false);
  };
  const PrintScreen = () => {
    window.print();
  };
  // const handleZoomIn = () => {
  //   if (zoomLevel < 200) {
  //     const newZoomLevel = zoomLevel + 25;
  //     setZoomLevel(newZoomLevel);

  //     if (zoomRef.current) {
  //       zoomRef.current.style.transform = `scale(${newZoomLevel / 100})`;
  //       zoomRef.current.style.transition = 'transform 0.3s ease';

  //       // Optionally, adjust scroll position to ensure content is fully visible
  //       zoomRef.current.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center', // Center the zoomed content vertically
  //       });
  //     }
  //   }
  // };

  // const handleZoomOut = () => {
  //   if (zoomLevel > 50) {
  //     const newZoomLevel = zoomLevel - 25;
  //     setZoomLevel(newZoomLevel);

  //     if (zoomRef.current) {
  //       zoomRef.current.style.transform = `scale(${newZoomLevel / 100})`;
  //       zoomRef.current.style.transition = 'transform 0.3s ease';

  //       // Optionally, adjust scroll position
  //       zoomRef.current.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //       });
  //     }
  //   }
  // };
  const listdataapi = () => {
    const searcharr = [
      `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
      `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
      `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
      `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
      `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
      `${debouncedParams.report ? `report=${debouncedParams.report}` : null}`,
      `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
      `${
        debouncedParams.tableSkip
          ? `tableSkip=${debouncedParams.tableSkip}`
          : debouncedParams.tableTake
            ? 'tableSkip=0'
            : null
      }`,
      `${debouncedParams.tableTake ? `tableTake=${debouncedParams.tableTake}` : null}`,
      `${
        debouncedParams.graphSkip
          ? `graphSkip=${debouncedParams.graphSkip}`
          : debouncedParams.graphTake
            ? 'graphSkip=0'
            : null
      }`,
      `${debouncedParams.graphTake ? `graphTake=${debouncedParams.graphTake}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoader(true);
    getAllListData(
      `${REPORT_ENDPOINT.openingHandled}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        setTableData(val.data);
        setGraphTotal(val.data.graphTotal);
        setTableTotal(val.data.tableTotal);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        function transformData(data: any) {
          return data.map((monthData: { month: any; data: any[] }) => {
            const transformedMonth: any = { name: monthData.month };
            monthData.data.forEach((item: any) => {
              transformedMonth[item.range] = item.openingHandled;
            });
            return transformedMonth;
          });
        }
        const transformedDatanew = val.data.graphData.map((company: any) => {
          // Start with a structure that matches the transformedData format
          const companyData: any = {
            name: company.corporateName,
            '0-10': 0,
            '10-30': 0,
            '30-50': 0,
            '50-100': 0,
            'above 100': 0,
          };

          // Fill in the values for each range based on the company's data
          company.data.forEach((rangeData: any) => {
            const colorKey = rangeToColor[rangeData.range]; // Get the color key based on the range
            if (colorKey) {
              companyData[colorKey] = rangeData.openingHandled; // Set the value for the corresponding color key
            }
          });

          return companyData;
        });
        const transformedDataNew = val.data.graphData.map((company: any) => {
          const companyData: any = { corporateName: company.corporateName };

          // Map through each range and assign openingHandled to the respective range
          company.data.forEach((entry: any) => {
            companyData[entry.monthRange] = entry.openingHandled;
          });

          return companyData;
        });
        const transformedData = transformData(val.data.graphData);
        // const transformedDataNew = transformedDataNew(val.data.graphData);
        setGraphData(
          debouncedParams.type === 'clientWise'
            ? transformedDatanew
            : debouncedParams.type === 'monthWise'
              ? transformedDataNew
              : transformedData,
        );
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log(err);
      });
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      tableSkip: sk,
      tableTake: tk,
    }));
  };

  useEffect(() => {
    listdataapi();
    branch();
  }, [debouncedParams]);
  const branch = () => {
    getAllListData(`${REPORT_ENDPOINT.branch}`, true)
      .then((res: any) => {
        setLoader(false);
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setBranchData(options);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  useEffect(() => {
    if (roleData) {
      setLoader(true);
      getAllListData(`${REPORT_ENDPOINT.employee}?reportRoles=${roleData}`, true)
        .then((res: any) => {
          const options: any = [];
          res.data.map((e: any) => {
            options.push({ label: e.name, value: e.id, fullName: e.nameWithRole });
          });
          if (roleData != 'recruiter') {
            options.push(
              roleData != 'recruiter' && res?.data?.length > 0
                ? { label: 'All', value: 'all', fullName: 'All' }
                : {},
            );
          }
          setEmployeeData(options);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  }, [roleData]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography color='text.primary'>
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Reports
            </Typography>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Opening Handled
            </Typography>
          </Breadcrumbs>
        </Typography>
        <ReportFilter
          setParams={setParams}
          setRoleData={setRoleData}
          tableData={setTableData}
          graphData={setGraphData}
          branchData={branchData}
          employeeData={employeeData}
          setEmployeeName={setEmployeeName}
          setPreviewOpen={setPreviewOpen}
          params={params}
        />
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            {!tableData || !graphData ? (
              <ReportIcon />
            ) : (
              <>
                {params.type === 'ctcWise' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <div ref={downloadRef0}>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              {params.type === 'ctcWise'
                                ? 'CTC Wise Monthly'
                                : params.type === 'clientWise'
                                  ? 'Client Wise CTC'
                                  : params.type === 'monthWise' && 'Client Wise Monthly'}
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <PngIcon
                              url={`reports/opening-handled/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=image',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'Opening Handled'}
                              setLoader={setLoader}
                            />
                            <PdfExport
                              url={`reports/opening-handled/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=pdf',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'Opening Handled'}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <StackedBarChart
                        data={graphData}
                        graphTotal={graphTotal}
                        setParams={setParams}
                      />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </div>
                  )}
                {params.type === 'clientWise' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              {params.type === 'ctcWise'
                                ? 'CTC Wise Monthly'
                                : params.type === 'clientWise'
                                  ? 'Client Wise CTC'
                                  : params.type === 'monthWise' && 'Client Wise Monthly'}
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <PngIcon
                              url={`reports/opening-handled/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=image',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'Opening Handled'}
                              setLoader={setLoader}
                            />
                            <PdfExport
                              url={`reports/opening-handled/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=pdf',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'Opening Handled'}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <CustomBarChart
                        data={graphData}
                        graphTotal={graphTotal}
                        setParams={setParams}
                      />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {params.type === 'monthWise' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              {params.type === 'ctcWise'
                                ? 'CTC Wise Monthly'
                                : params.type === 'clientWise'
                                  ? 'Client Wise CTC'
                                  : params.type === 'monthWise' && 'Client Wise Monthly'}
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <PngIcon
                              url={`reports/opening-handled/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=image',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'Opening Handled'}
                              setLoader={setLoader}
                            />
                            <PdfExport
                              url={`reports/opening-handled/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=pdf',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'Opening Handled'}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <CustomBarChartNew
                        data={graphData}
                        graphTotal={graphTotal}
                        setParams={setParams}
                      />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {(params.report === 'table' || params.report === 'all') && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className='d-flex flex-sb'
                        sx={{ marginTop: '25px' }}>
                        <Typography
                          component={'div'}
                          sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography component={'span'} className='report-title'>
                            {params.type === 'ctcWise'
                              ? 'CTC Wise Monthly'
                              : params.type === 'clientWise'
                                ? 'Client Wise CTC'
                                : params.type === 'monthWise' && 'Client Wise Monthly'}
                          </Typography>
                          <Typography
                            component={'span'}
                            className='report-title-name d-flex-cc'
                            sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                            {employeeName}
                          </Typography>
                        </Typography>
                        <Typography
                          component={'span'}
                          className='report-title-name'
                          sx={{ display: 'flex', alignItems: 'center' }}>
                          Timeline :{' '}
                          <Typography
                            component={'span'}
                            className='report-title'
                            sx={{ marginRight: '30px' }}>
                            {moment(params.fromDate).format('DD/MM/YYYY')} -
                            {moment(params.toDate).format('DD/MM/YYYY')}
                          </Typography>{' '}
                          <ExcelIcon
                            url={`reports/opening-handled/export${
                              Object.values({ ...params }).length > 0 && '?'
                            }${[
                              `${
                                debouncedParams.fromDate
                                  ? `fromDate=${debouncedParams.fromDate}`
                                  : null
                              }`,
                              `${
                                debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                              }`,
                              `${
                                debouncedParams.branchId
                                  ? `branchId=${debouncedParams.branchId}`
                                  : null
                              }`,
                              `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                              `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                              `${
                                debouncedParams.employee
                                  ? `userId=${debouncedParams.employee}`
                                  : null
                              }`,
                              'exportType=csv',
                              'report=table',
                            ]
                              .filter((ea: any) => ea !== 'null')
                              .join('&')}`}
                            fileName={'Opening Handled'}
                            setLoader={setLoader}
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                    <Typography component={'div'} className='mt-32'>
                      <TableContainer component={Paper}>
                        {params.type === 'ctcWise' || params.type === 'clientWise' ? (
                          <Table
                            sx={{ minWidth: 650 }}
                            className='custom-table'
                            aria-label='simple table'>
                            <TableHead>
                              <TableRow>
                                {tableData?.tableHeaders?.map((header: any) => (
                                  <TableCell sx={{ textTransform: 'capitalize' }} key={header}>
                                    {header === 'month'
                                      ? params.type === 'ctcWise'
                                        ? 'Month / CTC'
                                        : params.type === 'clientWise'
                                          ? 'Corporate Name / CTC'
                                          : 'Corporate Name / Month'
                                      : header}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tableData?.tableData && tableData?.tableData?.length ? (
                                tableData?.tableData?.map((row: any) => {
                                  return (
                                    <TableRow key={row.corporateName || row.month}>
                                      {/* Corporate Name or Month Cell */}
                                      <TableCell>
                                        {row.corporateName === 'totalOpeningHandled' ||
                                        row.month === 'totalOpeningHandled'
                                          ? 'Total Opening Handled'
                                          : row.corporateName || row.month || '-'}
                                      </TableCell>

                                      {/* Data Cells for each month or range */}
                                      {tableData.tableHeaders.slice(1, -1).map((header: any) => {
                                        // Adapt logic for both response structures
                                        const cellData = row.data.find(
                                          (item: any) =>
                                            (item.range && item.range === header) || // Handle existing response with "range"
                                            (item.month && item.month === header), // Handle new response with "month"
                                        );
                                        return (
                                          <TableCell key={header}>
                                            {cellData
                                              ? cellData.openingHandled || cellData.avgTaktTime || 0
                                              : 0}
                                          </TableCell>
                                        );
                                      })}

                                      {/* Total Cell */}
                                      <TableCell>
                                        {row.data.find(
                                          (item: any) =>
                                            item.range === 'total' || item.month === 'total',
                                        )?.openingHandled || 0}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan={tableData?.tableHeaders?.length}
                                    align='center'>
                                    No Record(s) Found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        ) : (
                          <Table
                            sx={{ minWidth: 650 }}
                            className='custom-table'
                            aria-label='simple table'>
                            {/* Table Header */}
                            <TableHead>
                              <TableRow>
                                {tableData?.tableHeaders?.map((header: string, index: number) => (
                                  <TableCell key={header} sx={{ textTransform: 'capitalize' }}>
                                    {index === 0 ? 'Corporate Name / CTC' : header}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {/* Table Body */}
                            <TableBody>
                              {tableData?.tableData?.length ? (
                                tableData.tableData.map((row: any, rowIndex: number) => (
                                  <TableRow key={row.corporateName || `row-${rowIndex}`}>
                                    {/* First Column: Corporate Name */}
                                    <TableCell>
                                      {row.corporateName === 'totalOpeningHandled'
                                        ? 'Total Opening Handled'
                                        : row.corporateName || '-'}
                                    </TableCell>

                                    {/* Data Columns */}
                                    {tableData.tableHeaders.slice(1, -1).map((header: string) => {
                                      const cellData = row.data.find(
                                        (item: any) => item.month === header,
                                      );
                                      return (
                                        <TableCell key={`${rowIndex}-${header}`}>
                                          {cellData ? cellData.openingHandled : 0}
                                        </TableCell>
                                      );
                                    })}

                                    {/* Total Column */}
                                    <TableCell>
                                      {row.data.find((item: any) => item.month === 'total')
                                        ?.openingHandled || 0}
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan={tableData?.tableHeaders?.length}
                                    align='center'>
                                    No Record(s) Found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        )}
                      </TableContainer>
                    </Typography>
                    <Grid container className='mt-24'>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex-a flex-end pagination'>
                          <Paginate total={tableTotal} setfn={handlePag} DefaultPerPage={10} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Typography>
        </Typography>
      </Grid>
      <Modal
        sx={{ zIndex: 1300 }}
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          className='email-modal'
          sx={{
            width: '100% !important',
            minHeight: '100vh !important',
            padding: '0px !important',
          }}>
          <Typography
            className='d-flex-a flex-sb'
            sx={{ backgroundColor: '#F2F4F7', height: '53px', padding: '20px' }}>
            <Typography component={'span'} className='report-title'>
              {'Opening Handled'}
            </Typography>
            <Typography component={'div'} className='d-flex-cc'>
              <img
                src={zoomIn}
                alt='zoomIn'
                style={{ width: '35px', height: '35px', marginRight: '25px', cursor: 'pointer' }}
                onClick={handleZoomIn}
              />
              <div
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#f0f0f0',
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}>
                {Math.round(scale * 100)}%
              </div>
              <img
                src={zoomOut}
                alt='zoomIn'
                style={{ width: '35px', height: '35px', marginLeft: '25px', cursor: 'pointer' }}
                onClick={handleZoomOut}
              />
            </Typography>
            <Typography component={'div'} className='d-flex'>
              <Typography component={'div'} className='d-flex'>
                <PngIcon
                  url={`reports/opening-handled/export${
                    Object.values({ ...params }).length > 0 && '?'
                  }${[
                    `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
                    `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
                    `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
                    `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                    `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                    `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
                    'exportType=image',
                    'report=graph',
                    `names=${employeeName.map((item: any) => item.replace(/^\(|\)$/g, ''))}`,
                  ]
                    .filter((ea: any) => ea !== 'null')
                    .join('&')}`}
                  setLoader={setLoader}
                />
                <PdfExport
                  url={`reports/opening-handled/export${
                    Object.values({ ...params }).length > 0 && '?'
                  }${[
                    `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
                    `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
                    `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
                    `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                    `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                    `${debouncedParams.report ? `report=${debouncedParams.report}` : null}`,
                    `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
                    'exportType=pdf',
                    'report=graph',
                    `names=${employeeName.map((item: any) => item.replace(/^\(|\)$/g, ''))}`,
                  ]
                    .filter((ea: any) => ea !== 'null')
                    .join('&')}`}
                  fileName={'Opening Handled'}
                  setLoader={setLoader}
                />
                <img
                  src={Print}
                  alt='Print'
                  style={{
                    width: '35px',
                    height: '35px',
                    marginRight: '20px',
                    marginLeft: '20px',
                  }}
                  onClick={PrintScreen}
                />
              </Typography>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Typography>
          </Typography>
          <Typography
            component={'div'}
            className='main-content'
            sx={{
              padding: '0px',
              overflow: 'auto',
              position: 'relative',
            }}>
            <Typography component={'div'} className='white-box'>
              {!tableData || !graphData ? (
                <ReportIcon />
              ) : (
                <>
                  {params.type === 'ctcWise' &&
                    (params.report === 'graph' || params.report === 'all') && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',
                          overflow: 'auto',
                          margin: '0 auto',
                          transform: `scale(${scale})`,
                          transition: 'transform 0.3s ease',
                        }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex flex-sb'>
                            <img
                              src={Fulllogo}
                              alt='Carrertree'
                              style={{ width: '142px', height: '21.25px' }}
                            />
                            <Typography
                              component={'div'}
                              sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography component={'span'} className='report-title'>
                                {params.type === 'ctcWise'
                                  ? 'CTC Wise Monthly'
                                  : params.type === 'clientWise'
                                    ? 'Client Wise CTC'
                                    : params.type === 'monthWise' && 'Client Wise Monthly'}
                              </Typography>
                              <Typography
                                component={'span'}
                                className='report-title-name'
                                sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                                {employeeName}
                              </Typography>
                            </Typography>
                            <Typography
                              component={'span'}
                              className='page-title d-flex-cc'
                              style={{ marginRight: '25px' }}>
                              <img
                                src={Chart}
                                alt='Chart'
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              />
                              Graph & Table View
                            </Typography>
                          </Typography>
                          <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                        </Grid>
                        <StackedBarChart
                          data={graphData}
                          graphTotal={graphTotal}
                          setParams={setParams}
                        />
                        <Grid item md={12} sm={12} xs={12}>
                          <Divider
                            sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                          />
                        </Grid>
                      </div>
                    )}
                  {params.type === 'clientWise' &&
                    (params.report === 'graph' || params.report === 'all') && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',
                          overflow: 'auto',
                          margin: '0 auto',
                          transform: `scale(${scale})`,
                          transition: 'transform 0.3s ease',
                        }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex flex-sb'>
                            <img
                              src={Fulllogo}
                              alt='Carrertree'
                              style={{ width: '142px', height: '21.25px' }}
                            />
                            <Typography
                              component={'div'}
                              sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography component={'span'} className='report-title'>
                                {'Client Wise CTC'}
                              </Typography>
                              <Typography
                                component={'span'}
                                className='report-title-name'
                                sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                                {employeeName}
                              </Typography>
                            </Typography>
                            <Typography
                              component={'span'}
                              className='page-title d-flex-cc'
                              style={{ marginRight: '25px' }}>
                              <img
                                src={Chart}
                                alt='Chart'
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              />
                              Graph & Table View
                            </Typography>
                          </Typography>
                          <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                        </Grid>
                        <CustomBarChart
                          data={graphData}
                          graphTotal={graphTotal}
                          setParams={setParams}
                        />
                        <Grid item md={12} sm={12} xs={12}>
                          <Divider
                            sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                          />
                        </Grid>
                      </div>
                    )}
                  {params.type === 'monthWise' &&
                    (params.report === 'graph' || params.report === 'all') && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',
                          overflow: 'auto',
                          margin: '0 auto',
                          transform: `scale(${scale})`,
                          transition: 'transform 0.3s ease',
                        }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex flex-sb'>
                            <img
                              src={Fulllogo}
                              alt='Carrertree'
                              style={{ width: '142px', height: '21.25px' }}
                            />
                            <Typography
                              component={'div'}
                              sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography component={'span'} className='report-title'>
                                {'Client Wise Monthly'}
                              </Typography>
                              <Typography
                                component={'span'}
                                className='report-title-name'
                                sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                                {employeeName}
                              </Typography>
                            </Typography>
                            <Typography
                              component={'span'}
                              className='page-title d-flex-cc'
                              style={{ marginRight: '25px' }}>
                              <img
                                src={Chart}
                                alt='Chart'
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              />
                              Graph & Table View
                            </Typography>
                          </Typography>
                          <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                        </Grid>
                        <CustomBarChartNew
                          data={graphData}
                          graphTotal={graphTotal}
                          setParams={setParams}
                        />
                        <Grid item md={12} sm={12} xs={12}>
                          <Divider
                            sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                          />
                        </Grid>
                      </div>
                    )}
                  {(params.report === 'table' || params.report === 'all') && (
                    <div>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography
                          component={'div'}
                          className='d-flex flex-sb'
                          sx={{ marginTop: '25px' }}>
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              {params.type === 'ctcWise'
                                ? 'CTC Wise Monthly'
                                : params.type === 'clientWise'
                                  ? 'Client Wise CTC'
                                  : params.type === 'monthWise' && 'Client Wise Monthly'}
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='report-title-name'
                            sx={{ display: 'flex', alignItems: 'center' }}>
                            Timeline :{' '}
                            <Typography
                              component={'span'}
                              className='report-title'
                              sx={{ marginRight: '30px' }}>
                              {' '}
                              {moment(params.fromDate).format('DD/MM/YYYY')} -
                              {moment(params.toDate).format('DD/MM/YYYY')}
                            </Typography>
                            <ExcelIcon
                              url={`reports/opening-handled/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=csv',
                                'report=table',
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'Opening Handled'}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                      </Grid>
                      <Typography component={'div'} className='mt-32'>
                        <TableContainer component={Paper}>
                          {params.type === 'ctcWise' || params.type === 'clientWise' ? (
                            <Table
                              sx={{ minWidth: 650 }}
                              className='custom-table'
                              aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  {tableData?.tableHeaders?.map((header: any) => (
                                    <TableCell sx={{ textTransform: 'capitalize' }} key={header}>
                                      {header === 'month'
                                        ? params.type === 'ctcWise'
                                          ? 'Month / CTC'
                                          : params.type === 'clientWise'
                                            ? 'Corporate Name / CTC'
                                            : 'Corporate Name / Month'
                                        : header}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tableData?.tableData && tableData?.tableData?.length ? (
                                  tableData?.tableData?.map((row: any) => {
                                    return (
                                      <TableRow key={row.corporateName || row.month}>
                                        {/* Corporate Name or Month Cell */}
                                        <TableCell>
                                          {row.corporateName === 'totalOpeningHandled'
                                            ? 'Total Opening Handled'
                                            : row.corporateName || row.month || '-'}
                                        </TableCell>

                                        {/* Data Cells for each month or range */}
                                        {tableData.tableHeaders.slice(1, -1).map((header: any) => {
                                          // Adapt logic for both response structures
                                          const cellData = row.data.find(
                                            (item: any) =>
                                              (item.range && item.range === header) || // Handle existing response with "range"
                                              (item.month && item.month === header), // Handle new response with "month"
                                          );
                                          return (
                                            <TableCell key={header}>
                                              {cellData
                                                ? cellData.openingHandled ||
                                                  cellData.avgTaktTime ||
                                                  0
                                                : 0}
                                            </TableCell>
                                          );
                                        })}

                                        {/* Total Cell */}
                                        <TableCell>
                                          {row.data.find(
                                            (item: any) =>
                                              item.range === 'total' || item.month === 'total',
                                          )?.openingHandled || 0}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      colSpan={tableData?.tableHeaders?.length}
                                      align='center'>
                                      No Record(s) Found.
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          ) : (
                            <Table
                              sx={{ minWidth: 650 }}
                              className='custom-table'
                              aria-label='simple table'>
                              {/* Table Header */}
                              <TableHead>
                                <TableRow>
                                  {tableData?.tableHeaders?.map((header: string, index: number) => (
                                    <TableCell key={header} sx={{ textTransform: 'capitalize' }}>
                                      {index === 0 ? 'Corporate Name / CTC' : header}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {/* Table Body */}
                              <TableBody>
                                {tableData?.tableData?.length ? (
                                  tableData.tableData.map((row: any, rowIndex: number) => (
                                    <TableRow key={row.corporateName || `row-${rowIndex}`}>
                                      {/* First Column: Corporate Name */}
                                      <TableCell>
                                        {row.corporateName === 'totalOpeningHandled'
                                          ? 'Total Opening Handled'
                                          : row.corporateName || '-'}
                                      </TableCell>

                                      {/* Data Columns */}
                                      {tableData.tableHeaders.slice(1, -1).map((header: string) => {
                                        const cellData = row.data.find(
                                          (item: any) => item.month === header,
                                        );
                                        return (
                                          <TableCell key={`${rowIndex}-${header}`}>
                                            {cellData ? cellData.openingHandled : 0}
                                          </TableCell>
                                        );
                                      })}

                                      {/* Total Column */}
                                      <TableCell>
                                        {row.data.find((item: any) => item.month === 'total')
                                          ?.openingHandled || 0}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      colSpan={tableData?.tableHeaders?.length}
                                      align='center'>
                                      No Record(s) Found.
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </Typography>
                      <Grid container className='mt-24'>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex-a flex-end pagination'>
                            <Paginate total={tableTotal} setfn={handlePag} DefaultPerPage={10} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </>
              )}
            </Typography>
          </Typography>
        </Box>
      </Modal>
    </Grid>
  );
};
