import { Box, Modal, Typography, Grid, Button, NativeSelect } from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { WriteOffSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import { BillingENDPOINT } from 'utils/Constant';
import { addNewData } from 'services/CommonServices';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

export const WriteOff = ({
  open,
  setWriteOffModalOpen,
  setLd,
  ids,
  getList,
  handleClosePop,
}: any) => {
  const {
    control: control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      writeOff: '',
      amount: '',
      amountCurrency: '',
      reason: '',
      toMail: '',
      ccMail: '',
      mailSubject: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(WriteOffSchema),
  });
  const handleClose = () => {
    setWriteOffModalOpen(false);
    reset();
  };
  const onSubmitForm = (data: any) => {
    const obj: any = {
      type: 'writeoff',
      resumeId: ids.resumeId,
      billingId: ids.billingId,
      writeOff: data.writeOff,
      reason: data.reason,
      amount: data.amount ? parseInt(String(data.amount).replace(/,/g, '')) : 0,
      amountCurrency: data.amountCurrency,
      to: data.toMail,
      cc: data.ccMail,
      subject: data.mailSubject,
      status: true,
    };
    addNewData(obj, `${BillingENDPOINT.billingPayment}`, {}, true)
      .then(() => {
        handleClose();
        setLd(false);
        getList();
        handleClosePop();
      })
      .catch((e: any) => {
        setLd(false);
        console.log(e);
      });
  };
  return (
    <Modal
      sx={{ zIndex: 11000 }}
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box className='email-modal' sx={{ width: '865px !important' }}>
        <Typography className='d-flex flex-sb '>
          <Typography className='l-title'>Write Off</Typography>
          <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={handleClose} />
        </Typography>
        <Typography className='bdiveder' />
        <div>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <form
                  className='private-form'
                  id={'writeOff-add'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <div style={{ height: '10px' }}></div>
                  <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container columnSpacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Write Off<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={'writeOff'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Write Off'
                                    error={errors.writeOff?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Amount<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={''}
                              name={'amount'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  // <CustomInput
                                  //   value={value}
                                  //   name={name}
                                  //   type={'number'}
                                  //   className='custom-input'
                                  //   placeHolder='Amount'
                                  //   error={errors.amount?.message}
                                  //   onChange={(e) => {
                                  //     onChange(e);
                                  //   }}
                                  // />
                                  <CustomInput
                                    className='custom-input'
                                    onChange={(e) => {
                                      const rawValue = e.target.value.replace(/[^\d.]/g, '');
                                      const numericValue = parseFloat(rawValue) || 0;
                                      const formatCurrency = (
                                        value: any,
                                        locale: any,
                                        currency: string,
                                      ) =>
                                        new Intl.NumberFormat(locale, {
                                          currency,
                                          maximumFractionDigits: 0,
                                        }).format(value);

                                      const targetCurrency: any = getValues('amountCurrency')
                                        ? getValues('amountCurrency').toLocaleUpperCase()
                                        : 'INR';
                                      const currencySettings: any = {
                                        USD: { locale: 'en-US', currency: 'USD' },
                                        INR: { locale: 'en-IN', currency: 'INR' },
                                        AED: { locale: 'ar-AE', currency: 'AED' },
                                      };
                                      const { locale, currency } = currencySettings[targetCurrency];
                                      const formattedValue = formatCurrency(
                                        numericValue,
                                        locale,
                                        currency,
                                      );
                                      onChange(formattedValue);
                                    }}
                                    value={value}
                                    name={name}
                                    placeHolder='Amount'
                                    currency
                                    // type={'number'}
                                    error={errors.amount?.message}
                                    customComponent={
                                      <Box
                                        sx={{
                                          minWidth: 120,
                                          position: 'absolute',
                                          right: '-40px',
                                          transform: 'scale(0.9)',
                                        }}>
                                        <Controller
                                          control={control}
                                          name='amountCurrency'
                                          render={({ field: { value, name } }) => {
                                            return (
                                              <NativeSelect
                                                // defaultValue={getValues('minCurrency')}
                                                value={value}
                                                name={name}
                                                onChange={(e: any) => {
                                                  if (e.target.value !== value) {
                                                    setValue('amount', '');
                                                  }
                                                  setValue('amountCurrency', e.target.value);
                                                }}
                                                // disabled
                                                className='position-currency-option'
                                                IconComponent={ExpandMoreRoundedIcon}
                                                sx={{
                                                  border: 'none !important',
                                                  minHeight: '20px !important',
                                                  '&::before': {
                                                    borderBottom: 'transparent !important',
                                                  },
                                                  '& > select': {
                                                    minWidth: '40px !important',
                                                    border: 'none !important',
                                                    outline: 'none !important',
                                                    marginRight: '8px',
                                                  },
                                                }}>
                                                <option value={'inr'}>INR</option>
                                                <option value={'usd'}>USD</option>
                                                <option value={'aed'}>AED</option>
                                              </NativeSelect>
                                            );
                                          }}
                                        />
                                      </Box>
                                    }
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Reason for Write Off<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='reason'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Reason for Write Off'
                                    error={errors.reason?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              To Mail<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='toMail'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='To mail'
                                    error={errors.toMail?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              CC Mail<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='ccMail'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='CC Mail'
                                    error={errors.ccMail?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>
                              Mail Subject<span>*</span>
                            </Typography>
                            <Controller
                              control={control}
                              defaultValue={undefined}
                              name='mailSubject'
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    value={value}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Mail Subject'
                                    error={errors.mailSubject?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Typography>
            </Grid>
          </Grid>
          <Typography className='bdiveder' />
          <Typography className='d-flex flex-end  mt-25'>
            <Button className='l-cancel-btn mr-16' onClick={handleClose}>
              Cancel
            </Button>
            <Button className='l-save-btn' type='submit' form={'writeOff-add'}>
              Submit
            </Button>
          </Typography>
        </div>
      </Box>
    </Modal>
  );
};
