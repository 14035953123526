import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import { FILE_ENDPOINT } from 'utils/Constant';
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from 'utils/Handlers';

export const getSingleData = async (
  id: string,
  endpoint: string,
  toastShow?: boolean,
  options?: any,
) => {
  const apiParams = {
    url: id !== '' ? `${endpoint}?id=${id}` : endpoint,
  };

  return await getRequest(apiParams, options)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error?.error?.status === 400) {
        if (toastShow) {
          toast.error(error?.message || error?.error?.message || String(error), alertOptions);
        }
        errorMessage = error?.message || error?.error?.message || String(error);
      }
      if (error?.error?.data?.message === 'Forbidden resource') {
        errorMessage = error?.error?.data?.message || String(error);
      }
      throw errorMessage || error?.message || error?.error?.message ||'Something went wrong';
    });
};

export const getAllListData = async (endpoint: string, toastShow?: boolean, options?: any) => {
  const apiParams = {
    url: endpoint,
  };
  return await getRequest(apiParams, options)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error?.error?.status === 400) {
        if (toastShow) {
          toast.error(
            error?.message ||
              (Array.isArray(error?.error?.data?.message)
                ? error?.error?.data?.message[0]
                : error?.error?.message) ||
              String(error),
            alertOptions,
          );
        }
        errorMessage =
          error?.message ||
          (Array.isArray(error?.error?.data?.message)
            ? error?.error?.data?.message[0]
            : error?.error?.message) ||
          String(error);
      }

      if (error?.error?.data?.message === 'Forbidden resource') {
        errorMessage = error?.error?.data?.message || String(error);
      }
      throw errorMessage || error?.message || error?.error?.message ||'Something went wrong';
    });
};

export const addNewData = async (
  data: any,
  endpoint: string,
  options?: any,
  toastShow?: boolean,
) => {
  const apiParams = {
    data,
    url: endpoint,
  };

  return await postRequest(apiParams, options)
    .then((response: any) => {
      if (toastShow && response?.status !== 400) {
        toast.success(response.message, alertOptions);
      }
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error?.error?.status === 400) {
        if (toastShow) {
          toast.error(
            error?.message ||
              (Array.isArray(error?.error?.data?.message)
                ? error?.error?.data?.message[0]
                : error?.error?.message) ||
              String(error),
            alertOptions,
          );
        }
        errorMessage =
          error?.message ||
          (Array.isArray(error?.error?.data?.message)
            ? error?.error?.data?.message[0]
            : error?.error?.message) ||
          String(error);
      }

      if (error?.error?.status === 403 && error?.error?.data?.message === 'Forbidden resource') {
        errorMessage = error?.error?.data?.message || String(error);
      }
      throw errorMessage || error?.message || error?.error?.message ||'Something went wrong';
    });
};

export const updateData = async (
  id: string,
  data: any,
  endpoint: string,
  toastShow?: boolean,
  options?: any,
) => {
  const apiParams = {
    data,
    url: id !== '' ? `${endpoint}/${id}` : `${endpoint}`,
  };

  return await putRequest(apiParams, options)
    .then((response: any) => {
      if (toastShow) {
        toast.success(response.message, alertOptions);
      }
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error?.error?.status === 400) {
        if (toastShow) {
          toast.error(
            error?.message ||
              (Array.isArray(error?.error?.data?.message)
                ? error?.error?.data?.message[0]
                : error?.error?.message) ||
              String(error),
            alertOptions,
          );
        }
        errorMessage =
          error?.message ||
          (Array.isArray(error?.error?.data?.message)
            ? error?.error?.data?.message[0]
            : error?.error?.message) ||
          String(error);
      }
      if (error?.error?.status === 403 && error?.error?.data?.message === 'Forbidden resource') {
        errorMessage = error?.error?.data?.message || String(error);
      }
      throw errorMessage || error?.message || error?.error?.message  ||'Something went wrong';
    });
};

export const patchData = async (
  id: string,
  data: any,
  endpoint: string,
  toastShow?: boolean,
  options?: any,
) => {
  const apiParams = {
    data,
    url: id !== '' ? `${endpoint}/${id}` : `${endpoint}`,
  };

  return await patchRequest(apiParams, options)
    .then((response: any) => {
      if (toastShow) {
        toast.success(response.message, alertOptions);
      }
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error?.error?.status === 400) {
        if (toastShow) {
          toast.error(
            error?.message ||
              (Array.isArray(error?.error?.data?.message)
                ? error?.error?.data?.message[0]
                : error?.error?.message) ||
              String(error),
            alertOptions,
          );
        }
        errorMessage =
          error?.message ||
          (Array.isArray(error?.error?.data?.message)
            ? error?.error?.data?.message[0]
            : error?.error?.message) ||
          String(error);
      }
      if (error?.error?.status === 403 && error?.error?.data?.message === 'Forbidden resource') {
        errorMessage = error?.error?.data?.message || String(error);
      }
      throw errorMessage || error?.message || error?.error?.message  ||'Something went wrong';
    });
};

export const deleteData = async (id: string, endpoint: string, toastShow?: boolean) => {
  const apiParams = {
    url: id ? `${endpoint}/${id}` : `${endpoint}`,
  };

  return await deleteRequest(apiParams)
    .then((response: any) => {
      if (toastShow) {
        toast.success(response.message, alertOptions);
      }
      return response;
    })
    .catch((error: any) => {
      let errorMessage = '';
      if (error?.error?.status === 400) {
        if (toastShow) {
          toast.error(error?.message || error?.error?.message || String(error), alertOptions);
        }
        errorMessage = error?.message || error?.error?.message || String(error);
      }
      if (error?.error?.status === 403 && error?.error?.data?.message === 'Forbidden resource') {
        errorMessage = error?.error?.data?.message || String(error);
      }
      throw errorMessage || error?.message || error?.error?.message  ||'Something went wrong';
    });
};

export const getS3file = async (key: any, url: string) => {
  const fileCheck = checkContentType(key.key);
  key.contentType = fileCheck;
  return await addNewData(key, url, { responseType: 'blob' }, true);
};

export const getS3ApiFile = async (id: string) => {
  return await getSingleData('', `${FILE_ENDPOINT.files}/${id}`, true, {
    responseType: 'blob',
  }).then((fileRes: any) => {
    return URL.createObjectURL(fileRes);
  });
};

export const checkContentType = (fileKey: any) => {
  if (fileKey) {
    const formate = fileKey.split('.').pop();
    const fileFormat = formate ? formate.toLowerCase() : '';
    switch (fileFormat) {
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'pdf':
        return 'application/pdf';
      case 'csv':
        return 'text/csv';
      case 'doc':
        return 'application/msword';
      case 'rtf':
        return 'application/rtf';
      case 'txt':
        return 'text/plain';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'bmp':
        return 'image/bmp';
      case 'gif':
        return 'image/gif';
      case 'ico':
        return 'image/vnd.microsoft.icon';
      case 'jpeg' || 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'svg':
        return 'image/svg+xml';
      case 'tif' || 'tiff':
        return 'image/tiff';
      case 'webp':
        return 'image/webp';
      default:
        return '';
    }
  }
};
