/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import { To, useNavigate, useSearchParams } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { BillingENDPOINT, FILE_ENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import _ from 'lodash';
import saveAs from 'file-saver';
import moment from 'moment';

export const ClientAgreementView: any = () => {
  const navigate = useNavigate();
  const [clientData, setclientData] = useState<any[]>([]);
  const [termsAndConditions, setTermsAndConditions] = useState<any>({
    professionalFee: [],
    PaymentTC: [],
    replacementClause: [],
  });
  const [showTableTab1, setShowTableTab1] = useState<any>({
    allLevel: false,
    levelWise: false,
    ctcWise: false,
  });
  const [showTableTab3, setShowTableTab3] = useState<any>({
    allLevel: false,
    levelWise: false,
    ctcWise: false,
  });
  const [fileId, setFileId] = useState<any[]>();
  const [isLoading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  useEffect(() => {
    getAllListData(`${BillingENDPOINT.clientAgreement}?id=${searchParams.get('id')}`, true)
      .then((val: any) => {
        if (val && val.data) {
          const data = val?.data[0];
          const professionalFeesDetails = val?.data[0]?.clientsAgreement?.filter(
            (e: any) => e?.section === 'professionalFee',
          );
          setFileId(
            professionalFeesDetails[0]?.attachmentId
              ? professionalFeesDetails[0]?.attachmentId
              : '',
          );
          const differenceInDays =
            Number(new Date(professionalFeesDetails[0]?.agreementEndDate)) -
            Number(new Date(professionalFeesDetails[0]?.agreementStartDate));
          const corporateDetails = [
            {
              key: 'Client Name',
              value: data?.corporateName,
            },
            {
              key: 'Project Type',
              value: professionalFeesDetails[0]?.projectType,
            },
            // {
            //   key: 'Agreement Type',
            //   value: 'Purchase Order',
            // },
            {
              key: 'Year of Tennure',
              value: `${(differenceInDays / (1000 * 60 * 60 * 24 * 365.25)).toFixed(2)} Year`,
            },
            {
              key: 'From Date',
              value: moment(professionalFeesDetails[0]?.agreementStartDate).format('DD-MM-YYYY'),
            },
            {
              key: 'To Date',
              value: moment(professionalFeesDetails[0]?.agreementEndDate).format('DD-MM-YYYY'),
            },
            { key: 'Attachment', value: 'Download Attachment' },
          ];
          setclientData(corporateDetails);

          // professional Tab //
          const agreementProfessionalFee: any = [];
          const final = data?.clientsAgreement?.find((e: any) => e?.section === 'professionalFee');
          const acrossAllLevel =
            final && final?.designations?.find((e: any) => e?.type === 'acrossAllLevel');
          const levelWise =
            final && final?.designations?.filter((e: any) => e?.type === 'levelWise');
          const ctcWise = final && final?.designations?.filter((e: any) => e?.type === 'ctcWise');

          setShowTableTab1((prev: any) => ({
            ...prev,
            allLevel: acrossAllLevel ? true : false,
            levelWise: levelWise?.length > 0 ? true : false,
            ctcWise: ctcWise?.length > 0 ? true : false,
          }));

          acrossAllLevel &&
            agreementProfessionalFee.push(
              {
                key: 'Across All Level',
                value: 'Yes',
              },

              {
                key: 'Percentage %',
                value: acrossAllLevel?.percentage?.percentage
                  ? `${Number(acrossAllLevel?.percentage?.percentage).toFixed(0)} %`
                  : '-',
              },
            );

          levelWise &&
            agreementProfessionalFee?.push(
              ...levelWise.map((re: any) => ({
                value: 'Yes',
                levelfrom: re?.levelFrom?.level,
                levelto: re?.levelTo?.level,
                percent: re?.percentage?.percentage,
              })),
            );
          ctcWise &&
            agreementProfessionalFee?.push(
              ...ctcWise.map((re: any) => ({
                value: 'Yes',
                ctcWisefrom: re?.ctcFrom?.ctc,
                ctcWiseto: re?.ctcTo?.ctc,
                percent: re?.percentage?.percentage,
              })),
            );

          // payment tab //
          const agreementPayment: any = [];
          const paymentTab = data?.clientsAgreement?.find((e: any) => e?.section === 'paymentTC');
          agreementPayment.push({
            key: 'No of Days',
            value: paymentTab?.paymentDays,
          });

          // Replacement Tab //
          const agreementReplacement: any = [];
          const replacmentTab = data?.clientsAgreement?.find(
            (e: any) => e?.section === 'replacementClause',
          );

          const replaceAcrossAllLevel =
            replacmentTab &&
            replacmentTab?.designations?.find((e: any) => e?.type === 'acrossAllLevel');

          const replaceLevelWise =
            replacmentTab &&
            replacmentTab?.designations?.filter((e: any) => e?.type === 'levelWise');
          const replaceCtcWise =
            replacmentTab && replacmentTab?.designations?.filter((e: any) => e?.type === 'ctcWise');

          setShowTableTab3((prev: any) => ({
            ...prev,
            allLevel: replaceAcrossAllLevel ? true : false,
            levelWise: replaceLevelWise?.length > 0 ? true : false,
            ctcWise: replaceCtcWise?.length > 0 ? true : false,
          }));

          replaceAcrossAllLevel &&
            agreementReplacement?.push(
              {
                key: 'Across All Level',
                value: 'Yes',
              },

              {
                key: 'No of Days',
                value: replaceAcrossAllLevel?.days?.days ? replaceAcrossAllLevel?.days?.days : '-',
              },
            );

          replaceLevelWise &&
            agreementReplacement?.push(
              ...replaceLevelWise.map((re: any) => ({
                value: 'Yes',
                levelfrom: re?.levelFrom?.level,
                levelto: re?.levelTo?.level,
                percent: re?.days?.days,
              })),
            );
          replaceCtcWise &&
            agreementReplacement.push(
              ...replaceCtcWise.map((re: any) => ({
                value: 'Yes',
                ctcWisefrom: re?.ctcFrom?.ctc,
                ctcWiseto: re?.ctcTo?.ctc,
                percent: re?.days?.days,
              })),
            );

          setTermsAndConditions({
            professionalFee: agreementProfessionalFee ? agreementProfessionalFee : [],
            PaymentTC: agreementPayment ? agreementPayment : [],
            replacementClause: agreementReplacement ? agreementReplacement : [],
          });
        }
        setLoading(!true);
      })
      .catch(() => {
        setLoading(!true);
      });
  }, [searchParams.get('id')]);
  const downloadAttachment = () => {
    setLoading(true);
    getAllListData(`${FILE_ENDPOINT.files}/${fileId}`, true, {
      responseType: 'blob',
    })
      .then((res) => {
        const currentDate = new Date();
        saveAs(res, `Client_Agreement_${currentDate}`, { autoBom: true });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  console.log(termsAndConditions);

  return (
    <Grid container>
      {isLoading && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography
              color='text.primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => gotoNavigate(AdminPrivateRoutes.CLIENTAGREEMENT.CLIENTAGREEMENTLIST)}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Agreements
            </Typography>
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> View Agreement
            </Typography>
          </Breadcrumbs>
        </Typography>
        <Typography
          component={'div'}
          className='d-flex-a flex-sb title-add-box'
          sx={{ paddingRight: '56px' }}>
          <Typography className='page-title'>View Agreement</Typography>
          <Button
            className='cancel-btn mr-16'
            onClick={() => gotoNavigate(AdminPrivateRoutes.CLIENTAGREEMENT.CLIENTAGREEMENTLIST)}>
            Back
          </Button>
        </Typography>
        <Typography
          component={'div'}
          className='main-content view-position'
          style={{ padding: '20px' }}>
          <Typography component={'div'} className='white-box view-position-white-box'>
            <Typography
              sx={{ fontWeight: 600, fontSize: '16px', color: '#344051', marginBottom: '19px' }}>
              Client Information
            </Typography>
            <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />

            {clientData ? (
              <Grid container md={12} sm={12} xs={12}>
                {!_.isEmpty(clientData) &&
                  clientData?.map((e, i) => (
                    <Grid md={3} sm={3} xs={3} key={i}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: 1.5,
                          display: 'flex',
                          justifyContent: 'flex-start',
                          color: '#191632',
                        }}>
                        {e.key}
                      </Typography>
                      {e.value === 'Download Attachment' ? (
                        <a
                          style={{ color: '#5F2EE5', cursor: 'pointer' }}
                          onClick={() => {
                            downloadAttachment();
                          }}>
                          {e.value}{' '}
                        </a>
                      ) : (
                        <Typography
                          sx={{
                            marginBottom: '24px',
                            fontSize: '14px !important',
                            fontWeight: 400,
                            lineHeight: 2.5,
                            display: 'flex',
                            justifyContent: 'flex-start',
                            color: '#637083',
                            textTransform: 'capitalize',
                          }}>
                          {e.value ? e.value : '--'}
                        </Typography>
                      )}
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <div style={{ fontSize: '14px', display: 'flex', justifyContent: 'center' }}>
                No Data Found
              </div>
            )}
            <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
            <Typography
              sx={{ fontWeight: 600, fontSize: '16px', color: '#344051', marginBottom: '19px' }}>
              Terms & Conditions
            </Typography>
            <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
            {showTableTab1.allLevel &&
              !_.isEmpty(termsAndConditions?.professionalFee) &&
              termsAndConditions?.professionalFee?.map(({ data, index }: any) => (
                <>
                  <Grid container md={12} sm={12} xs={12} key={index}>
                    <Grid md={12} sm={3} xs={3}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '14px',
                          color: '#344051',
                          marginBottom: '19px',
                        }}>
                        Professional Fee
                      </Typography>
                    </Grid>
                    <Grid md={4} sm={3} xs={3} sx={{ display: 'flex' }}>
                      {!_.isEmpty(termsAndConditions?.professionalFee) &&
                        termsAndConditions?.professionalFee?.map((e: any, i: any) => (
                          <TableContainer key={i}>
                            <Table className='custom-table' aria-label='simple table'>
                              <TableHead>
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell
                                    align='left'
                                    sx={{
                                      textTransform: 'capitalize',
                                      fontWeight: '600',
                                      borderRight: '1px solid #E4E7EC',
                                      minWidth: '200px',
                                    }}>
                                    {e?.key}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                    {e?.value ? e?.value : '--'}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ))}
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginBottom: '30px', marginTop: '25px' }} />
                </>
              ))}
            {showTableTab1?.levelWise && !_.isEmpty(termsAndConditions?.professionalFee) && (
              <>
                <Grid md={12} sm={3} xs={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      color: '#344051',
                      marginBottom: '19px',
                    }}>
                    Professional Fee
                  </Typography>
                </Grid>
                <TableContainer>
                  <Table className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Level Wise
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Level Wise (From)
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Level Wise (To)
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Percentage %
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(termsAndConditions.professionalFee) &&
                        termsAndConditions.professionalFee.map((e: any, i: any) => (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            key={i}>
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.value ? e?.value : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.levelfrom ? e?.levelfrom : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.levelto ? e?.levelto : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.percent ? e?.percent : '--'}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider sx={{ marginBottom: '30px', marginTop: '25px' }} />
              </>
            )}
            {showTableTab1.ctcWise && !_.isEmpty(termsAndConditions.professionalFee) && (
              <>
                <Grid md={12} sm={3} xs={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      color: '#344051',
                      marginBottom: '19px',
                    }}>
                    Professional Fee
                  </Typography>
                </Grid>
                <TableContainer>
                  <Table className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          CTC Wise
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          CTC Wise (From)
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          CTC Wise (To)
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Percentage %
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(termsAndConditions.professionalFee) &&
                        termsAndConditions.professionalFee.map((e: any, i: any) => (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            key={i}>
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.value ? e?.value : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.ctcWisefrom ? e?.ctcWisefrom : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.ctcWiseto ? e?.ctcWiseto : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.percent ? e?.percent : '--'}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider sx={{ marginBottom: '30px', marginTop: '25px' }} />
              </>
            )}
            {!_.isEmpty(termsAndConditions.PaymentTC) &&
              termsAndConditions.PaymentTC?.map(({ data, index }: any) => (
                <>
                  <Grid container md={12} sm={12} xs={12} key={index} className='mt-20'>
                    <Grid md={12} sm={3} xs={3}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '14px',
                          color: '#344051',
                          marginBottom: '19px',
                        }}>
                        Payment T&C
                      </Typography>
                    </Grid>
                    <Grid md={4} sm={3} xs={3} sx={{ display: 'flex' }}>
                      {!_.isEmpty(termsAndConditions?.PaymentTC) &&
                        termsAndConditions?.PaymentTC?.map((e: any, i: any) => (
                          <TableContainer key={i}>
                            <Table className='custom-table' aria-label='simple table'>
                              <TableHead>
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell
                                    align='left'
                                    sx={{
                                      textTransform: 'capitalize',
                                      fontWeight: '600',
                                      borderRight: '1px solid #E4E7EC',
                                      minWidth: '200px',
                                    }}>
                                    {e?.key}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                    {e?.value ? e?.value : '--'}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ))}
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginBottom: '30px', marginTop: '25px' }} />
                </>
              ))}
            {showTableTab3?.allLevel &&
              !_.isEmpty(termsAndConditions?.replacementClause) &&
              termsAndConditions?.replacementClause?.map(({ data, index }: any) => (
                <>
                  <Grid container md={12} sm={12} xs={12} key={index}>
                    <Grid md={12} sm={3} xs={3}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '14px',
                          color: '#344051',
                          marginBottom: '19px',
                        }}>
                        Replacement Clause
                      </Typography>
                    </Grid>
                    <Grid md={4} sm={3} xs={3} sx={{ display: 'flex' }}>
                      {!_.isEmpty(termsAndConditions?.replacementClause) &&
                        termsAndConditions?.replacementClause?.map((e: any, i: any) => (
                          <TableContainer key={i}>
                            <Table className='custom-table' aria-label='simple table'>
                              <TableHead>
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell
                                    align='left'
                                    sx={{
                                      textTransform: 'capitalize',
                                      fontWeight: '600',
                                      borderRight: '1px solid #E4E7EC',
                                      minWidth: '200px',
                                    }}>
                                    {e?.key}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                                    {e?.value ? e?.value : '--'}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ))}
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginBottom: '30px', marginTop: '25px' }} />
                </>
              ))}
            {showTableTab3?.levelWise && !_.isEmpty(termsAndConditions?.replacementClause) && (
              <>
                <Grid md={12} sm={3} xs={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      color: '#344051',
                      marginBottom: '19px',
                    }}>
                    Replacement Clause
                  </Typography>
                </Grid>
                <TableContainer>
                  <Table className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Level Wise
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Level Wise (From)
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          Level Wise (To)
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          No of Days
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(termsAndConditions?.replacementClause) &&
                        termsAndConditions?.replacementClause?.map((e: any, i: any) => (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            key={i}>
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.value ? e?.value : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.levelfrom ? e?.levelfrom : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.levelto ? e?.levelto : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.percent ? e?.percent : '--'}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider sx={{ marginBottom: '30px', marginTop: '25px' }} />
              </>
            )}
            {showTableTab3?.ctcWise && !_.isEmpty(termsAndConditions?.replacementClause) && (
              <>
                <Grid md={12} sm={3} xs={3}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      color: '#344051',
                      marginBottom: '19px',
                    }}>
                    Replacement Clause
                  </Typography>
                </Grid>
                <TableContainer>
                  <Table className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          CTC Wise
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          CTC Wise (From)
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          CTC Wise (To)
                        </TableCell>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          No of Days
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(termsAndConditions?.replacementClause) &&
                        termsAndConditions?.replacementClause?.map((e: any, i: any) => (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            key={i}>
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.value ? e?.value : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.ctcWisefrom ? e?.ctcWisefrom : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.ctcWiseto ? e?.ctcWiseto : '--'}
                            </TableCell>{' '}
                            <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                              {e?.percent ? e?.percent : '--'}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider sx={{ marginBottom: '30px', marginTop: '25px' }} />
              </>
            )}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
