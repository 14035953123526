import './index.scss';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const CustomTooltip = ({ active, payload, coordinate }: any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;
    const { x, y } = coordinate;
    const tooltipX = x > 150 ? x - 120 : x + 30; // If on the right, shift left, else shift right
    const tooltipY = y - 20; // Move it slightly up
    return (
      <div
        style={{
          position: 'absolute',
          left: tooltipX,
          top: tooltipY,
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '6px',
          color: 'black',
          fontSize: '14px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          pointerEvents: 'none',
          whiteSpace: 'nowrap',
          zIndex: 9999,
        }}>
        <p style={{ margin: 0 }}>
          <strong>{name}</strong>
        </p>
        <p style={{ margin: 0 }}>
          Value: <strong>{value}</strong>
        </p>
      </div>
    );
  }
  return null;
};

export default function Donutreactchart({ resumeData, interview }: any) {
  const total = resumeData.reduce(
    (accumulator: any, currentItem: { value: any }) => accumulator + currentItem.value,
    0,
  );
  return (
    <div className={interview ? 'donutchartcon-in' : 'donutchartcon'}>
      <PieChart width={215} height={215}>
        <Pie
          data={resumeData}
          cx={107.5}
          cy={107.5}
          innerRadius={60}
          outerRadius={80}
          fill='#8884d8'
          paddingAngle={5}
          dataKey='value'
          nameKey='name'>
          {resumeData.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {/* Custom Tooltip with white background and black text */}
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
      <div className='totalcon'>
        <div className='total'>Total</div>
        <div className='value d-flex-cc'>{total}</div>
      </div>
    </div>
  );
}
