import {
  Badge,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import moment from 'moment';
import { getAllListData } from 'services/CommonServices';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { useDebounce } from 'hooks';
import CustomSelect from 'components/CustomSelect/customSelect';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import { CommomFilter } from './CommomFilter';

export const Productivity = ({ setLd, option }: any) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const oneMonth = moment().clone().subtract(1, 'months').format('YYYY-MM-DD');
  const [visible, setVisible] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const filterClose = () => setFilterOpen(false);
  const [count, setCount] = useState<any>();
  const Defaultparam = {
    search: null,
    skip: 0,
    take: 10,
    from: oneMonth,
    to: currentDate,
    type: null,
    dateChange: false,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [commonstate, setcommonstate] = useState<any[]>([]);

  const listdataapi = () => {
    const searcharr = [
      `type=${option.toLowerCase()}`,
      `${debouncedParams.from ? `fromDate=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `toDate=${debouncedParams.to}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);

    getAllListData(
      `${DASHBOARD_ENDPOINT.Productivity}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        setcommonstate([...val.data]);
        const totalProductivity = val.data.reduce((acc: number, curr: { productivity: string }) => {
          return acc + parseFloat(curr.productivity);
        }, 0);
        setCount(totalProductivity / val?.data?.length);
        filterClose();
        setLd(!true);
      })
      .catch(() => {
        setLd(!true);
      });
  };
  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);
  const FilterChange = (value: number) => {
    const currentDate = moment();
    let fromDate: moment.Moment | null = null;
    let toDate: moment.Moment = currentDate;

    switch (value) {
      case 1: // 1 Day
        fromDate = currentDate.clone().subtract(1, 'days');
        break;
      case 2: // 1 Week
        fromDate = currentDate.clone().subtract(1, 'weeks');
        break;
      case 3: // 1 Month
        fromDate = currentDate.clone().subtract(1, 'months');
        break;
      case 4: // 3 Month
        fromDate = currentDate.clone().subtract(3, 'months');
        break;
      case 5: // 6 Month
        fromDate = currentDate.clone().subtract(6, 'months');
        break;
      case 6: // 1 Year
        fromDate = currentDate.clone().subtract(1, 'years');
        break;
      default:
        fromDate = currentDate.clone().subtract(1, 'months');
        toDate = currentDate;
        break;
    }

    // Format dates to 'yyyy-mm-dd' using Moment.js format method
    const formattedFromDate = fromDate ? fromDate.format('YYYY-MM-DD') : null;
    const formattedToDate = toDate.format('YYYY-MM-DD');

    // Update the parameters
    setParams((prev: any) => ({
      ...prev,
      from: formattedFromDate,
      to: formattedToDate,
    }));
  };

  return (
    <Grid item md={6} sm={12} xs={12}>
      <div className='productivitycards'>
        <Typography component={'div'} className='d-flex-a flex-sb'>
          <Typography
            className='title'
            sx={{ display: 'flex', fontSize: '20px', fontWeight: '600' }}>
            {'Productivity'}
            <div
              style={{
                // width: '300px',
                marginLeft: '10px',
                fontFamily: 'Poppins medium !important',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '13px',
                color: '#808080',
                display: 'flex',
                alignItems: 'center',
              }}>
              individual
            </div>
          </Typography>
          <Typography className='d-flex'>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '150px', marginRight: '10px' }}>
              <CustomSelect
                labelKey={'label'}
                valueKey={'value'}
                options={[
                  { label: '1 Day', value: 1 },
                  { label: '1 Week', value: 2 },
                  { label: '1 Month', value: 3 },
                  { label: '3 Month', value: 4 },
                  { label: '6 Month', value: 5 },
                  { label: '1 Year', value: 6 },
                ]}
                onChange={(e) => {
                  FilterChange(e.target.value);
                }}
                placeHolder='Select'
              />
            </Typography>
            <Typography
              component={'div'}
              sx={{ marginRight: '10px' }}
              className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
              onClick={() => setFilterOpen(true)}>
              <Badge color='error' variant='dot' invisible={visible}>
                <img src={Funnelimg} alt='filter funnel icon' />
              </Badge>
            </Typography>
          </Typography>
        </Typography>
        <Typography component={'div'} className='borderdivider' style={{ marginTop: '20px' }} />
        <TableContainer
          component={Paper}
          className='sb-table2'
          sx={{ width: '100% !important', maxHeight: '400px', height: '100%' }}>
          <Table
            stickyHeader
            className='custom-table custom-table-client'
            aria-label='sticky Header'>
            <TableHead>
              <TableRow
                sx={{
                  marginLeft: '50px',
                  marginRight: '43px',
                  // width: 'calc(100% - 93px)',
                }}>
                <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  Date
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  Session
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  Job Code
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  CTC
                </TableCell>
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  CV Target
                </TableCell>{' '}
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  CV Sent
                </TableCell>{' '}
                <TableCell align='center' sx={{ whiteSpace: 'nowrap' }} className='w250'>
                  Productivity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commonstate && commonstate.length > 0 ? (
                commonstate.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        // verticalAlign: 'baseline',
                        marginLeft: '50px',
                        marginRight: '43px',
                        width: 'calc(100% - 93px)',
                      }}>
                      <TableCell
                        sx={{
                          textTransform: 'capitalize',
                          whiteSpace: 'nowrap',
                          minWidth: '100px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            // justifyContent:'center',
                            alignItems: 'center',
                            gap: '10px',
                            cursor: 'pointer',
                          }}>
                          {row && row?.date ? moment(row?.date).format('DD-MM-YYYY') : ' '}
                        </div>
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize', minWidth: '120px' }}>
                        {row && row?.session ? row?.session : '-'}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                        {row && row.jobCode ? row?.jobCode : '-'}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                        {row && row?.avg ? `${Math.round(row?.avg)}` : '-'}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                        {row && row.target ? row.target : '-'}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                        {row && row.actual ? row.actual : '-'}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                        {row && row.productivity ? `${Math.round(row.productivity)} %` : '-'}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7} align={'center'}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '60px',
                      }}>
                      <NCAdedIcon />
                      <Typography className='no-client'>No Data</Typography>
                    </div>
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {commonstate && commonstate.length > 0 ? (
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              component={'p'}
              className='custom-field'
              style={{
                border: '1px solid #E4E7EC',
                borderRadius: '0px 0px 8px 8px',
                padding: '8px',
                backgroundColor: '#F2F4F7',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              Productivity for the Period{' '}
              {params.from
                ? `${moment(params.from).format('MMMM D')} to ${moment(params.to).format('MMMM D')}`
                : '-'}
              <Typography component={'span'} style={{ marginRight: '20px' }}>
                {count ? `${Math.round(count)} %` : '-'}
              </Typography>
            </Typography>
          </Grid>
        ) : (
          ''
        )}
      </div>

      {filterOpen && (
        <CommomFilter
          handleOpen={filterOpen}
          handleClose={filterClose}
          setParams={setParams}
          params={params}
          setVisible={setVisible}
          getApi={listdataapi}
          type='producivity'
        />
      )}
    </Grid>
  );
};
