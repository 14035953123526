/* eslint-disable react/display-name */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { ReactElement } from 'react';

type InputProps = {
  autoComplete?: boolean;
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  onBlur?: (src: any) => void;
  extraStyle?: any;
  isSecureTextEntry?: boolean;
  value?: string | number;
  variant?: any;
  error?: any;
  name?: string;
  type?: string;
  inputProps?: any;
  id?: string;
  maxLength?: number;
  minLength?: number;
  tabIndex?: number;
  isTextArea?: boolean;
  disabled?: boolean;
  minRows?: number;
  onFocus?: (src: any) => void;
  focus?: boolean;
  defaultValue?: string;
  size?: 'small' | 'medium' | undefined;
  autoFocus?: boolean;
  customComponent?: any;
  InputLabelProps?: any;
  currency?: boolean;
};
// const HiddenArrowIcon = () => <></>;
const CustomInput = React.forwardRef((props: InputProps, ref: any): ReactElement => {
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    placeHolder,
    className,
    onChange,
    onBlur,
    extraStyle,
    value,
    variant,
    error,
    name = '',
    type = 'text',
    inputProps = {},
    id = '',
    maxLength,
    minLength,
    isTextArea = false,
    isSecureTextEntry = false,
    autoComplete = false,
    disabled,
    minRows = 3,
    onFocus,
    focus,
    tabIndex,
    defaultValue = '',
    size = 'medium',
    autoFocus = false,
    customComponent,
    InputLabelProps,
    currency,
  } = props;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const additionalInputProps = isSecureTextEntry && (
    <InputAdornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        edge='end'
        onClick={handleClickShowPassword}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
  // const Hidenumberarrow = type=== 'number' && (
  //   <InputAdornment position='end'>
  //     <IconButton
  //      disableRipple>
  //       <HiddenArrowIcon />
  //     </IconButton>
  //   </InputAdornment>
  // );
  return (
    <TextField
      defaultValue={defaultValue}
      {...(focus ? { autoFocus: true } : {})}
      placeholder={variant ? '' : placeHolder}
      label={variant ? placeHolder : ''}
      className={className}
      onChange={onChange && onChange}
      onBlur={onBlur && onBlur}
      onFocus={onFocus && onFocus}
      name={name}
      disabled={disabled}
      inputRef={ref}
      id={id}
      style={{ ...extraStyle, position: 'relative' }}
      value={value}
      variant={variant}
      error={error}
      helperText={error}
      tabIndex={tabIndex}
      InputLabelProps={{ ...InputLabelProps }}
      type={isSecureTextEntry ? (showPassword ? 'text' : 'password') : type}
      InputProps={
        isSecureTextEntry
          ? { endAdornment: additionalInputProps }
          : type === 'number'
            ? { ...inputProps, endAdornment: customComponent ? customComponent : '' }
            : currency
              ? { ...inputProps, endAdornment: customComponent ? customComponent : '' }
              : { ...inputProps }
      }
      inputProps={{
        autoComplete: autoComplete ? 'on' : 'off',
        maxLength: maxLength,
        minLength: minLength,
        tabIndex: tabIndex,
      }}
      multiline={isTextArea ? true : false}
      minRows={isTextArea ? (minRows ? minRows : 3) : 1}
      maxRows={isTextArea ? (minRows ? minRows : 3) : 1}
      {...(size ? { size: size } : {})}
      autoFocus={autoFocus}
    />
  );
});

export default CustomInput;
