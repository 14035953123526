/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Breadcrumbs,
  Button,
  // Button,
  // Checkbox,
  // FormControlLabel,
  Grid,
  Link,
  Modal,
  Typography,
} from '@mui/material';
import Loader from 'components/loader';
import companylogo from 'assets/demopng/va-temp.png';
import Editbluepen from 'assets/png/edit.svg';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Fragment, useEffect, useRef, useState } from 'react';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Wordpng from 'assets/png/Word.png';
import Deletebucket from 'assets/png/Deletebucket.svg';
import { ClientRejectSchema, ViewPositionSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { ViewPositionType } from 'types';
import { Controller, useForm } from 'react-hook-form';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import Closepng from 'assets/png/close.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllListData, patchData } from 'services/CommonServices';
import { ClientsENDPOINT, PositionENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import _ from 'lodash';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';
import ImageWithAuth from 'components/ProfileImage';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Editor from 'ck-editor-plugin/build/ckeditor';
import RevisionHistory from '../../position/Add/RevisionHistory';
import PDFpng from 'assets/png/pngimg.png';
import { Country } from 'country-state-city';

const FileComponentui2 = ({
  fileName,
  id,
  setcalbk,
  data,
}: {
  fileName: string;
  id: string;
  setcalbk: any;
  data?: any;
}) => {
  const [clik, setclik] = useState(false);
  const handledelete = () => {
    setclik(true);
    setcalbk(id);
  };

  return (
    <>
      {/* <Grid item md={4} sm={12} xs={12} style={{display:clik ? 'none':'inherit'}}> */}
      <Typography component={'div'} style={{ display: clik ? 'none' : 'inherit' }}>
        <Typography component={'div'} className='custom-field file-view-box'>
          <Typography
            component={'p'}
            className={'first-children first-green-children-no-border-color'}>
            {
              <div className='image-container'>
                <span className='image image-success'>
                  {data?.type === 'application/pdf' || data?.mimetype === 'application/pdf' ? (
                    <img src={PDFpng} alt='PDFpng' style={{ height: '20px', width: '20px' }} />
                  ) : (
                    <img src={Wordpng} alt='Wordpng' />
                  )}
                </span>
                <span className={'texted texted-success'}>
                  <TableTooltip value={fileName ? fileName : ''} positionfile={true}></TableTooltip>
                </span>
              </div>
            }
            {/* <div className='Crossicon Crossicon-deletebucket' onClick={handledelete}>
                  <img src={Deletebucket} alt='Crossicon' />
                </div> */}
          </Typography>
        </Typography>
      </Typography>
    </>
  );
};

const Commoncontent = ({ propmode, uids }: { propmode?: boolean; uids?: any }) => {
  const {
    userInfo: { organizationId: orgdy, id: userId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [Ld, setLd] = useState(false);
  const [companylocal, setcompanylocal] = useState<any>({});
  const [Mainloop, setMainloop] = useState<any>([]);
  const [crmdata, setcrmdata] = useState<any>({});
  const [spocdata, setspocdata] = useState<any>({});
  const [Jobcode, setJobcode] = useState<any>('');
  const [spoclocal, setspoclocal] = useState<any>('');
  const [Clientename, setClientename] = useState<any>([]);
  const [Spocname, setSpocname] = useState<any>([]);
  const [functionalArea, setfunctionalArea] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [industry, setindustry] = useState<any>([]);
  const [Recuiterslis, setRecuiterslis] = useState<any>([]);
  const [history, setHistory] = useState<any>([]);
  const [approvalstatus, setapprovalstatus] = useState<any>('');
  const [positionData, setPositionData] = useState<any>({});
  const [isRejectionModalOpened3, setRejectionModalOpen3] = useState(false);
  // ckeditor
  const editorRef = useRef();
  const [editorContent, setEditorContent] = useState<any>(undefined);

  // const handleEditorChange = (event: any, editor: any) => {
  //   const data = editor && editor.getData();

  //   setEditorContent(data);
  // };
  const [searchParams] = useSearchParams();
  const Id = searchParams.get('id');
  const screen = searchParams.get('screen');
  const [prefileupload, setprefileupload] = useState<any>([
    { fileName: 'word.docx', id: 'iojoij' },
  ]);
  const navigate = useNavigate();
  const [removeFileIds, setremoveFileIds] = useState<any>([]);
  const {
    handleSubmit: handleSubmit3,
    control: control3,
    formState: { errors: errors3 },
    getValues: getValues3,
    setValue: setValue3,
    // reset:reset3,
    // trigger:trigger2,
  } = useForm<ViewPositionType>({
    defaultValues: {
      status: undefined,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(ViewPositionSchema),
  });

  const onSubmitForm32 = (data: any) => {
    if (data.status) {
      patchData(
        '',
        {
          approvalStatus: data.status,
        },
        `${PositionENDPOINT.position}/view-status/${Id}`,
        true,
      )
        .then(() => {
          setRejectionModalOpen3(!true);
          Alllistapi();
        })
        .catch((e) => console.log(e));
    }
  };

  function handleSuccessprops2(idxen: string) {
    setremoveFileIds((pl: any) => [...pl, idxen]);
  }

  const [isRejectionModalOpened, setRejectionModalOpen] = useState(false);

  const handleRejectOpen = () => setRejectionModalOpen(true);

  const handleRejectClose = () => {
    reset();
    setRejectionModalOpen(false);
  };

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit: handleSubmit,
  } = useForm<{ comments: string }>({
    criteriaMode: 'all',
    defaultValues: {
      comments: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ClientRejectSchema),
  });

  const onSubmitForm = (data: any) => {
    rejectClient(uids, data.comments);
  };

  const approveClient = (clientId: string) => {
    patchData(
      '',
      { approvalStatus: 'assigned' },
      `${PositionENDPOINT.position}/status/${clientId}`,
      true,
    )
      .then(() => {
        navigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW);
      })
      .catch((e) => console.log(e));
  };

  const rejectClient = (clientId: string, comments: string) => {
    patchData(
      '',
      { approvalStatus: 'rejected', reason: comments },
      `${PositionENDPOINT.position}/status/${clientId}`,
      true,
    )
      .then(() => {
        navigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW);
      })
      .catch((e) => console.log(e));
  };

  const handleRejectClose3 = () => {
    setRejectionModalOpen3(false);
    setValue3('status', '');
  };
  const Alllistapi = async () => {
    try {
      setLd(true);
      const lis = await getAllListData(
        `${PositionENDPOINT.position}?id=${propmode ? uids : Id}`,
        true,
      );
      setHistory(lis.data[0].history);
      if (
        lis &&
        !_.isEmpty(lis.data) &&
        lis.data &&
        lis.data.length > 0 &&
        !_.isEmpty(lis.data[0])
      ) {
        const data = lis.data[0];
        data && data.files && !_.isEmpty(data.files) ? setprefileupload(data.files) : [];
        if (!_.isEmpty(data)) {
          setPositionData(data);
          data && !_.isEmpty(data.title)
            ? setMainloop((pl: any) => [...pl, { title: 'Job Title', subtitle: data.title }])
            : '';

          const clientCountry = Country.getAllCountries().filter(
            (country) => country.isoCode === data?.country,
          );
          data && !_.isEmpty(data.country)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Job Country', subtitle: clientCountry[0]?.name },
              ])
            : '';
          data && !_.isEmpty(data.location)
            ? setMainloop((pl: any) => [...pl, { title: 'Job Location', subtitle: data.location }])
            : '';
          data && !_.isEmpty(data.jobCode)
            ? setMainloop((pl: any) => [...pl, { title: 'Job Code', subtitle: data.jobCode }])
            : '';
          data && data.minAge && data.maxAge
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Age', subtitle: `${data.minAge} - ${data.maxAge} Years` },
              ])
            : '';
          data && data.minExp && data.maxExp
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Experience', subtitle: `${data.minExp} - ${data.maxExp} Years` },
              ])
            : '';
          data && !_.isEmpty(data.gender)
            ? setMainloop((pl: any) => [...pl, { title: 'Gender', subtitle: data.gender }])
            : '';

          data && !_.isEmpty(data.minCtc) && !_.isEmpty(data.maxCtc)
            ? setMainloop((pl: any) => [
                ...pl,
                {
                  title: 'Annual CTC',
                  subtitle: `${Number(data.minCtc).toLocaleString(
                    'en-IN',
                  )} ${data?.minCurrency.toUpperCase()} - ${Number(data.maxCtc).toLocaleString(
                    'en-IN',
                  )} ${data?.maxCurrency.toUpperCase()}`,
                },
              ])
            : '';
          data && !_.isEmpty(data.billableValue)
            ? setMainloop((pl: any) => [
                ...pl,
                {
                  title: 'Billable value for Openings',
                  subtitle: Number(data.billableValue).toLocaleString('en-IN'),
                },
              ])
            : '';
          data && !_.isEmpty(data.billableValue) && !_.isEmpty(data.totalOpenins)
            ? setMainloop((pl: any) => [
                ...pl,
                {
                  title: 'Total Billable Value',
                  subtitle: Number(data.billableValue) * data.totalOpenins,
                },
              ])
            : '';
          data && !_.isEmpty(data.qualification)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Qualification', subtitle: data.qualification },
              ])
            : '';
          data && !_.isEmpty(data.functionalAreaId) && functionalArea[data.functionalAreaId]
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Functional Area', subtitle: functionalArea[data.functionalAreaId] },
              ])
            : '';
          data && !_.isEmpty(data.industries) && !_.isEmpty(data.industries.name)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Industry', subtitle: data.industries.name },
              ])
            : '';
          data && !_.isEmpty(data.createdAt)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Created On', subtitle: moment(data.createdAt).format('DD-MM-YYYY') },
              ])
            : '';
          data && !_.isEmpty(data.user) && data.user.firstName && data.user.lastName
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Created By', subtitle: `${data.user.firstName} ${data.user.lastName}` },
              ])
            : '';
          data &&
          !_.isEmpty(data.crm) &&
          !_.isEmpty(data.crm.user) &&
          !_.isEmpty(data.crm.user.firstName) &&
          !_.isEmpty(data.crm.user.lastName)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'CRM', subtitle: `${data.crm.user.firstName} ${data.crm.user.lastName}` },
              ])
            : '';
          const technialdummy =
            data &&
            !_.isEmpty(data.technicalSkills) &&
            !_.isEmpty(data.technicalSkills.map((r: any) => r.skill))
              ? data.technicalSkills.map((r: any) => r.skill)
              : [];

          !_.isEmpty(technialdummy)
            ? setMainloop((pl: any) => [
                ...pl,
                {
                  title: 'Technical Skills',
                  subtitle: technialdummy.toString(),
                },
              ])
            : '';

          const Behavidummy =
            data &&
            !_.isEmpty(data.behaviouralSkills) &&
            !_.isEmpty(data.behaviouralSkills.map((r: any) => r.skill))
              ? data.behaviouralSkills.map((r: any) => r.skill)
              : [];

          !_.isEmpty(Behavidummy)
            ? setMainloop((pl: any) => [
                ...pl,
                {
                  title: 'Behavioural Skills',
                  subtitle: Behavidummy.toString(),
                },
              ])
            : '';
          data && !_.isEmpty(data.totalOpenins)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Total Openings', subtitle: data.totalOpenins },
              ])
            : '';

          const recuitersdummy =
            data &&
            !_.isEmpty(data.recruiters) &&
            !_.isEmpty(
              data.recruiters
                .map((r: any) =>
                  r.user && r.user.firstName && r.user.lastName && r.user.roleId
                    ? [r.user.firstName, r.user.lastName, r.user.roleId]
                    : [],
                )
                .flat(100),
            );
          !_.isEmpty(recuitersdummy)
            ? setMainloop((pl: any) => [
                ...pl,
                {
                  title: 'Recruiters',
                  subtitle: recuitersdummy.toString(),
                },
              ])
            : '';
          data && !_.isEmpty(data.expectedJoiningDate)
            ? setMainloop((pl: any) => [
                ...pl,
                {
                  title: 'Expected Joining Date',
                  subtitle: moment(data.expectedJoiningDate).format('DD-MM-YYYY'),
                },
              ])
            : '';
          data && !_.isEmpty(data.resumeType)
            ? setMainloop((pl: any) => [...pl, { title: 'Resume Type', subtitle: data.resumeType }])
            : '';
          data && !_.isEmpty(data.projectType)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Project Type', subtitle: data.projectType },
              ])
            : '';
          data && !_.isEmpty(data.hideResumeContacts)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Hide Resume Contacts', subtitle: data.hideResumeContacts },
              ])
            : '';
          data && !_.isEmpty(data.updatedAt)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Modified On', subtitle: moment(data.updatedAt).format('DD-MM-YYYY') },
              ])
            : '';
          data && !_.isEmpty(data.publishInWebsite)
            ? setMainloop((pl: any) => [
                ...pl,
                { title: 'Publish in Website', subtitle: data.publishInWebsite },
              ])
            : '';
          data && !_.isEmpty(data.jobDescription) ? setEditorContent(data.jobDescription) : '';
          data &&
          !_.isEmpty(data.spoc) &&
          !_.isEmpty(data.spoc.firstName) &&
          !_.isEmpty(data.spoc.lastName)
            ? setspoclocal(`${data.spoc.firstName} ${data.spoc.lastName}`)
            : '';
          data && !_.isEmpty(data.client) ? setcompanylocal(data.client) : '';
          data && data.approvalStatus
            ? (setapprovalstatus(data.approvalStatus), setValue3('status', data.approvalStatus))
            : '';
        }
      }
    } catch (e) {
      console.log(e, 'err');
      setLd(!true);
    } finally {
      console.log('final');
      setLd(!true);
    }
  };

  const alldropdownapicalls = async () => {
    const getdate = new Date();
    try {
      setLd(true);
      const Jobcodeapi = await getAllListData(
        `position/job-code?year=${getdate.getFullYear()}&organizationId=${orgdy}`,
        true,
      );
      !_.isEmpty(Jobcodeapi) && !_.isEmpty(Jobcodeapi.jobCode)
        ? setJobcode(Jobcodeapi.jobCode)
        : '';
      const Industrydetailsapi = await getAllListData(`industry?organizationId=${orgdy}`, true);
      const industryparse =
        !_.isEmpty(Industrydetailsapi) &&
        !_.isEmpty(Industrydetailsapi.data) &&
        Industrydetailsapi.data.map((mpdat1: any) => {
          return !_.isEmpty(mpdat1) && !_.isEmpty(mpdat1.id) && !_.isEmpty(mpdat1.name)
            ? { label: mpdat1.name, value: mpdat1.id }
            : {};
        });
      !_.isEmpty(industryparse) && _.isArray(industryparse) ? setindustry(industryparse) : '';

      const getapi = await getAllListData(
        `${ClientsENDPOINT.Client}?approvalStatus=${'approved'}&status=true`,
        true,
      );

      // const Userdata: any = await getAllListData(`${SETTING_ENDPOINT.users}?roleId=2`, true);

      // if (Userdata && !_.isEmpty(Userdata) && !_.isEmpty(Userdata.data)) {
      //   // console.log(Userdata.data, 'Userdata.data');
      // }

      const functionalarea: any = await getAllListData(`${SETTING_ENDPOINT.functionalArea}`, true);

      if (functionalarea && !_.isEmpty(functionalarea) && !_.isEmpty(functionalarea.data)) {
        const functionalareadropdown: any = functionalarea.data.map((p1: any) => {
          if (!_.isEmpty(p1) && p1.name && p1.id) {
            return setfunctionalArea((frea: any) => ({ ...frea, [p1.id]: p1.name }));
          }
        });
        // !_.isEmpty(functionalareadropdown) && setfunctionalArea(functionalareadropdown);
      }

      if (getapi && !_.isEmpty(getapi) && !_.isEmpty(getapi.data)) {
        const Localspoc: any = {};
        const Localcrm: any = {};
        const clientdropdown: any = getapi.data.map((p1: any) => {
          if (!_.isEmpty(p1)) {
            p1.id ? (Localspoc[p1.id] = p1.clientsSpoc) : '';
            p1.id ? (Localcrm[p1.id] = p1.clientsCrm) : '';
            return {
              label: p1 && p1.corporateName ? p1.corporateName : '',
              value: p1 && p1.id ? p1.id : '',
            };
          }
        });
        !_.isEmpty(clientdropdown) && setClientename(clientdropdown);
        !_.isEmpty(Localcrm) && setcrmdata(Localcrm);
        !_.isEmpty(Localspoc) && setspocdata(Localspoc);
      }
    } catch (e: any) {
      setLd(!true);
      console.log(e, 'e');
    } finally {
      setLd(!true);
      setTimeout(() => {
        Alllistapi();
      });
    }
  };

  useEffect(() => {
    alldropdownapicalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='main-content view-position'>
            <Typography component={'div'} className='white-box view-position-white-box'>
              <header style={{ alignItems: propmode ? 'flex-end' : 'inherit' }}>
                <div className={'left-header'}>
                  <div className={'main-img'} style={{ marginTop: propmode ? 'inherit' : '10px' }}>
                    {companylocal?.logoId ? (
                      <ImageWithAuth
                        styled={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          // transform: 'translateY(-4px)',
                        }}
                        id={companylocal?.logoId}
                      />
                    ) : (
                      <img src={companylogo} alt='companylogo' />
                    )}
                  </div>
                  <div className={'company-info'}>
                    <div className={'firstname'}>
                      {companylocal?.corporateName ? companylocal.corporateName : ''}
                    </div>
                    <div
                      className={'secondname'}
                      style={{
                        flexDirection: propmode ? 'column' : 'row',
                        rowGap: propmode ? '8px' : 'inherit',
                        justifyContent: propmode ? 'flex-start' : 'inherit',
                        alignItems: propmode ? 'flex-start' : 'center',
                      }}>
                      <div className={'name'}>{positionData?.title ? positionData?.title : ''}</div>
                      <div className={'btns'} style={{ marginLeft: propmode ? '0px' : '16px' }}>
                        {approvalstatus === 'awaitingApproval' ? (
                          <button className='position-view-btn awaitingApproval'>
                            <span>Awaiting Approval</span>
                          </button>
                        ) : (
                          ''
                        )}
                        {approvalstatus === 'rejected' ? (
                          <button className='position-view-btn rejected'>
                            <span>Rejected</span>
                          </button>
                        ) : (
                          ''
                        )}
                        {approvalstatus === 'assigned' ? (
                          <button className='position-view-btn assigned'>
                            <span>Assigned</span>
                          </button>
                        ) : (
                          ''
                        )}
                        {approvalstatus === 'inProcess' ? (
                          <button className='position-view-btn inProcess'>
                            <span>In Process</span>
                          </button>
                        ) : (
                          ''
                        )}
                        {approvalstatus === 'onHold' ? (
                          <button className='position-view-btn onHold'>
                            <span>On Hold</span>
                          </button>
                        ) : (
                          ''
                        )}
                        {approvalstatus === 'billed' ? (
                          <button className='position-view-btn billed'>
                            <span>Billed</span>
                          </button>
                        ) : (
                          ''
                        )}
                        {approvalstatus === 'terminated' ? (
                          <button className='position-view-btn terminated'>
                            <span>Terminated</span>
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                      {propmode ? (
                        <></>
                      ) : positionData?.crm?.length &&
                        positionData?.crm[0]?.crm?.userId === userId &&
                        approvalstatus !== 'billed' ? (
                        <img
                          src={Editbluepen}
                          alt='Editbluepen'
                          className={'edit'}
                          style={{
                            cursor: 'pointer',
                            display: !JSON.parse(screen!) ? 'none' : 'inherit',
                          }}
                          onClick={() => (
                            setRejectionModalOpen3(!false), setValue3('status', approvalstatus)
                          )}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={'right-header'}
                  style={{ marginBottom: propmode ? '8px' : 'inherit' }}>
                  {propmode ? (
                    <div className='buttons'>
                      {' '}
                      {!positionData?.approvedBy && (
                        <button onClick={handleRejectOpen} className='client-aprv-btn'>
                          Reject
                        </button>
                      )}
                      <button
                        onClick={() => approveClient(uids)}
                        className='client-aprv-btn aprvd-btn-2'>
                        Approve
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className='title'>SPOC Name</div>
                      <div className='sub-title'>{spoclocal ? spoclocal : ''}</div>
                    </>
                  )}
                </div>
              </header>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EDEDED',
                  marginTop: '16px',
                }}></div>
              {propmode ? (
                <>
                  <Grid container className='main-content-modal-spoc-2'>
                    <div className='child-items2' style={{ maxWidth: 'auto' }}>
                      <div className='heading'>{'Client Name'}</div>
                      <div className='sub-heading'>
                        {companylocal?.corporateName ? companylocal.corporateName : ''}
                      </div>
                    </div>
                    <div className='child-items2' style={{ maxWidth: 'auto', marginRight: '49px' }}>
                      <div className='heading'>{'SPOC Name'}</div>
                      <div className='sub-heading'>{spoclocal ? spoclocal : ''}</div>
                    </div>
                  </Grid>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EDEDED',
                      marginTop: '32px',
                    }}></div>
                </>
              ) : (
                <></>
              )}
              <div className='section-heading'>Basic Details</div>
              <main className='main-content'>
                {Mainloop.map((dat1: any, idx: number) => {
                  return (
                    <div className='child-items' key={idx}>
                      <div className='heading'>{dat1.title}</div>
                      <div className='sub-heading'>{dat1.subtitle}</div>
                    </div>
                  );
                })}
                <div className='child-items'></div>
                <div className='child-items'></div>
              </main>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EDEDED',
                }}></div>
              <div className='section-heading' style={{ marginTop: '32px', marginBottom: '10px' }}>
                Job Description
              </div>
              <div className='ckeditorview'>
                <CKEditor
                  editor={Editor}
                  data={editorContent && editorContent}
                  // onChange={handleEditorChange}
                  config={{
                    toolbar: [],
                  }}
                  disabled
                />
              </div>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EDEDED',
                  marginTop: '34px',
                }}></div>
              <div className='section-heading' style={{ marginTop: '32px', marginBottom: '16px' }}>
                Attachments
              </div>
              <div className='d-flex-a'>
                {' '}
                {prefileupload.map((datup: any, idx1: number) => {
                  return (
                    <Fragment key={idx1}>
                      <FileComponentui2
                        fileName={datup.fileName}
                        id={datup.id}
                        setcalbk={handleSuccessprops2}
                        data={datup}
                      />
                    </Fragment>
                  );
                })}
              </div>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EDEDED',
                  marginTop: '34px',
                }}></div>
              {searchParams.get('id') && (
                <RevisionHistory
                  history={history}
                  crmUserId={
                    positionData &&
                    positionData?.crm?.length &&
                    positionData?.crm[0]['crm']['userId']
                  }
                />
              )}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Modal open={isRejectionModalOpened3} onClose={handleRejectClose3}>
        <>
          <form className='private-form' id={'user-add'} onSubmit={handleSubmit3(onSubmitForm32)}>
            <Box
              className='spoc-list-modal center'
              // style={{ overflow: !opnspocbtndsc ? 'hidden' : 'inherit' }}
            >
              <img src={Closepng} alt='close' className='closepng' onClick={handleRejectClose3} />
              <Typography component={'div'} className='custom-field'>
                <Typography component={'div'} className='reason-for-editing'>
                  Change Position Status
                </Typography>
                <Typography component={'p'}>
                  New Status<span>*</span>
                </Typography>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[
                        { label: 'In Process', value: 'inProcess' },
                        { label: 'On Hold', value: 'onHold' },
                        { label: 'Billed', value: 'billed' },
                        { label: 'Terminated', value: 'terminated' },
                        { label: 'Assigned', value: 'assigned' },
                      ]}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        // setqualiload(false);
                        onChange(e);
                      }}
                      placeHolder='Select Status'
                      error={errors3.status?.message}
                    />
                  )}
                  name='status'
                  control={control3}
                />
              </Typography>
              {/* <div className='button-con center'>
                <button className='button'>Submit</button>
              </div> */}
              <div className='button-con center'>
                <div className='buttons editreason-btn'>
                  <button onClick={handleRejectClose3} className='editreason-btn-close'>
                    Cancel
                  </button>
                  <button
                    type='submit'
                    // form={'user-add'}
                    // style={{pointerEvents:Number(getValues('totalOpenins')) - Number(dynamictotalposition) > 0?'inherit':'none',opacity:Number(getValues('totalOpenins')) - Number(dynamictotalposition) > 0?1:0.4}}
                    className='editreason-btn-submit'>
                    Save
                  </button>
                </div>
              </div>
            </Box>
          </form>
        </>
      </Modal>
      <Modal open={isRejectionModalOpened} onClose={handleRejectClose}>
        <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
          <Box className='spoc-list-modal center' style={{ overflow: 'inherit' }}>
            <img src={Closepng} alt='close' className='closepng' onClick={handleRejectClose} />
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}>
                Comments <span>*</span>
              </Typography>
              <Controller
                render={({ field: { onChange, value, name } }) => (
                  <CustomInput
                    value={value}
                    name={name}
                    isTextArea={true}
                    className={`custom-input client-comment-box ${
                      errors.comments?.message ? 'client-comment-box-error' : ''
                    }`}
                    placeHolder='Write your content here'
                    error={errors.comments?.message}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                )}
                name='comments'
                control={control}
              />
            </Typography>
            {/* <div className='button-con center'>
                <button className='button'>Submit</button>
              </div> */}
            <div className='button-con center'>
              <div className='buttons'>
                <button
                  type='submit'
                  // onClick={() => reset()}
                  className='client-aprv-btn client-modal-comment-btn'>
                  Submit
                </button>
              </div>
            </div>
          </Box>
        </form>
      </Modal>
      {Ld && <Loader />}
    </>
  );
};

export default Commoncontent;
