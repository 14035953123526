import { FormHelperText, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { ReactElement } from 'react';

type DatePickerProps = {
  placeHolder?: string;
  onChange?: (src: any) => void;
  value?: any;
  error?: string;
  name?: string;
  minDate?: any;
  maxDate?: any;
  disabled?: boolean;
  className?: string;
  views?: 'MM/YYYY' | 'YYYY'; // Limit views to specific options
};

const CustomDatePicker = (props: DatePickerProps): ReactElement => {
  const {
    onChange,
    value,
    error,
    minDate,
    maxDate,
    disabled = false,
    views = 'MM/YYYY',
    placeHolder = 'Select Month and Year', // Default value
    className,
  } = props;

  const handleChange = (date: any) => {
    const formattedDate = date.date(1); // Ensure day is 1
    onChange && onChange(formattedDate);
  };

  return (
    <Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          // label='Custom input'
          format='MM/YYYY'
          value={value ? dayjs(value) : null}
          defaultValue={value ? value : null}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleChange}
          openTo={views === 'MM/YYYY' ? 'month' : 'year'}
          views={views === 'MM/YYYY' ? ['month', 'year'] : ['year']}
          disabled={disabled}
          sx={{
            '&input': {
              padding: '0px !important',
            },
          }}
          slotProps={{
            textField: {
              placeholder: placeHolder, // Use the placeholder prop here
              className,
              sx: {
                '& .MuiInputBase-input': {
                  padding: '0px !important',
                },
              },
            },
            inputAdornment: {
              sx: {
                padding: '0px !important',
              },
            },
            openPickerButton: {
              sx: {
                padding: '0px !important',
              },
            },
            openPickerIcon: {
              sx: {
                height: '0.8em !important',
                width: '0.8em !important',
              },
            },
          }}
        />
      </LocalizationProvider>

      {error && <FormHelperText className='errorMessage'>{error}</FormHelperText>}
    </Typography>
  );
};

export default CustomDatePicker;
