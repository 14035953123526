/* eslint-disable react/display-name */
import { Box, Grid, Typography } from '@mui/material';
import { FeedBackSuggestions } from './feedBackSuggestions';
import ItechLogo from 'assets/png/iTechLogo.svg';

const Footer = () => {
  return (
    <Typography component={'div'} className='footer-box'>
      <Grid
        container
        sx={{
          flexWrap: 'wrap',
          rowGap: '8px',
          justifyContent: 'space-between',
          columnGap: '50px',
        }}>
        <Box
          sx={{
            flexWrap: 'nowrap',
            flexShrink: 0,
            flexBasis: 'auto',
            maxWidth: 'auto',
            width: 'auto',
          }}>
          <Typography className='footer-item'>
            © Copyright {new Date().getFullYear()} | All rights reserved.
          </Typography>
        </Box>
        <Box
          sx={{
            columnGap: '16px',
            rowGap: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px',
          }}>
          <Typography className='footer-item boder-right'>
            Privacy Policy {'  '}
            <Typography className='footer-item' component={'span'}>
              {'  '} |
            </Typography>
          </Typography>

          <Typography className='footer-item boder-right'>
            Terms of service {'  '}
            <Typography className='footer-item' component={'span'}>
              {'  '} |
            </Typography>
          </Typography>
          <Typography className='footer-item boder-right'>Acceptable use policy</Typography>
        </Box>
        <FeedBackSuggestions />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography className='footer-item'>Powered by</Typography>
          <img
            src={ItechLogo}
            alt='arrow'
            className='arrows'
            style={{ height: '25px', width: '50px' }}
          />
        </Box>
      </Grid>
    </Typography>
  );
};

export default Footer;
