/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Avatar from 'assets/png/avatarIcon.svg';
import EmailIcon from 'assets/png/email.svg';
import { To, useNavigate, useParams } from 'react-router-dom';
import BanImg from 'assets/png/pro-banner.png';
import CustomSelect from 'components/CustomSelect/customSelect';

import react, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { useForm, Controller } from 'react-hook-form';
import { productAdminSchema, userFormSchema } from 'utils/ValidatorSchema';
import _ from 'lodash';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { updateData, addNewData, getAllListData, getSingleData } from 'services/CommonServices';
import { FILE_ENDPOINT, MASTER_ENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';
import Loader from 'components/loader';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
import { Country, State, City } from 'country-state-city';

const ProductAdministratorAdd = () => {
  const { editId } = useParams();
  const [loader, setLoader] = useState<any>(false);
  const [uploadImage, setUploadImage] = useState<any>({});
  const [localImage, setLocalImage] = useState<any>('');
  const [userData, setUserData] = useState<any>({});
  const [rollist, setrollist] = useState<any>([]);
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const [removeImage, setRemoveImage] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<any>('');
  const [branchDatas, setBranchDatas] = useState<Array<{ label: string; value: string }>>([]);
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();

  const getRolefn = async () => {
    const rold = await getAllListData(
      `${SETTING_ENDPOINT.roles}?roles=${String(
        15,
        // Array(14)
        //   .fill(1)
        //   .map((el: number | any, id: any) => Number(el * id) + 1)
        //   .toString(),
      )}`,
      true,
    );
    setrollist(
      rold && rold.data && rold.data.length > 0
        ? rold.data.map((erold: any) => ({
            label: erold.name ? (erold.id === 15 ? 'Administrator' : erold.name) : '',
            value: erold.id ? erold.id : '',
          }))
        : [],
    );
    // console.log(rold);
  };
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    trigger,
    // setError,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      role: 15,
      email: '',
      city: '',
      state: '',
      country: '',
      PhoneNumber: '',
      saturdayleave: 'even',
      isSunday: true,
      PhoneNumberFormatted: '',
      pincode: '',
      status: true,
      countryCodeText: '',
      branchId: '',
      empCode: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(productAdminSchema),
  });
  const getInitialStatus = () => {
    getAllListData(`${SETTING_ENDPOINT.users}?roleId=${15}&id=${editId}`, true)
      .then((resp: any) => {
        if (resp && !_.isEmpty(resp.data)) {
          setUserData(resp.data[0]);
          const imageId = resp?.data[0]?.file ? resp?.data[0]?.file?.id : '';
          setValue('firstName', resp.data[0]?.firstName);
          setValue('lastName', resp.data[0]?.lastName);
          setValue('email', resp.data[0]?.email);
          setValue('role', resp.data[0]?.roleId);
          setValue('city', resp.data[0]?.city);
          setValue('state', resp.data[0]?.state);
          setValue('pincode', resp.data[0]?.pincode);
          setValue('country', resp.data[0]?.country);
          setValue('countryCode', resp.data[0]?.countryCode);
          setValue('status', resp.data[0]?.status);
          setValue('isSunday', resp.data[0]?.isSundayLeave);
          setValue('saturdayleave', resp.data[0]?.saturdayLeave);
          setValue('phoneNumber', `${resp.data[0]?.countryCode}${resp.data[0]?.phoneNumber}`);
          setValue(
            'PhoneNumberFormatted',
            `${resp.data[0]?.countryCode}${resp.data[0]?.phoneNumber}`,
          );
          setValue('countryCode', `${resp.data[0]?.countryCode}`);
          setValue('branchId', resp.data[0]?.branchId);
          setValue('empCode', resp.data[0]?.empCode);
          if (!_.isEmpty(imageId)) {
            getSingleData('', `${FILE_ENDPOINT.files}/${imageId}`, true, {
              responseType: 'blob',
            }).then((fileRes: any) => {
              setUploadImage(URL.createObjectURL(fileRes));
              setImageUrl(URL.createObjectURL(fileRes));
              setLoader(false);
            });
          }
          setLoader(false);
        }
      })
      .catch((e: any) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };
  const onSubmitForm = (data: any) => {
    if (_.isEmpty(uploadImage)) {
      toast.error(String('Product Administrator Image is required'), {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
      });
      return;
    }
    const formData = new FormData();
    formData.append('firstName', data?.firstName);
    formData.append('lastName', data?.lastName);
    formData.append('email', data?.email);
    formData.append('city', data?.city);
    formData.append('state', data?.state);
    formData.append('country', data?.country);
    formData.append('status', data?.status);
    formData.append('pincode', data.pincode);
    formData.append('phoneNumber', String(data?.phoneNumber).slice(data?.countryCode.length));
    formData.append(
      'countryCode',
      `+${data?.countryCode
        .split('')
        .filter((ep: string) => ep != '+')
        .join('')}`,
    );
    formData.append('organizationId', orgdy);
    formData.append('isSundayLeave', data.isSunday);
    formData.append('saturdayLeave', data.saturdayleave);
    formData.append('roleId', data?.role);
    userData?.imageId ? formData.append('imageId', userData?.imageId) : '';
    uploadImage[0] ? formData.append('profile', uploadImage[0]) : '';
    // removeImage ? formData.append('removeImageId', removeImage) : '';
    formData.append('toastType', 'admin');
    formData.append('crm', 'false');
    formData.append('branchId', data.branchId);
    formData.append('empCode', data.empCode);
    if (!_.isUndefined(editId)) {
      updateData(editId, formData, `${String(SETTING_ENDPOINT.users)}`, true, {
        'Content-Type': 'multipart/form-data',
      })
        .then(() => {
          setLocalImage('');
          setUploadImage({});
          // setRemoveImage('');
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.PRODUCTADMINISTRATOR.PRODUCTADMINISTRATORLIST}`);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          // console.log(e);
        });
    } else {
      addNewData(
        formData,
        `${String(SETTING_ENDPOINT.users)}`,
        {
          'Content-Type': 'multipart/form-data',
        },
        true,
      )
        .then(() => {
          setLocalImage('');
          setUploadImage({});
          // setRemoveImage('');
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.PRODUCTADMINISTRATOR.PRODUCTADMINISTRATORLIST}`);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          // console.log(e);
        });
    }
  };

  const branchData = () => {
    getAllListData(
      `${SETTING_ENDPOINT.Branch}?organizationId=${orgdy}&status=true&branchType=user&sortby=name&order=asc`,
      true,
    )
      .then((res) => {
        const options: Array<{ label: string; value: string }> = [];
        if (res.data.length) {
          res.data.forEach((e: any) => {
            options.push({ label: e.name, value: e.id });
          });
        }
        setBranchDatas(options);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    getRolefn();
    branchData();
    if (editId) {
      getInitialStatus();
    }
  }, []);

  const updatePhoto = (e: any) => {
    const imageFormatCheck = e?.target?.files[0]?.name.split('.');
    const formatCheck = ['tiff', 'jpeg', 'jpg', 'png'].includes(
      imageFormatCheck[imageFormatCheck.length - 1].toLowerCase(),
    );
    if (formatCheck) {
      let fileSize = 0;
      fileSize = Math.round(e?.target?.files[0].size / 1024);
      if (fileSize > 5120) {
        toast.error('File size should be less than 5MB');
      } else {
        setUploadImage(e?.target?.files);
        setLocalImage(URL.createObjectURL(e?.target?.files[0]));
      }
    } else {
      toast.error('Upload valid images only PNG, JPEG, JPG and TIFF are allowed');
    }
  };

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountry(options);
  }, []);

  useEffect(() => {
    if (watch('country')) {
      const options: any = [];
      State.getStatesOfCountry(watch('country')).map((state: any) => {
        options.push({ label: state.name, value: state.isoCode });
      });
      setStates(options);
    }
  }, [watch('country')]);

  useEffect(() => {
    if (watch('state')) {
      const options: any = [];
      City.getCitiesOfState(watch('country'), watch('state')).map((city: any) => {
        options.push({ label: city.name, value: city.name });
      });
      setCities(options);
    }
  }, [watch('country'), watch('state')]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Link
              underline='hover'
              color='inherit'
              href=''
              onClick={() =>
                navigate(AdminPrivateRoutes.SETTINGS.PRODUCTADMINISTRATOR.PRODUCTADMINISTRATORLIST)
              }>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Product Administrator
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              {editId ? 'Edit' : 'Add'} Product Administrator
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>
            {editId ? 'Edit' : 'Add'} Product Administrator
          </Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button
              className='cancel-btn mr-16'
              onClick={() =>
                navigate(AdminPrivateRoutes.SETTINGS.PRODUCTADMINISTRATOR.PRODUCTADMINISTRATORLIST)
              }>
              {' '}
              Cancel
            </Button>
            <Button className='s-add-btn' type='submit' form={'user-add'}>
              Save
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='pad-box'>
          <Grid container columnSpacing={3.25}>
            <Grid item md={4} sm={12} xs={12}>
              <Typography component={'div'} className='pro-box'>
                <Typography component={'div'} className='pro-box'>
                  {localImage ? (
                    <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                      <img className='default-pic' src={localImage} />
                    </Typography>
                  ) : userData?.file?.id ? (
                    <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                      <img className='default-pic' src={imageUrl} />
                    </Typography>
                  ) : userData?.firstName && userData?.lastName ? (
                    <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                      <Typography className='default-pic'>
                        {userData?.firstName?.toUpperCase().trim().split('')[0] +
                          userData?.lastName?.toUpperCase().trim().split('')[0]}
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                      <img
                        className='default-pic'
                        src={Avatar}
                        style={{ objectFit: 'none', backgroundColor: '#FDEDE9' }}
                      />
                    </Typography>
                  )}
                  <Typography sx={{ textTransform: 'capitalize  ' }} className='pro-name'>
                    {!editId
                      ? ''
                      : `${userData?.firstName ? userData?.firstName : ''} ${
                          userData?.lastName ? userData?.lastName : ''
                        }`}
                  </Typography>
                  <Typography className='pro-role'>Administrator</Typography>
                  <Typography className='d-flex-ja  mt-35'>
                    <Typography className='d-flex-ja'>
                      <Button
                        component='label'
                        // onClick={() => fileRef.current.click()}
                        className='theme-btn mr-16'>
                        Upload Photo
                        <input
                          hidden
                          type='file'
                          accept='image/*'
                          onChange={(e: any) => updatePhoto(e)}
                          onClick={(event: any) => {
                            event.target.value = null;
                          }}
                        />
                      </Button>

                      {/* <Button
                        onClick={() => {
                          setRemoveImage(userData?.file?.id ? userData?.file?.id : '');
                          setLocalImage('');
                          setUploadImage({});
                        }}
                        className='theme-btn'
                        sx={{ opacity: _.isEmpty(uploadImage) ? '0.5' : '' }}
                        disabled={_.isEmpty(uploadImage) ? true : false}>
                        Remove Photo
                      </Button> */}
                    </Typography>
                  </Typography>
                  <Typography component={'div'} className='mt-147'>
                    <Typography className='join-text mb-16'>
                      Joined on <span>{moment(userData?.createdAt).format('DD MMMM YYYY')}</span>
                    </Typography>
                    <Typography className='status-text text-center'>
                      User status
                      <Controller
                        control={control}
                        defaultValue={null}
                        name='status'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Switch
                              value={value}
                              name={name}
                              checked={value}
                              // defaultValue={userData.status}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              className='custom-switch'
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Typography component={'div'} className='form-box' sx={{ minHeight: '660px' }}>
                <form
                  className='private-form'
                  id={'user-add'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <Grid container columnSpacing={4.25}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          First Name <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='firstName'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='First name'
                                error={errors.firstName?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Last Name <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='lastName'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Last name'
                                error={errors.lastName?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={4.25}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Country <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='country'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={country}
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Country'
                                error={errors.country?.message}
                                onChange={(e) => {
                                  onChange(e);
                                  setValue('state', '');
                                  setValue('city', '');
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          State <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='state'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={states}
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='State'
                                error={errors.state?.message}
                                onChange={(e) => {
                                  onChange(e);
                                  setValue('city', '');
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          City <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='city'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={cities}
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='City'
                                error={errors.city?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Pincode <span>*</span>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={null}
                          name='pincode'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                className='custom-input'
                                onChange={onChange}
                                value={value}
                                name={name}
                                placeHolder='Pincode'
                                type={'number'}
                                error={errors.pincode?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Email <span>*</span>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={null}
                          name='email'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <img src={EmailIcon} alt='email' />
                                    </InputAdornment>
                                  ),
                                }}
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Email'
                                error={errors.email?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Phone No <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='PhoneNumberFormatted'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              // <CustomInput
                              //   value={value}
                              //   name={name}
                              //   className='custom-input'
                              //   placeHolder='Phone Number'
                              //   error={errors.phoneNumber?.message}
                              //   onChange={(e) => {
                              //     onChange(e);
                              //   }}
                              // />
                              <CustomPhoneInput
                                placeholder=''
                                containerClass={
                                  errors.phoneNumber?.message ? 'phone-input-error' : ''
                                }
                                inputClass={
                                  errors.phoneNumber?.message
                                    ? 'phone-input phone-input-no-border'
                                    : 'phone-input'
                                }
                                initialCountry={'in'}
                                specialLabel={''}
                                searchClass={'search-custom-class'}
                                value={value}
                                enableSearch={true}
                                searchNotFound={'No Country Found'}
                                inputStyle={{
                                  width: '100%',
                                  padding: '9px 11px',
                                  borderRadius: '6px',
                                  paddingLeft: '60px',
                                  color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                                  fontSize: '16px',
                                  fontFamily: 'Poppins-Regular',
                                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                }}
                                onChange={(value, data, event, formattedValue) => {
                                  const { countryCode, dialCode } = data;
                                  // setValue(
                                  //   'homePhoneISO2',
                                  //   countryCode.toUpperCase(),
                                  // );
                                  // console.log(countryCode,'countryCode',value,data);
                                  // console.log(formattedValue, 'formattedValue');
                                  // console.log(value, 'phnformattedValue');
                                  // console.log(countryCode, 'countryCode');
                                  // console.log(dialCode, 'dialCode');
                                  setValue('countryCodeText', countryCode);
                                  setValue('countryCode', dialCode);
                                  onChange(formattedValue);
                                  setValue('PhoneNumberFormatted', formattedValue);
                                  setValue('phoneNumber', value);
                                  trigger('PhoneNumberFormatted');
                                }}
                                error={
                                  errors.PhoneNumberFormatted?.message
                                    ? errors.PhoneNumberFormatted?.message
                                    : ''
                                }
                                inputProps={{ tabIndex: 11 }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Role <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='role'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={rollist ? rollist : [{ label: '', value: null }]}
                                name={name}
                                disabled={true}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder='Role'
                                error={errors.role?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Branch <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='branchId'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={
                                  branchDatas.length ? branchDatas : [{ label: '', value: null }]
                                }
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder='Select'
                                error={errors.branchId?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Employee ID <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='empCode'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                className='custom-input'
                                onChange={onChange}
                                value={value}
                                name={name}
                                placeHolder='Employee ID'
                                type={'text'}
                                error={errors.empCode?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Saturday Leave <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='saturdayleave'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'ALL', value: 'all' },
                                  { label: 'ODD', value: 'odd' },
                                  { label: 'EVEN', value: 'even' },
                                  { label: 'NONE', value: 'none' },
                                ]}
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder='Select'
                                error={errors.saturdayleave?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Sunday Leave <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='isSunday'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <RadioGroup
                                className='custom-radio'
                                row
                                aria-labelledby=''
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}>
                                <FormControlLabel value={true} control={<Radio />} label='Yes' />
                                <FormControlLabel value={false} control={<Radio />} label='No' />
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProductAdministratorAdd;
