/* eslint-disable no-constant-condition */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import CloseSvg from 'assets/png/close.svg';
import Fileupload from 'assets/png/upload-cloud.svg';
import Deletebucket from 'assets/png/Deletebucket.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import AddIcon from 'assets/png/addplus.svg';
import Xlsxpng from 'assets/png/xlsx.png';
import SearchIcon from 'assets/png/nsearch.svg';
import Loader from 'components/loader';
import Paginate from 'components/Pagination/Pagination';
import { useDebounce } from 'hooks';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { SETTING_ENDPOINT } from 'utils/Constant';
import moment from 'moment';
// import ExcelIcon from 'assets/iconcomponents/excelicon';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImportLeaveSchema } from 'utils/ValidatorSchema';
import CustomSelect from 'components/CustomSelect/customSelect';
import { alertOptions } from 'utils/Config';
import { ImportLeaveType } from 'types';
import Dropzone from 'react-dropzone';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { AccessDenied } from 'utils/helper';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  take?: number | null;
  sortby?: string | null;
}

const Defaultparam = {
  search: null,
  skip: 0,
  order: 'asc',
  sortby: 'createdAt',
  take: 50,
};

export const ImportLeaveList = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [params, setParams] = useState<Statetype>({ ...Defaultparam });
  const [pagetotal, setpagetotal] = useState<number>(0);
  const debouncedParams = useDebounce(params, 500);
  const [importLeaveList, setImportLeaveList] = useState<any>();
  const [leavePolicyType, setLeavePolicyType] = useState<{ label: string; value: string }[]>();
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [isImportResponseOpen, setImportResponseOpen] = useState(false);
  const [importResponse, setImportResponse] = useState<any>();
  const [fileUpload, setfileupload] = useState<any>(null);
  const containerRef = useRef(null) as any;
  const hiddenFileInput: any = useRef(null);
  const handleModalOpen = () => {
    setImportModalOpen(true);
  };
  const handleModalClose = () => {
    reset();
    setfileupload(null);
    setImportModalOpen(false);
  };

  const handleResponseModalClose = (e?: any, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    setImportResponseOpen(false);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ resolver: yupResolver(ImportLeaveSchema) });

  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${SETTING_ENDPOINT.leaves.importLeave}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res: any) => {
        setpagetotal(Number(res.total));
        setImportLeaveList(res && res.data);
        setLoader(false);
      })
      .catch(() => {
        // console.log(e);
        setLoader(false);
      });
  };
  const getLeaveTypeList = () => {
    getAllListData(
      `${SETTING_ENDPOINT.leaves.leavePolicy}/type?organizationId=${orgdy}&sortyBy=type&order=asc`,
      true,
    )
      .then((res: any) => {
        if (res.data.length) {
          const data = res.data.map((r: any) => r.id && r.type && { value: r.id, label: r.type });
          setLeavePolicyType(data);
        }
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        console.log(e);
      });
  };
  const onSubmit = (data: ImportLeaveType) => {
    setLoader(true);
    if (!fileUpload) {
      toast.error('File is required', alertOptions);
      setLoader(false);
      return;
    }

    const formdata = new FormData();
    formdata.append('file', fileUpload[0]);
    formdata.append('leavePolicyId', data.leavePolicyId);
    formdata.append('year', data.year);

    addNewData(
      formdata,
      SETTING_ENDPOINT.leaves.importLeave,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then((res: any) => {
        if (res.data) {
          const hasFailed = res?.data?.errors?.length ?? false;
          if (hasFailed) {
            setImportResponse(res.data);
            setImportResponseOpen(true);
          }
        }
        setLoader(false);
        handleModalClose();
        getList();
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
        console.log(e);
      });
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  const downloadSample = () => {
    getAllListData(`${SETTING_ENDPOINT.leaves.importLeave}/download-sample`, true, {
      responseType: 'arraybuffer',
    })
      .then((res) => {
        const date = Date.now();
        const data = new Blob([res]);
        FileSaver.saveAs(data, `sample_${date}.xlsx`);
        setLoader(false);
      })
      .finally(() => setLoader(false));
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
  }, []);
  const handledelete = () => {
    setfileupload('');
  };

  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 10240) {
          toast.error('File is too big, Please select a file less than 10MB', alertOptions);
          return;
        }
      } else {
        toast.error('You can upload documents of types XLSX only', alertOptions);
        return;
      }
    }
    setfileupload(file);
    hiddenFileInput.current.value = '';
  };

  useEffect(() => {
    getList();
    getLeaveTypeList();
  }, [debouncedParams]);

  return (
    <Grid container>
      {loader && <Loader style={{ zIndex: 1301 }} />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Leaves
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Import Leaves
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Leaves</Typography>
          <Typography component={'div'} className='d-flex-a'>
            {/* <div className='expot-icon'>
              <span>
                <ExcelIcon
                  url={
                    'users?type=export&roles=1,2,3,4,5,6,7,8,9,10,11,12,13,14&order=asc&sortby=name'
                  }
                  fileName={'Users'}
                  setLoader={setLoader}
                />
              </span>
            </div> */}
            <Button className='add-btn' onClick={handleModalOpen}>
              <img src={AddIcon} alt='plus' /> Import
            </Button>
          </Typography>
        </Typography>

        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <TextField
              className='custom-search'
              placeholder='Search'
              id='outlined-start-adornment'
              value={params.search}
              sx={{ width: '320px' }}
              onChange={(e: any) =>
                setParams((prev: any) => ({
                  ...prev,
                  search: e.target.value,
                  skip: 0,
                  take: 50,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={SearchIcon} alt='search' />
                  </InputAdornment>
                ),
                endAdornment: params.search ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => {
                        setParams(() => ({ ...Defaultparam, search: '' }));
                      }}>
                      <CloseIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Typography component={'div'} className='mt-32'>
              <TableContainer component={Paper} className='sb-table' ref={containerRef}>
                <Table className='custom-table' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Emp. ID
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('empId', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('empId', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Employees
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('employee', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('employee', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Leave Category
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('category', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('category', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Leave Type
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('leaveType', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('leaveType', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        Number of Days
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('days', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('days', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Created
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('createdAt', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('createdAt', 'desc')}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {importLeaveList && importLeaveList.length ? (
                      importLeaveList.map((row: any) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell sx={{ textTransform: 'capitalize' }}>
                            {row?.user?.empCode ?? '-'}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }}>
                            {`${row?.user?.firstName ?? ''} ${row?.user?.lastName ?? ''}`
                              ? `${row?.user?.firstName ?? ''} ${row?.user?.lastName ?? ''}`
                              : '-'}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }}>
                            {row?.policy?.category ?? '-'}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }}>
                            {row?.policy?.type?.type ?? '-'}
                          </TableCell>
                          <TableCell align='center' sx={{ textTransform: 'capitalize' }}>
                            {row?.available === '0.00' ? 0 : row?.available ?? '-'}
                          </TableCell>
                          <TableCell>
                            {row?.createdAt ? moment(row?.createdAt).format('DD-MM-YYYY') : '-'}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={5} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Grid container className='mt-24'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='d-flex-a flex-end pagination'>
                  <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>

      <Modal open={isImportModalOpen} onClose={handleModalClose}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '1rem',
            }}>
            <Typography className='text-right'>
              <img
                src={CloseSvg}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleModalClose}
              />
            </Typography>
          </Typography>
          <Typography
            sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center', marginBottom: '1rem' }}>
            Import Leaves
          </Typography>
          <form id='offer-status' className='private-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Leave Type <span>*</span>
                    </Typography>
                    <Controller
                      name='leavePolicyId'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={leavePolicyType}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Select Leave Type'
                          error={errors.leavePolicyId?.message}
                        />
                      )}
                    />
                  </Typography>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Period <span>*</span>
                    </Typography>
                    <Controller
                      name='year'
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={Array.from({ length: 2 }, (_, index) => ({
                            label: moment().year() + index,
                            value: moment().year() + index,
                          }))}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Select Year'
                          error={errors.year?.message}
                        />
                      )}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography onClick={downloadSample} className='link-color'>
                    Download sample format
                  </Typography>
                  <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => {
                      return (
                        <div
                          {...getRootProps({
                            className: 'dropzone container container-position pointer',
                            onClick: () => {
                              hiddenFileInput.current?.click();
                            },
                          })}>
                          <div>
                            <input {...getInputProps()} />
                            <p className='img-container'>
                              <img src={Fileupload} alt='file upload' />
                            </p>
                            <div className='fil-uplod-desc'>
                              <p className='first-heading'>
                                <span className='frst-span' role='button'>
                                  Click to upload
                                </span>{' '}
                                <span className='drago'>or drag and drop</span>
                              </p>
                              <p className='docx'>Upload only XLSX</p>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Dropzone>
                </Typography>
                <div>
                  {fileUpload ? (
                    <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                      <Typography component={'div'} className='custom-field file-view-box'>
                        <Typography component={'p'} className='first-children first-green-children'>
                          <div className='image-container'>
                            <span className='image image-success'>
                              <img
                                style={{ width: '16px', height: '16px' }}
                                src={Xlsxpng}
                                alt='Wordpng'
                              />
                            </span>
                            <span className={'texted'}>
                              <TableTooltip
                                value={
                                  fileUpload && fileUpload[0]
                                    ? fileUpload[0]?.name
                                    : fileUpload
                                      ? fileUpload.fileName
                                      : ''
                                }
                                positionfile={true}></TableTooltip>
                            </span>
                          </div>
                          <div className='Crossicon Crossicon-deletebucket' onClick={handledelete}>
                            <img src={Deletebucket} alt='Crossicon' />
                          </div>
                        </Typography>
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'center' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button form={'offer-status'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Modal open={isImportResponseOpen} onClose={handleResponseModalClose}>
        <Box className='logout-modal p-32' sx={{ width: '40%' }}>
          <Typography component={'div'}>
            <Typography
              className='text-right'
              sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: '600',
                }}>
                List of Failed Leave Imports
              </Typography>
              <img
                src={CloseSvg}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleResponseModalClose}
              />
            </Typography>
          </Typography>
          <Typography component={'div'} sx={{ maxHeight: '400px', overflow: 'auto' }}>
            {importResponse && importResponse?.errors.length ? (
              <TableContainer
                sx={{ border: '1px solid #E4E7EC', borderRadius: '8px', marginTop: '1rem' }}>
                <Table className='custom-table' aria-label='simple table'>
                  <TableHead>
                    <TableCell>
                      <Typography>Emp .ID</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Reason</Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {importResponse?.errors.map((errors: any, i: number) => (
                      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          align='left'
                          sx={{
                            textTransform: 'capitalize',
                            fontWeight: '600',
                            borderRight: '1px solid #E4E7EC',
                            minWidth: '200px',
                          }}>
                          {errors?.empId ?? '-'}
                        </TableCell>
                        <TableCell align='left' sx={{ paddingLeft: '23px !important' }}>
                          {errors?.message ?? '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ''
            )}
          </Typography>
        </Box>
      </Modal>
    </Grid>
  );
};
