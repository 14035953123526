/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Divider,
  // Button,
  // Checkbox,
  // FormControlLabel,
  Grid,
  Link,
  Paper,
  Rating,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';

import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Bigprint from 'assets/png/print-blue.svg';
import * as R from 'ramda';
import Position from 'assets/png/Position2.svg';
import Interview from 'assets/png/Interview2.svg';
import Joining from 'assets/png/Joining1.svg';
import Billing from 'assets/png/Billing1.svg';
import { saveAs } from 'file-saver';
import { To, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getAllListData, getSingleData } from 'services/CommonServices';
import { useEffect, useRef, useState } from 'react';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';
import _ from 'lodash';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Editbluepen from 'assets/png/Frame.svg';
import { ResumesENDPOINT } from 'utils/Constant';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  AccessDenied,
  InterviewLevel,
  InterviewLevelToText,
  ResumeApprovalStatus,
  currentResumeReason,
  currentResumeStatus,
  getResumeStatus,
} from 'utils/helper';
import { toast } from 'react-toastify';
import { EditResume } from '../EditResume';
import { HttpStatusCode } from 'axios';
import ResumeDuplication from '../Add/ResumeDuplication';
import AddResumeNew from '../AddResume';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #C7CDD8',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #C7CDD8',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme?.palette?.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme?.palette?.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});
const ViewResume = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    menuList,
    userInfo: { id: Useruniqueid },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [commonState, setcommonstate] = useState<any[]>([]);
  const [addResumeModalOpen, setAddResumeModalOpen] = useState({ open: false, id: '' });
  const [resumeRowData, setResumeRowData] = useState<any>({});
  const [personlData, setPersonalData] = useState<any>([]);
  const [experienceData, setExperienceData] = useState<any>([]);
  const [educationData, setEducationData] = useState<any[]>([]);
  const [Data, setData] = useState<any>([]);
  const [resumeStatus, setResumeStatus] = useState<any>('');
  const [interviewDetail, setInterviewDetail] = useState<any>([]);
  const [billingData, setBillingData] = useState<any>([]);
  const [clientModule, setClientModule] = useState([]);
  const [consultantAssessment, setConsultantAssessment] = useState<any>([]);
  const [consultantAssessmentRating, setConsultantAssessmentRating] = useState<any>([]);
  const [createAt, setCreateAt] = useState<any>('');
  const [searchParams]: any = useSearchParams();
  const [Ld, setLd] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [resumeName, setResumeName] = useState('1');
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [resumeUploadopen, setResumeUploadOpen] = useState({ open: false, id: '' });
  const [duplication, setDuplication] = useState<{
    data: any;
    message: any;
    duplicationStatus: string;
  }>({
    data: {},
    message: '',
    duplicationStatus: '',
  });
  const duplicationFn = (res: {
    message: any;
    duplicationStatus: string;
    data: any;
    status: HttpStatusCode;
  }) => {
    setDuplication({
      data: res.data,
      message:
        res.data?.duplicateResume?.approvalStatus === ResumeApprovalStatus.Draft
          ? 'Draft'
          : res.data?.duplicateResume?.approvalStatus === ResumeApprovalStatus.InReview
            ? 'CRM Validation Pending'
            : res.data?.duplicateResume?.approvalStatus === 'inProcess'
              ? 'In-Process'
              : res.data?.duplicateResume?.approvalStatus === 'readyForReview'
                ? 'Ready For Review'
                : currentResumeStatus(res.data?.duplicateResume?.resumeStatus),
      duplicationStatus: res.duplicationStatus,
    });
    handleDuplicateOpen();
  };
  const handleDuplicateClose = () => {
    setOpenDuplicate(false);
    setDuplication({ data: {}, message: '', duplicationStatus: '' });
  };
  const handleDuplicateOpen = () => {
    setOpenDuplicate(true);
  };
  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setTabValue(newValue);
  };
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const getResume = () => {
    getSingleData(searchParams.get('id'), ResumesENDPOINT.resumeList)
      .then((res) => {
        if (
          res &&
          !_.isEmpty(res.data) &&
          res.data &&
          res.data.length > 0 &&
          !_.isEmpty(res.data[0])
        ) {
          setData(res && res?.data);
          setBillingData(res?.data[0]?.billing ? res?.data[0]?.billing : '');
          setCreateAt(res?.data[0]?.createdBy ? res?.data[0]?.createdBy : '');
          const data = res?.data[0];
          setResumeStatus(data?.approvalStatus);
          setResumeName(`${data?.personal?.firstName} ${data?.personal?.lastName}`);
          if (!_.isEmpty(data)) {
            setResumeRowData(data);
            setPersonalData(() => [
              { title: 'Code', subtitle: data?.code ? `CT-${data?.code}` : '-' },
              {
                title: 'Candidate Name',
                subtitle: data?.personal?.firstName
                  ? `${data?.personal?.firstName} ${data?.personal?.lastName}`
                  : '-',
              },
              {
                title: 'Email',
                subtitle: data?.personal?.email ? data?.personal?.email : '-',
              },
              {
                title: 'Mobile',
                subtitle: data?.personal?.phoneNumber ? data?.personal?.phoneNumber : '-',
              },
              {
                title: 'DOB',
                subtitle: data?.personal?.dob
                  ? new Date(data?.personal?.dob).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })
                  : '-',
              },
              {
                title: 'Current Designation',
                subtitle: data?.personal?.designation ? data?.personal?.designation?.name : '-',
              },
              {
                title: 'Total Years of Exp',
                subtitle: data?.personal?.expYears
                  ? `${data?.personal?.expYears} year ${data?.personal?.expMonths} months`
                  : '-',
              },
              {
                title: 'Notice Period',
                subtitle:
                  data?.personal?.noticePeriod && data?.personal?.noticePeriod === '0'
                    ? 'Immediate'
                    : data?.personal?.noticePeriod !== '0'
                      ? data?.personal?.noticePeriod
                      : '-',
              },
              {
                title: 'Presently Working',
                subtitle: data?.personal?.isWorking ? 'Yes' : 'No',
              },
              {
                title: 'Current Status',
                subtitle:
                  data?.approvalStatus === ResumeApprovalStatus.Draft
                    ? 'Draft'
                    : data?.approvalStatus === ResumeApprovalStatus.InReview
                      ? 'CRM Validation Pending'
                      : currentResumeStatus(data?.resumeStatus),
              },
              {
                title: 'Reason',
                subtitle: currentResumeReason(data?.resumeStatus) || '--',
              },
              {
                title: 'Family (Dependents)',
                subtitle: data?.personal?.family ? data?.personal?.family : '-',
              },
              {
                title: 'Gender',
                subtitle: data?.personal?.gender ? data?.personal?.gender : '-',
              },
              {
                title: 'CTC(Present & Expect)',
                subtitle: data?.personal?.currentCtc
                  ? `${Number(data?.personal?.currentCtc).toLocaleString(
                      'en-IN',
                    )} ${data?.personal?.currentCtcCurrency.toUpperCase()} - ${Number(
                      data?.personal?.expectedCtc,
                    ).toLocaleString('en-IN')} ${data?.personal?.expectedCtcCurrency.toUpperCase()}`
                  : '-',
              },
              {
                title: 'Marital Status',
                subtitle: data?.personal?.maritalStatus ? data?.personal?.maritalStatus : '-',
              },
              {
                title: 'Present Location',
                subtitle: data?.personal?.presentLocation ? data?.personal?.presentLocation : '-',
              },
              {
                title: 'Native Location',
                subtitle: data?.personal?.native ? data?.personal?.native : '-',
              },
              { title: 'Resume', subtitle: 'Candidate Resume' },
              { title: 'Snapshot', subtitle: 'Download Snapshot' },
              {
                title: 'Created By',
                subtitle: data?.user?.firstName
                  ? `${data?.user?.firstName} ${data?.user?.lastName ? data?.user?.lastName : ''}`
                  : '-',
              },
              {
                title: 'Created',
                subtitle: data?.personal?.createdAt
                  ? moment(data?.personal?.createdAt).format('DD-MM-YYYY')
                  : '-',
              },
              {
                title: 'Modified',
                subtitle: data?.personal?.updatedAt
                  ? moment(data?.personal?.updatedAt).format('DD-MM-YYYY')
                  : '-',
              },
            ]);
            setConsultantAssessmentRating(data?.assessment);
            setEducationData(data?.education);
            setExperienceData(data?.experience);
            setConsultantAssessment(data?.personal);
          }
        }
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };
  const resumeDAta = () => {
    getAllListData(
      `${ResumesENDPOINT.resumeList}?id=${searchParams.get(
        'id',
      )}&type=interview,offer,joining,billing&screen=interview`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          const intervieData = val.data[0].interview;
          intervieData.forEach((r: any, idx: number) =>
            r.rescheduleReason
              ? ((intervieData[idx + 1].status = 'Scheduled'),
                (intervieData[idx].status = 'Selected'))
              : !intervieData[idx].status
                ? (intervieData[idx].status = 'Selected')
                : {},
          );
          setcommonstate([...val.data]);
          setInterviewDetail(intervieData);
        } else {
          setcommonstate([]);
        }
        setLd(!true);
      })
      .catch((e) => {
        setLd(!true);
      });
  };
  useEffect(() => {
    getResume();
    resumeDAta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const resumeCreateAccess = () => {
    getAllListData(ResumesENDPOINT.task, true)
      .then(() => {
        if (resumeRowData.approvalStatus === 'readyForReview') {
          getAllListData(`${ResumesENDPOINT.resumeDuplicate}/${resumeRowData?.id}`, true)
            .then((res: any) => {
              if (res && res?.isDuplicate) {
                duplicationFn(res);
              } else {
                setAddResumeModalOpen({ open: true, id: searchParams.get('id') });
              }
            })
            .catch(() => {});
        } else {
          setAddResumeModalOpen({ open: true, id: searchParams.get('id') });
        }
      })
      .catch(() => {});
  };
  const downloadResume = () => {
    setLd(true);
    getAllListData(`${ResumesENDPOINT.download}/${searchParams.get('id')}`, true)
      .then((res) => {
        const currentDate = new Date();
        saveAs(
          'data:application/pdf;base64,' + res,
          (resumeName ? resumeName : currentDate) + '.pdf',
          { autoBom: true },
        );
        setLd(false);
      })
      .catch((err) => {
        setLd(false);
        console.log(err);
      });
  };
  const downloadSnapShot = () => {
    setLd(true);
    getAllListData(`${ResumesENDPOINT.resumeSnapshot}/${searchParams.get('id')}`, true)
      .then((res) => {
        if (res) {
          const currentDate = new Date();
          saveAs(
            'data:application/pdf;base64,' + res,
            (resumeName ? resumeName : currentDate) + '.pdf',
            { autoBom: true },
          );
          setLd(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLd(false);
      });
  };
  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'Resume');
      setClientModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Typography
                color='text.primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => gotoNavigate(AdminPrivateRoutes.RESUMES.RESUMESLIST)}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Resumes
              </Typography>
              <Typography
                color='text.primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.VIEWPOSITIONS)}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> View Resume
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>View Resume</Typography>
            {R.findIndex(R.propEq('Add Resume', 'screenName'))(clientModule ? clientModule : []) !==
              -1 && (
              <Typography
                component={'div'}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  component={'div'}
                  sx={{
                    marginRight: '24px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    createAt === Useruniqueid &&
                    !['inProcess', 'parsingFailed'].includes(resumeStatus)
                      ? resumeCreateAccess()
                      : '';
                  }}>
                  {createAt === Useruniqueid &&
                  !['inProcess', 'parsingFailed'].includes(resumeStatus) ? (
                    <img src={Editbluepen} alt='edit' />
                  ) : (
                    ''
                  )}
                </Typography>
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Typography
              component={'div'}
              className='main-content view-position'
              style={{ padding: '20px' }}>
              <Typography component={'div'} className='white-box view-position-white-box'>
                <div className='section-heading'>Personal </div>
                <Typography className='bdiveder' />
                <main className='main-content'>
                  {personlData.map((dat1: any, idx: number) => {
                    return (
                      <div className='child-items' key={idx}>
                        <div className='heading'>{dat1.title}</div>

                        {dat1.title === 'Resume' || dat1.title === 'Snapshot' ? (
                          <a
                            style={{ color: '#5F2EE5', cursor: 'pointer' }}
                            onClick={() => {
                              if (
                                !['inProcess', 'readyForReview', 'parsingFailed', 'draft'].includes(
                                  resumeRowData.approvalStatus,
                                )
                              ) {
                                dat1?.title === 'Resume'
                                  ? downloadResume()
                                  : dat1?.title === 'Snapshot'
                                    ? downloadSnapShot()
                                    : '';
                              } else {
                                toast.error('Resume not available for download');
                              }
                            }}>
                            {dat1.subtitle}{' '}
                          </a>
                        ) : (
                          <div className='sub-heading'>{dat1.subtitle}</div>
                        )}
                      </div>
                    );
                  })}
                  <div className='child-items'></div>
                  <div className='child-items'></div>
                </main>
              </Typography>
              <Typography
                component={'div'}
                className='white-box view-position-white-box'
                sx={{ minHeight: '100% !important' }}>
                <Typography className='bdiveder' />
                <div className='section-heading'>Education </div>
                <Typography className='bdiveder' />
                {educationData && educationData.length ? (
                  educationData.map((dat1: any, idx: number) => {
                    return (
                      <>
                        <main className='main-content' key={idx} style={{ gap: '40px 30px' }}>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Qualification'}</div>
                            <div className='sub-heading'>
                              {dat1?.qualification?.name ? dat1?.qualification?.name : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Degree'}</div>
                            <div className='sub-heading'>
                              {dat1?.degree?.degree ? dat1?.degree?.degree : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Specialization'}</div>
                            <div className='sub-heading'>
                              {dat1?.specialization?.specialization
                                ? dat1?.specialization?.specialization
                                : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Institute'}</div>
                            <div className='sub-heading'>
                              {dat1.institute ? dat1.institute : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Year of Passing'}</div>
                            <div className='sub-heading'>{dat1.year ? dat1.year : '-'}</div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'University'}</div>
                            <div className='sub-heading'>
                              {dat1.university ? dat1.university : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Marks / Grade'}</div>
                            <div className='sub-heading'>{dat1.marks ? dat1.marks : '-'}</div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Course Type'}</div>
                            <div className='sub-heading'>
                              {dat1.modeOfEducation ? dat1.modeOfEducation : '-'}
                            </div>
                          </div>
                        </main>
                        {educationData.length - 1 != idx ? <Typography className='bdiveder' /> : ''}
                      </>
                    );
                  })
                ) : (
                  <div className='child-items'>
                    <div className='heading'>{'No Record(s) Found.'}</div>
                  </div>
                )}
              </Typography>
              <Typography
                component={'div'}
                className='white-box view-position-white-box'
                sx={{ minHeight: '100% !important' }}>
                <Typography className='bdiveder' />
                <div className='section-heading'>Experience </div>
                <Typography className='bdiveder' />

                {experienceData && experienceData.length ? (
                  experienceData.map((dat1: any, idx: number) => {
                    return (
                      <>
                        <main className='main-content' key={idx} style={{ gap: '40px 30px' }}>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Designation'}</div>
                            <div className='sub-heading'>
                              {dat1?.designation ? dat1?.designation : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Employment Period'}</div>
                            <div className='sub-heading'>
                              {dat1?.fromDate
                                ? ` ${new Date(dat1?.fromDate).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: 'short',
                                    year: 'numeric',
                                  })}  ${
                                    dat1?.toDate
                                      ? `- ${new Date(dat1?.toDate).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: 'short',
                                          year: 'numeric',
                                        })}`
                                      : dat1?.tillDate
                                        ? '- Present'
                                        : ''
                                  }`
                                : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Location of Work'}</div>
                            <div className='sub-heading'>
                              {dat1?.location ? dat1?.location : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Company Name'}</div>
                            <div className='sub-heading'>
                              {dat1.companyName ? dat1.companyName : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Project / Certification'}</div>
                            <div className='sub-heading'>
                              {dat1.projectAndCertifications ? dat1.projectAndCertifications : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{'Areas of Expertise'}</div>
                            <div className='sub-heading'>
                              {dat1.specialization ? dat1.specialization : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>
                              {'Other Vitual Information (Position Specific)'}
                            </div>
                            <div className='sub-heading'>
                              {dat1.vitalInformation ? dat1.vitalInformation : '-'}
                            </div>
                          </div>
                          <div className='child-items' key={idx}>
                            <div className='heading'>{''}</div>
                            <div className='sub-heading'>{''}</div>
                          </div>
                        </main>
                        {experienceData?.length - 1 != idx ? (
                          <Typography className='bdiveder' />
                        ) : (
                          ''
                        )}
                      </>
                    );
                  })
                ) : (
                  <div className='child-items'>
                    <div className='heading'>{'No Record(s) Found.'}</div>
                  </div>
                )}
              </Typography>
              <Typography
                component={'div'}
                className='white-box view-position-white-box'
                sx={{ minHeight: '100% !important' }}>
                <Typography className='bdiveder' />
                <div className='section-heading'>Consultant Assessment</div>
                <Typography className='bdiveder' />
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <main className='main-content'>
                      {consultantAssessment && (
                        <>
                          <div className='child-items'>
                            <div className='heading'>{'Consultant Assessment'}</div>
                            <div className='sub-heading'>
                              {consultantAssessment.consultantAssessment
                                ? consultantAssessment.consultantAssessment
                                : '-'}
                            </div>
                          </div>
                          <div className='child-items'>
                            <div className='heading'>{'Any Other Inputs'}</div>
                            <div className='sub-heading'>
                              {consultantAssessment.comments ? consultantAssessment.comments : '-'}
                            </div>
                          </div>
                          <div className='child-items'>
                            <div className='heading'>{'Interview Availability'}</div>
                            <div className='sub-heading'>
                              {consultantAssessment.interviewAvailability
                                ? consultantAssessment.interviewAvailability
                                : '-'}
                            </div>
                          </div>
                        </>
                      )}
                    </main>
                  </Grid>
                </Grid>
                <div className='section-heading'>Behavioural Skills</div>
                <Grid container>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: 'flex', marginTop: '10px', flexWrap: 'wrap', gap: '20px 77px' }}>
                    {consultantAssessmentRating && consultantAssessmentRating.length ? (
                      consultantAssessmentRating
                        .filter((re: any) => re.behaviouralSkillsId)
                        .map((dat1: any, idx: number) => {
                          return (
                            <>
                              <div className='child-items' style={{ marginTop: '10px' }} key={idx}>
                                <div>
                                  <div className='heading'>{dat1?.behaviouralSkill?.skill}</div>
                                  <div style={{ display: 'flex' }}>
                                    <Rating
                                      sx={{ color: '#EE4B22 ', marginRight: '7px' }}
                                      value={dat1?.rating}
                                    />
                                    <div
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        top: '448px',
                                        left: '609px',
                                        padding: '0px 6px 5px 6px',
                                        borderRadius: '4px',
                                        border: '1px solid #FDEDE9',
                                        gap: '6px',
                                        background: '#FDEDE9',
                                        fontSize: '15px',
                                      }}>
                                      {dat1?.rating}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                    ) : (
                      <div className='child-items'>
                        <div className='heading'>{'-'}</div>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <div className='section-heading'>Technical Skills</div>
                <Grid container>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ display: 'flex', marginTop: '10px', flexWrap: 'wrap', gap: '20px 77px' }}>
                    {consultantAssessmentRating && consultantAssessmentRating.length ? (
                      consultantAssessmentRating
                        .filter((re: any) => re.technicalSkillsId)
                        .map((dat1: any, idx: number) => {
                          return (
                            <>
                              <div className='child-items' style={{ marginTop: '10px' }} key={idx}>
                                <div>
                                  <div className='heading'>{dat1?.technicalSkill?.skill}</div>
                                  <div style={{ display: 'flex' }}>
                                    <Rating
                                      sx={{ color: '#EE4B22 ', marginRight: '7px' }}
                                      value={dat1?.rating}
                                    />
                                    <div
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        top: '448px',
                                        left: '609px',
                                        padding: '0px 6px 5px 6px',
                                        borderRadius: '4px',
                                        border: '1px solid #FDEDE9',
                                        gap: '6px',
                                        background: '#FDEDE9',
                                        fontSize: '15px',
                                      }}>
                                      {dat1?.rating}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                    ) : (
                      <div className='child-items'>
                        <div className='heading'>{'-'}</div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Typography>
              <Typography
                component={'div'}
                className='white-box view-position-white-box'
                sx={{ minHeight: '100% !important' }}>
                <Typography className='bdiveder' />
                <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
                  <Box>
                    <TabContext value={tabValue}>
                      <Typography
                        component={'div'}
                        className='d-flex-a d-flex-sb custom-position-tab'>
                        <Box
                          sx={{
                            borderColor: 'divider',
                            borderRadius: '8px',
                            background: '#F5F6FA',
                            padding: '5px',
                          }}>
                          <TabList onChange={handleChange} aria-label='lab API tabs example'>
                            <Tab
                              icon={
                                <img
                                  src={Position}
                                  alt='Position'
                                  style={{
                                    height: '18px',
                                    width: '18px',
                                    marginBottom: '0px',
                                    marginRight: '10px',
                                  }}
                                />
                              }
                              label='Position Details'
                              value='1'
                            />

                            <Tab
                              icon={
                                <img
                                  src={Interview}
                                  alt='Interview'
                                  style={{
                                    height: '18px',
                                    width: '18px',
                                    marginBottom: '0px',
                                    marginRight: '10px',
                                  }}
                                />
                              }
                              label='Interview Details'
                              value='2'
                            />

                            <Tab
                              icon={
                                <img
                                  src={Joining}
                                  alt='Joining'
                                  style={{
                                    height: '18px',
                                    width: '18px',
                                    marginBottom: '0px',
                                    marginRight: '10px',
                                  }}
                                />
                              }
                              label='Joining Details'
                              value='3'
                            />

                            <Tab
                              icon={
                                <img
                                  src={Billing}
                                  alt='Billing'
                                  style={{
                                    height: '18px',
                                    width: '18px',
                                    marginBottom: '0px',
                                    marginRight: '10px',
                                  }}
                                />
                              }
                              label='Billing Details'
                              value='4'
                            />
                          </TabList>
                        </Box>
                      </Typography>
                      <Typography component={'div'}>
                        <TabPanel value='1' sx={{ padding: '10px 0px' }}>
                          <Grid item md={12}>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                className='custom-table'
                                aria-label='simple table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Job Title</TableCell>
                                    <TableCell>Client</TableCell>
                                    <TableCell>Contact Person</TableCell>
                                    <TableCell>Contact Email</TableCell>
                                    <TableCell>Contact No</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Data && Data.length ? (
                                    Data.map((row: any) => (
                                      <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                          {row?.position?.title ? row?.position?.title : '-'}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                          {row?.client?.corporateName
                                            ? row?.client?.corporateName
                                            : '-'}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                          {row.position?.spoc?.firstName
                                            ? `${row.position?.spoc?.firstName} ${
                                                row.position?.spoc?.firstName
                                                  ? row.position?.spoc?.lastName
                                                  : ''
                                              }`
                                            : '-'}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                          {row.position?.spoc?.email
                                            ? row.position?.spoc?.email
                                            : '-'}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                          {row.position?.spoc?.phoneNumber
                                            ? row.position?.spoc?.phoneNumber
                                            : '-'}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <tr className={'text-center'}>
                                      <td colSpan={6} align={'center'}>
                                        No Record(s) Found.
                                      </td>
                                    </tr>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </TabPanel>
                        <TabPanel value='2' sx={{ padding: '10px 0px' }}>
                          <Grid item md={12}>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                className='custom-table'
                                aria-label='simple table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Interview Date</TableCell>
                                    <TableCell>Stage</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {interviewDetail && interviewDetail.length ? (
                                    interviewDetail.map(
                                      (
                                        {
                                          dateTime,
                                          level,
                                          id,
                                          remarks,
                                          rescheduleReason,
                                          status,
                                        }: any,
                                        i: any,
                                      ) => {
                                        const interviewDate = dateTime
                                          ? moment(dateTime).format('DD-MM-YYYY')
                                          : '-';
                                        const interviewLevel = level
                                          ? InterviewLevelToText[level as InterviewLevel]
                                          : '';

                                        const interviewStatus =
                                          i === 0
                                            ? commonState[0]?.resumeStatus?.find(
                                                (e: any) => e.type === 'interview',
                                              )?.status === 'S'
                                              ? 'Selected'
                                              : commonState[0]?.resumeStatus?.find(
                                                    (e: any) => e.type === 'interview',
                                                  )?.status === 'R'
                                                ? 'Rejected'
                                                : rescheduleReason
                                                  ? 'Re-Scheduled'
                                                  : 'Scheduled'
                                            : status;
                                        return (
                                          <TableRow
                                            key={id}
                                            sx={{
                                              '&:last-child td, &:last-child th': { border: 0 },
                                            }}>
                                            <TableCell sx={{ textTransform: 'capitalize' }}>
                                              {interviewDate}
                                            </TableCell>
                                            <TableCell sx={{ textTransform: 'capitalize' }}>
                                              {interviewLevel}
                                            </TableCell>
                                            <TableCell sx={{ textTransform: 'capitalize' }}>
                                              {interviewStatus ? interviewStatus : '-'}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      },
                                    )
                                  ) : (
                                    <tr className={'text-center'}>
                                      <td colSpan={4} align={'center'}>
                                        No Record(s) Found.
                                      </td>
                                    </tr>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </TabPanel>
                        <TabPanel value='3' sx={{ padding: '10px 0px' }}>
                          <Grid item md={12}>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                className='custom-table'
                                aria-label='simple table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Offer Date</TableCell>
                                    <TableCell>Offered CTC</TableCell>
                                    <TableCell>Joined Date</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {Data &&
                                  Data?.length &&
                                  (Data[0]?.offeredDate ||
                                    Data[0]?.offeredCTC > 0 ||
                                    Data[0]?.joinedDate) ? (
                                    Data.map((row: any) => (
                                      <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                          {row?.offeredDate ? row?.offeredDate : '-'}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                          {row.offeredCTC && row.offeredCTC > 0
                                            ? `${Number(row.offeredCTC).toLocaleString('en-IN')}`
                                            : '-'}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                          {row.joinedDate ? row.joinedDate : '-'}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <tr className={'text-center'}>
                                      <td colSpan={4} align={'center'}>
                                        No Record(s) Found.
                                      </td>
                                    </tr>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </TabPanel>
                        <TabPanel value='4' sx={{ padding: '10px 0px' }}>
                          <Grid item md={12}>
                            <TableContainer component={Paper}>
                              <Table className='custom-table' aria-label='simple table'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Billing Date</TableCell>
                                    <TableCell>Billing Amount</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {billingData && billingData ? (
                                    <TableRow
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell sx={{ textTransform: 'capitalize' }}>
                                        {billingData.billingDate ? billingData.billingDate : '-'}
                                      </TableCell>
                                      <TableCell sx={{ textTransform: 'capitalize' }}>
                                        {billingData.billingAmount
                                          ? `${Number(billingData.billingAmount).toLocaleString(
                                              'en-IN',
                                            )}`
                                          : '-'}
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <tr className={'text-center'}>
                                      <td colSpan={2} align={'center'}>
                                        No Record(s) Found.
                                      </td>
                                    </tr>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </TabPanel>
                      </Typography>
                    </TabContext>
                  </Box>
                </Grid>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {addResumeModalOpen.open && (
          <EditResume
            addResumeModalOpen={addResumeModalOpen}
            setAddResumeModalOpen={setAddResumeModalOpen}
            id={resumeRowData?.id}
            fileId={resumeRowData?.fileId}
            resumeStatus={resumeRowData}
            // isIgnore={isIgnore}
            setResumeRowData={setResumeRowData}
            listdataapi={getResume}
            view={true}
          />
        )}
        {!_.isEmpty(duplication?.data) && openDuplicate && (
          <ResumeDuplication
            open={openDuplicate}
            data={duplication?.data}
            message={duplication?.message}
            duplicationStatus={duplication?.duplicationStatus}
            handleClose={handleDuplicateClose}
            setResumeUploadOpen={setResumeUploadOpen}
            setAddResumeModalOpen={setAddResumeModalOpen}
            // setIsIgnore={setIsIgnore}
            id={searchParams.get('id')}
          />
        )}
        {resumeUploadopen.open && (
          <AddResumeNew
            setResumeUploadOpen={setResumeUploadOpen}
            resumeUploadopen={resumeUploadopen}
            id={searchParams.get('id')}
            listdataapi={getResume}
            setResumeRowData={setResumeRowData}
          />
        )}
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default ViewResume;
