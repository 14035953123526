import { Grid, TextField, Typography } from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { DashboradFilterType } from 'types';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';

interface ClientFilterFormProps {
  control: Control<DashboradFilterType, any>;
  handleSubmit: UseFormHandleSubmit<DashboradFilterType, undefined>;
  formState: FormState<DashboradFilterType>;
  onSubmit: (data: DashboradFilterType) => void;
  watch: UseFormWatch<DashboradFilterType>;
  trigger: UseFormTrigger<DashboradFilterType>;
  setParams: any;
  option?: any;
  setLd?: any;
}

export const DashboardFilterForm: React.FC<ClientFilterFormProps> = ({
  control,
  handleSubmit,
  formState: { errors },
  onSubmit,
  watch,
  option,
  setLd,
}) => {
  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = currentDate.getFullYear();
  const [fromMaxDate, setFromMaxDate] = useState<string | null>(null);
  const [toMaxDate, setToMaxDate] = useState<string | null>(null);
  const [ClientData, setClientData] = useState<any>([]);
  React.useEffect(() => {
    getAllListData(`${DASHBOARD_ENDPOINT.Clients}?type=${option.toLowerCase()}`, true)
      .then((val: any) => {
        const options: any = [];
        val.data.map((e: any) => {
          options.push({ label: e.corporateName, value: e.id });
        });
        setClientData(options);
        setLd(!true);
      })
      .catch(() => {
        setLd(!true);
      });
  }, []);
  return (
    <form
      className='private-form clientfilter-form'
      onSubmit={handleSubmit(onSubmit)}
      id='clientfilter-form'>
      <Grid container>
        <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
          <Grid container sx={{ width: '100%' }}>
            <Grid item md={12} sm={12} xs={12} sx={{ width: '100%' }}>
              <Typography component={'div'} className='custom-field' sx={{ width: '100%' }}>
                <Typography component={'p'}>From Date</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='fromDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        defaultValue={value}
                        className='clientfilter-time-from-to'
                        onChange={(e) => {
                          setFromMaxDate(e.target.value);
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { max: toMaxDate || `${year}-${month}-${day}` },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>To Date</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='toDate'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        defaultValue={value}
                        onChange={(e) => {
                          setToMaxDate(e.target.value);
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { min: fromMaxDate, max: `${year}-${month}-${day}` },
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            {/* <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Report Type</Typography>
                <Controller
                  control={control}
                  name='reportType'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        labelKey={'label'}
                        valueKey={'value'}
                        isClearable={false}
                        options={[]}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Type'
                        error={errors.reportType?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid> */}
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Client</Typography>
                <Controller
                  control={control}
                  name='clientId'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        isClearable={false}
                        labelKey={'label'}
                        valueKey={'value'}
                        options={ClientData}
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        placeHolder='Select Client'
                        error={errors.clientId?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <button
        type='submit'
        className='clientfilter-form-btn'
        form='clientfilter-form'
        style={{
          ...(!(watch('fromDate') || watch('toDate') || watch('clientId') || watch('reportType'))
            ? { opacity: '0.5', cursor: 'not-allowed' }
            : {}),
        }}>
        Apply
      </button>
    </form>
  );
};
