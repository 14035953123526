import { Checkbox, Grid, styled, Typography } from '@mui/material';
import AddIcon from 'assets/iconcomponents/addicon';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DeleteIcon from 'assets/png/Deleteblack.svg';
import { getAllListData } from 'services/CommonServices';
import { ClientsENDPOINT } from 'utils/Constant';
import { ModelComponent } from './modelComponent';
import { yupResolver } from '@hookform/resolvers/yup';
import { DesignationSchema } from 'utils/ValidatorSchema';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

export const ReplacementClause = ({
  errors,
  control,
  watch,
  getValues,
  setValue,
  setLd,
  removeExistingDesignation,
  removeData,
  handleSubmit,
  onSubmitForm,
}: any) => {
  const [model, setModel] = useState<any>({
    open: false,
    type: '',
  });
  const [noOfDays, setNoOfDays] = useState<any>();
  // const [code, setCode] = useState<any>('');
  const [level, setLevel] = useState<any>();
  const [ctcWiseData, setCtcWiseData] = useState<any>();
  const handleOpen = (open: boolean, type: string) => {
    setModel((prev: any) => ({
      ...prev,
      open: open,
      type: type,
    }));
  };
  const handleClose = () => {
    reset();
    setModel((prev: any) => ({
      ...prev,
      open: false,
      type: '',
    }));
  };
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset,
    formState: { errors: errors1 },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      type: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(DesignationSchema),
  });
  const {
    fields: fieldsOne,
    append: appendOne,
    // replace: replaceform,
    // update,
    remove: levelRemove,
    // swap,
  } = useFieldArray({
    control,
    name: 'replaceClientDesignations',
  });
  const {
    fields: fieldsTwo,
    append: appendTwo,
    // replace: replaceform,
    // update,
    remove: ctcRemove,
    // swap,
  } = useFieldArray({
    control,
    name: 'replaceClientDesignationCTC',
  });
  useEffect(() => {
    if (watch(`replaceClientDesignations.${0}.levelWise`)) {
      getValues('replaceClientDesignations')?.map((e: any, i: any) => {
        setValue(`replaceClientDesignations.${i}.levelWise`, true);
      });
    }
    if (watch(`replaceClientDesignationCTC.${0}.ctcWise`)) {
      getValues('replaceClientDesignationCTC')?.map((e: any, i: any) => {
        setValue(`replaceClientDesignationCTC.${i}.ctcWise`, true);
      });
    }
  }, [
    watch(`replaceClientDesignations.${0}.levelWise`),
    watch('replaceClientDesignations'),
    watch(`replaceClientDesignationCTC.${0}.ctcWise`),
    watch('replaceClientDesignationCTC'),
  ]);
  const percentageData = () => {
    if (
      watch(`replaceClientDesignations.${0}.levelWise`) ||
      watch(`replaceClientDesignationCTC.${0}.ctcWise`) ||
      watch('replaceAcrossAllLevel')
    ) {
      setLd(true);
      getAllListData(
        `${ClientsENDPOINT.ClientDays}?type=${
          watch(`replaceClientDesignations.${0}.levelWise`)
            ? 'levelWise'
            : watch(`replaceClientDesignationCTC.${0}.ctcWise`)
              ? 'ctcWise'
              : watch('replaceAcrossAllLevel')
                ? 'acrossAllLevel'
                : ''
        }&status&order&sortby&skip&take`,
        true,
      )
        .then((res: any) => {
          if (res && res.data && res.data.length) {
            const options: any = [];
            res.data.map((e: any) => {
              options.push({
                label: e.days ? `${e.days} Days` : '',
                value: e.id,
              });
            });
            options.push({
              label: '+ Add Days',
              value: 'AddPercentage',
              isDisabled: true,
              isFixed: true,
              isSelectable: false,
              custmdwn: true,
            });
            setNoOfDays(options);
          }
          setLd(!true);
        })
        .catch(() => {
          // console.log(e);
          setLd(!true);
        });
    } else {
      setNoOfDays([]);
    }
  };
  const levelData = () => {
    if (watch(`replaceClientDesignations.${0}.levelWise`)) {
      setLd(true);
      getAllListData(`${ClientsENDPOINT.ClientLevel}?status&order&sortby&skip&take`, true)
        .then((res: any) => {
          if (res && res.data && res.data.length) {
            const options: any = [];
            res.data.map((e: any) => {
              options.push({
                label: e.level ? e.level : '',
                value: e.id,
              });
            });
            options.push({
              label: '+ Add Level',
              value: 'AddLevel',
              isDisabled: true,
              isFixed: true,
              isSelectable: false,
              custmdwn: true,
            });
            setLevel(options);
          }
          setLd(!true);
        })
        .catch(() => {
          // console.log(e);
          setLd(!true);
        });
    } else {
      setLevel([]);
    }
  };
  const ctcData = () => {
    if (watch(`replaceClientDesignationCTC.${0}.ctcWise`)) {
      setLd(true);
      getAllListData(`${ClientsENDPOINT.ClientCTC}?status&order&sortby&skip&take`, true)
        .then((res: any) => {
          if (res && res.data && res.data.length) {
            const options: any = [];
            res.data.map((e: any) => {
              options.push({
                label: e.ctc ? `${e.ctc} LPA` : '',
                value: e.id,
              });
            });
            options.push({
              label: '+ Add CTC',
              value: 'AddLevel',
              isDisabled: true,
              isFixed: true,
              isSelectable: false,
              custmdwn: true,
            });
            setCtcWiseData(options);
          }
          setLd(!true);
        })
        .catch(() => {
          // console.log(e);
          setLd(!true);
        });
    } else {
      setCtcWiseData([]);
    }
  };
  useEffect(() => {
    percentageData();
    levelData();
    ctcData();
  }, [
    watch(`replaceClientDesignationCTC.${0}.ctcWise`),
    watch(`replaceClientDesignations.${0}.levelWise`),
    watch('replaceAcrossAllLevel'),
  ]);
  return (
    <div>
      <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
        <Typography component={'p'} className='mt-15'>
          Designation
        </Typography>
        <Grid
          container
          columnSpacing={3}
          sx={{
            display:
              watch(`replaceClientDesignations.${0}.levelWise`) ||
              watch(`replaceClientDesignationCTC.${0}.ctcWise`)
                ? 'none'
                : '',
          }}>
          <Grid item md={3.5} sm={12} xs={12} sx={{ marginTop: '25px' }}>
            <Typography component={'div'} className=' d-flex custom-field'>
              <Controller
                control={control}
                defaultValue={''}
                name='replaceAcrossAllLevel'
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <Checkbox
                      className='custom-checkbox'
                      sx={{
                        '&:hover': { bgcolor: 'transparent' },
                      }}
                      disableRipple
                      color='default'
                      checkedIcon={<CheckedIcon />}
                      icon={<CheckIcon />}
                      checked={value}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          removeExistingDesignation('replacement');
                        }
                        onChange(e);
                      }}
                    />
                  );
                }}
              />
              <Typography component={'p'} style={{ marginLeft: '10px', marginTop: '5px' }}>
                Across all levels
              </Typography>
            </Typography>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}>No. Of Days</Typography>
              <Controller
                control={control}
                defaultValue={''}
                name='replaceAllLevelPercentage'
                render={({ field: { onChange, value, name } }) => {
                  const dat =
                    noOfDays && noOfDays.length
                      ? noOfDays
                      : [
                          {
                            label: '+ Add Days',
                            value: 'AddPercentage',
                            isDisabled: true,
                            isFixed: true,
                            isSelectable: false,
                            custmdwn: true,
                          },
                        ];
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      custmfn={() => {
                        setValue1('type', 'days');
                        handleOpen(true, 'acrossAllLevel');
                      }}
                      options={dat}
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      placeHolder='Select'
                      error={errors.replaceAllLevelPercentage?.message}
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={3}
          sx={{
            display:
              watch('replaceAcrossAllLevel') || watch(`replaceClientDesignationCTC.${0}.ctcWise`)
                ? 'none'
                : '',
          }}>
          {fieldsOne.map((ers: any, idx: number) => {
            return (
              <React.Fragment key={ers.id}>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography
                    component={'div'}
                    className='custom-field d-flex'
                    style={{ marginBottom: '0px' }}>
                    {idx === 0 && (
                      <Controller
                        control={control}
                        defaultValue={false}
                        name={`replaceClientDesignations.${idx}.levelWise`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Checkbox
                              className='custom-checkbox'
                              sx={{
                                '&:hover': { bgcolor: 'transparent' },
                                marginBottom: '8px',
                              }}
                              disableRipple
                              color='default'
                              checkedIcon={<CheckedIcon />}
                              icon={<CheckIcon />}
                              checked={value}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  removeExistingDesignation('replacement');
                                }
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    )}
                    <Typography component={'p'} style={{ marginLeft: '10px' }}>
                      Level Wise (From)
                    </Typography>
                  </Typography>
                  <Typography component={'div'} className='custom-field'>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`replaceClientDesignations.${idx}.levelWiseFrom`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          level && level.length
                            ? level
                            : [
                                {
                                  label: '+ Add Level',
                                  value: 'AddLevel',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={dat}
                            custmfn={() => {
                              setValue1('type', 'level');
                              handleOpen(true, 'level');
                            }}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={errors?.replaceClientDesignations?.[idx]?.levelWiseFrom?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Level Wise (To)</Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`replaceClientDesignations.${idx}.levelWiseTo`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          level && level.length
                            ? level
                            : [
                                {
                                  label: '+ Add Level',
                                  value: 'AddLevel',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            custmfn={() => {
                              setValue1('type', 'level');
                              handleOpen(true, 'level');
                            }}
                            options={dat}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={errors?.replaceClientDesignations?.[idx]?.levelWiseTo?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>No. Of Days</Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`replaceClientDesignations.${idx}.levelWisePercentage`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          noOfDays && noOfDays.length
                            ? noOfDays
                            : [
                                {
                                  label: '+ Add Days',
                                  value: 'AddPercentage',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            custmfn={() => {
                              setValue1('type', 'days');
                              handleOpen(true, 'levelWise');
                            }}
                            options={dat}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={
                              errors?.replaceClientDesignations?.[idx]?.levelWisePercentage?.message
                            }
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={1.5} sm={12} xs={12} className='mt-15 d-flex-ja'>
                  {watch(`replaceClientDesignations.${0}.levelWise`) && idx === 0 && (
                    <AddIcon
                      onClick={() =>
                        appendOne({
                          levelWise: '',
                          levelWiseFrom: '',
                          levelWiseTo: '',
                          levelWisePercentage: '',
                        })
                      }
                    />
                  )}
                  {idx != 0 && (
                    <img
                      src={DeleteIcon}
                      style={{ marginLeft: '20px', cursor: 'pointer' }}
                      alt='Crossicon'
                      onClick={() => {
                        if (watch(`replaceClientDesignations.${idx}.id`)) {
                          removeData([watch(`replaceClientDesignations.${idx}.id`)], 'replacement');
                        }
                        levelRemove(idx);
                      }}
                    />
                  )}
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid
          container
          columnSpacing={3}
          sx={{
            display:
              watch('replaceAcrossAllLevel') || watch(`replaceClientDesignations.${0}.levelWise`)
                ? 'none'
                : '',
          }}>
          {fieldsTwo.map((ers: any, idx: number) => {
            return (
              <React.Fragment key={ers.id}>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography
                    component={'div'}
                    className='custom-field d-flex'
                    style={{ marginBottom: '0px' }}>
                    {idx === 0 && (
                      <Controller
                        control={control}
                        defaultValue={false}
                        name={`replaceClientDesignationCTC.${idx}.ctcWise`}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <Checkbox
                              className='custom-checkbox'
                              sx={{
                                '&:hover': { bgcolor: 'transparent' },
                                marginBottom: '8px',
                              }}
                              disableRipple
                              color='default'
                              checkedIcon={<CheckedIcon />}
                              icon={<CheckIcon />}
                              checked={value}
                              name={name}
                              value={value}
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  removeExistingDesignation('replacement');
                                }
                                onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    )}
                    <Typography component={'p'} style={{ marginLeft: '10px' }}>
                      CTC Wise (From)
                    </Typography>
                  </Typography>
                  <Typography component={'div'} className='custom-field'>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`replaceClientDesignationCTC.${idx}.ctcWiseFrom`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          ctcWiseData && ctcWiseData.length
                            ? ctcWiseData
                            : [
                                {
                                  label: '+ Add CTC',
                                  value: 'AddPercentage',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            custmfn={() => {
                              setValue1('type', 'ctc');
                              handleOpen(true, 'ctc');
                            }}
                            options={dat}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={errors?.replaceClientDesignationCTC?.[idx]?.ctcWiseFrom?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>CTC Wise (To)</Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`replaceClientDesignationCTC.${idx}.ctcWiseTo`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          ctcWiseData && ctcWiseData.length
                            ? ctcWiseData
                            : [
                                {
                                  label: '+ Add CTC',
                                  value: 'AddPercentage',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            custmfn={() => {
                              setValue1('type', 'ctc');
                              handleOpen(true, 'ctc');
                            }}
                            options={dat}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={errors?.replaceClientDesignationCTC?.[idx]?.ctcWiseTo?.message}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={3.5} sm={12} xs={12} className='mt-15'>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>No. Of Days</Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`replaceClientDesignationCTC.${idx}.ctcWisePercentage`}
                      render={({ field: { onChange, value, name } }) => {
                        const dat =
                          noOfDays && noOfDays.length
                            ? noOfDays
                            : [
                                {
                                  label: '+ Add Days',
                                  value: 'AddPercentage',
                                  isDisabled: true,
                                  isFixed: true,
                                  isSelectable: false,
                                  custmdwn: true,
                                },
                              ];
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            custmfn={() => {
                              setValue1('type', 'days');
                              handleOpen(true, 'ctcWise');
                            }}
                            options={dat}
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeHolder='Select'
                            error={
                              errors?.replaceClientDesignationCTC?.[idx]?.ctcWisePercentage?.message
                            }
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={1.5} sm={12} xs={12} className='mt-15 d-flex-ja'>
                  {watch(`replaceClientDesignationCTC.${0}.ctcWise`) && idx === 0 && (
                    <AddIcon
                      onClick={() =>
                        appendTwo({
                          ctcWise: '',
                          ctcWiseFrom: '',
                          ctcWiseTo: '',
                          ctcWisePercentage: '',
                        })
                      }
                    />
                  )}
                  {idx != 0 && (
                    <img
                      src={DeleteIcon}
                      alt='Crossicon'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (watch(`replaceClientDesignationCTC.${idx}.id`)) {
                          removeData(
                            [watch(`replaceClientDesignationCTC.${idx}.id`)],
                            'replacement',
                          );
                        }
                        ctcRemove(idx);
                      }}
                    />
                  )}
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </form>
      <ModelComponent
        control1={control1}
        errors1={errors1}
        handleSubmit1={handleSubmit1}
        model={model}
        handleClose={handleClose}
        setLd={setLd}
        percentageData={percentageData}
        levelData={levelData}
        ctcData={ctcData}
      />
    </div>
  );
};
