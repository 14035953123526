import React, { useEffect, useRef, useState } from 'react';
import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import PreIcon from 'assets/png/pre.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { To, useNavigate } from 'react-router-dom';
import { deleteData, getAllListData } from 'services/CommonServices';
import { alertOptions } from 'utils/Config';
import { toast } from 'react-toastify';
import { useDebounce } from 'hooks';
import { SETTING_ENDPOINT, ResumesENDPOINT } from 'utils/Constant';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import Paginate from 'components/Pagination/Pagination';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import Edit from 'assets/png/Edit.png';
import Download from 'assets/png/Download.png';
import Upload from 'assets/png/Upload.svg';
import View from 'assets/png/View.png';
import _ from 'lodash';
import moment from 'moment';
import Snapshot from 'assets/png/Snapshot.png';
import ThreedotIcon from 'assets/png/threedot.svg';
import { ResumeFilterDrawer } from 'pages/Admin/Resume/List/ViewResumeFilter/ResumeFilterDrawer';
import { saveAs } from 'file-saver';
import * as R from 'ramda';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { AccessDenied, ResumeApprovalStatus, currentResumeStatus } from 'utils/helper';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import AddResumeNew from '../AddResume';
import { EditResume } from '../EditResume';
import ResumeDuplication from '../Add/ResumeDuplication';
import { HttpStatusCode } from 'axios';
import SyncSvgIcon from 'components/Rotate';

const ResumeList = () => {
  const {
    userInfo: { organizationId: orgdy, id: Useruniqueid },
    menuList,
  }: any = useAppSelector((store: any) => store.userLogin);
  const Defaultparam = {
    search: null,
    userId: null,
    order: null,
    skip: 0,
    sortby: null,
    status: null,
    take: 50,
    from: null,
    to: null,
    crm: null,
    organizationId: null,
    approvalStatus: null,
    interviewFrom: null,
    interviewTo: null,
    employee: null,
    branch: null,
    minExp: null,
    maxExp: null,
    currentStatus: null,
  };
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [SyncState, setSyncState] = useState(true);
  const [reupload, setReupload] = useState(false);
  // const [isIgnore, setIsIgnore] = useState(false);
  const [duplication, setDuplication] = useState<{
    data: any;
    message: any;
    duplicationStatus: string;
  }>({
    data: {},
    message: '',
    duplicationStatus: '',
  });

  const duplicationFn = (res: {
    message: any;
    duplicationStatus: string;
    data: any;
    status: HttpStatusCode;
  }) => {
    setDuplication({
      data: res.data,
      message:
        res.data?.duplicateResume?.approvalStatus === ResumeApprovalStatus.Draft
          ? 'Draft'
          : res.data?.duplicateResume?.approvalStatus === ResumeApprovalStatus.InReview
            ? 'CRM Validation Pending'
            : res.data?.duplicateResume?.approvalStatus === 'inProcess'
              ? 'In-Process'
              : res.data?.duplicateResume?.approvalStatus === 'readyForReview'
                ? 'Ready For Review'
                : currentResumeStatus(res.data?.duplicateResume?.resumeStatus),
      duplicationStatus: res.duplicationStatus,
    });
    handleDuplicateOpen();
  };
  const handleDuplicateClose = () => {
    setOpenDuplicate(false);
    setDuplication({ data: {}, message: '', duplicationStatus: '' });
  };
  const handleDuplicateOpen = () => {
    setOpenDuplicate(true);
  };
  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [Ld, setLd] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clientModule, setClientModule] = useState([]);
  const [resumeRowData, setResumeRowData] = useState<any>({});
  const debouncedParams = useDebounce(params, 500);
  const [resumeUploadopen, setResumeUploadOpen] = useState({ open: false, id: '' });
  const [addResumeModalOpen, setAddResumeModalOpen] = React.useState({ open: false, id: '' });
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, row?: any) => {
    setResumeRowData(row);
    setPAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // setResumeRowData({});
    setPAnchorEl(null);
  };
  const open = Boolean(panchorEl);
  const paid = open ? 'simple-popover' : undefined;
  const listdataapi = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `approvalStatus=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
      `${debouncedParams.interviewFrom ? `interviewFrom=${debouncedParams.interviewFrom}` : null}`,
      `${debouncedParams.interviewTo ? `interviewTo=${debouncedParams.interviewTo}` : null}`,
      // `${debouncedParams.employee ? `createdBy=${debouncedParams.employee}` : null}`,
      `${debouncedParams.branch ? `branchId=${debouncedParams.branch}` : null}`,
      `${debouncedParams.minExperience ? `expMin=${debouncedParams.minExperience}` : null}`,
      `${debouncedParams.maxExperience ? `expMax=${debouncedParams.maxExperience}` : null}`,
      `${debouncedParams.currentStatus ? `currentStatus=${debouncedParams.currentStatus}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      'screen=resume',
      // !debouncedParams.employee ? (roleId !== 6 ? `respectiveUserId=${Useruniqueid}` : '') : '',
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);
    setSyncState(true);
    getAllListData(
      `${ResumesENDPOINT.resumeListData}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
        } else {
          setcommonstate([]);
        }
        setSyncState(false);
        setLd(!true);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(!true);
        setSyncState(false);
      });
  };

  useEffect(() => {
    listdataapi();
    // setcommonstate([]);
  }, [debouncedParams]);

  const deletebtn = async (id: any) => {
    setLd(true);
    try {
      await deleteData(id, `${SETTING_ENDPOINT.functionalArea}`, true);
      // toast.success(deleted.message, alertOptions);
      setConfirmOpen({
        show: false,
        id: '',
      });
      listdataapi();
    } catch (err) {
      toast.error(String(err), alertOptions);
    } finally {
      setLd(!true);
    }
  };

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }
  function handleFilterPag(filteredobj1: any) {
    setParams((prev: any) => ({
      ...prev,
      ...filteredobj1,
    }));
  }

  const downloadResume = () => {
    setLd(true);
    getAllListData(`${ResumesENDPOINT.download}/${resumeRowData?.id}`, true)
      .then((res) => {
        saveAs(
          'data:application/pdf;base64,' + res,
          (resumeRowData?.personal
            ? `${resumeRowData?.personal?.firstName} ${resumeRowData?.personal?.lastName}`
            : resumeRowData?.file?.fileName) + '.pdf',
          { autoBom: true },
        );
        setLd(false);
        handleClose();
      })
      .catch((err) => {
        setLd(false);
        console.log(err);
      });
  };
  const CreateReasume = () => {
    getAllListData(ResumesENDPOINT.task, true)
      .then(() => {
        setResumeUploadOpen({ open: true, id: '' });
      })
      .catch(() => {});
  };
  const resumeCreateAccess = () => {
    getAllListData(ResumesENDPOINT.task, true)
      .then(() => {
        if (resumeRowData.approvalStatus === 'readyForReview') {
          getAllListData(`${ResumesENDPOINT.resumeDuplicate}/${resumeRowData?.id}`, true)
            .then((res: any) => {
              if (res && res?.isDuplicate) {
                duplicationFn(res);
                handleClose();
              } else {
                setAddResumeModalOpen({ open: true, id: '' });
                handleClose();
              }
            })
            .catch(() => {});
        } else {
          setAddResumeModalOpen({ open: true, id: '' });
          handleClose();
        }
      })
      .catch(() => {});
  };
  const viewResume = () => {
    setLd(true);
    getAllListData(`${ResumesENDPOINT.download}/${resumeRowData?.id}`, true)
      .then((res) => {
        if (res) {
          const baseUrl = res;
          const winHtml = `<!DOCTYPE html>
          <html>
            <body>
              <iframe
                style="width: 100%; height: 100vh"
                src="data:application/pdf;base64,${baseUrl}"
              />
            </body>
          </html>
          `;
          const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));
          window.open(
            winUrl,
            'win',
            'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=500',
          );
          setLd(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLd(false);
      });
  };
  const viewSnapshot = () => {
    setLd(true);
    getAllListData(`${ResumesENDPOINT.resumeSnapshot}/${resumeRowData?.id}`, true)
      .then((res) => {
        if (res) {
          const baseUrl = res;
          const winHtml = `<!DOCTYPE html>
          <html>
            <body>
              <iframe
                style="width: 100%; height: 100vh"
                src="data:application/pdf;base64,${baseUrl}"
              />
            </body>
          </html>
          `;
          const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));
          window.open(
            winUrl,
            'win',
            'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=500',
          );
          setLd(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLd(false);
      });
  };

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList.find((e: any) => e.moduleName === 'Resume');
      setClientModule(settingData && settingData.screens ? settingData.screens : {});
    }
  }, [menuList]);

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Resumes
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>Resumes</Typography>
            <Typography
              component={'div'}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography component={'div'} className='expot-icon'>
                <Typography component={'span'}>
                  <ExcelIcon
                    url={`${ResumesENDPOINT.resumeListData}${
                      Object.values({ ...params }).length > 0 && '?'
                    }${[
                      `${orgdy ? `organizationId=${orgdy}` : null}`,
                      `${
                        debouncedParams.search
                          ? `search=${encodeURIComponent(debouncedParams.search)}`
                          : null
                      }`,
                      `${
                        debouncedParams.status ? `approvalStatus=${debouncedParams.status}` : null
                      }`,
                      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
                      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
                      `${
                        debouncedParams.interviewFrom
                          ? `interviewFrom=${debouncedParams.interviewFrom}`
                          : null
                      }`,
                      `${
                        debouncedParams.interviewTo
                          ? `interviewTo=${debouncedParams.interviewTo}`
                          : null
                      }`,
                      // `${
                      //   debouncedParams.employee ? `createdBy=${debouncedParams.employee}` : null
                      // }`,
                      `${debouncedParams.branch ? `branchId=${debouncedParams.branch}` : null}`,
                      `${
                        debouncedParams.minExperience
                          ? `expMin=${debouncedParams.minExperience}`
                          : null
                      }`,
                      `${
                        debouncedParams.maxExperience
                          ? `expMax=${debouncedParams.maxExperience}`
                          : null
                      }`,
                      `${
                        debouncedParams.currentStatus
                          ? `currentStatus=${debouncedParams.currentStatus}`
                          : null
                      }`,
                      'sortby=createdAt',
                      'order=desc',
                      'type=export',
                      'screen=resume',
                      // !debouncedParams.employee
                      //   ? roleId !== 6
                      //     ? `respectiveUserId=${Useruniqueid}`
                      //     : ''
                      //   : '',
                    ]
                      .filter((ea: any) => ea !== 'null')
                      .join('&')}`}
                    fileName={'resumes'}
                    setLoader={setLd}
                  />
                </Typography>
                <Typography component={'span'} style={{ width: '12.17px' }}></Typography>
              </Typography>

              <div style={{ width: '16px' }}></div>
              {R.findIndex(R.propEq('Add Resume', 'screenName'))(
                clientModule ? clientModule : [],
              ) !== -1 && (
                <Typography component={'div'} className='client-add-btn' onClick={CreateReasume}>
                  <img src={AddIcon} alt='plus' />
                  <Typography>Add Resume</Typography>
                </Typography>
              )}
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <Typography component={'div'} className='d-flex-cc flex-sb'>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography component={'p'} className={'d-flex'}>
                  <Typography
                    component={'div'}
                    className='d-flex-cc mr-16 flex-sb d-flex-nwrp filterfunnel-con'
                    onClick={dweropn}>
                    <img src={Funnelimg} alt='filter funnel icon' />
                    <span className='filterfunnel'>More Filters</span>
                  </Typography>
                  <SyncSvgIcon
                    onClick={listdataapi}
                    className={`sync-loader mt-8 ${SyncState ? 'sync-anim' : ''}`}
                  />
                </Typography>
              </Typography>
              <Typography component={'div'} className='mt-15' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table
                    sx={{ overflowX: 'auto', minWidth: 650 }}
                    stickyHeader
                    className='custom-table custom-table-client'
                    aria-label='sticky Header'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          width: 'calc(100% - 93px)',
                          position: 'relative',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Code
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('code', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('code', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Client
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('client', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('client', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Job Title
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('jobTitle', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('jobTitle', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Name
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('name', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('name', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Mobile
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('mobile', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('mobile', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Email ID
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('email', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('email', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Experience
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('exp', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('exp', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Location
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('location', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('location', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          Status
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('approvalStatus', 'desc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('approvalStatus', 'asc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
                          Created By
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdBy', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdBy', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
                          Type
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('resumeType', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('resumeType', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
                          Created On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', minWidth: '150px' }}>
                          Modified On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('updatedAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('updatedAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ minWidth: '100px' }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate.length > 0 ? (
                        commonstate.map((row) => {
                          const experienceYear: any = row.personal ? row.personal.expYears : '';
                          const experienceMonth: any = row.personal ? row.personal.expMonths : '';
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                // verticalAlign: 'baseline',
                                marginLeft: '50px',
                                marginRight: '43px',
                                width: 'calc(100% - 93px)',
                              }}>
                              <TableCell
                                sx={{
                                  minWidth: '120px',
                                }}
                                onClick={() => {
                                  gotoNavigate(
                                    `${AdminPrivateRoutes.RESUMES.RESUMESVIEW}?id=${row.id}`,
                                  );
                                }}>
                                <Typography
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    color: '#5F2EE5',
                                    fontSize: '14px !important',
                                  }}>
                                  CT-{row && row.code ? row.code : '-'}
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '100px',
                                }}>
                                {row && row?.client?.corporateName
                                  ? row?.client?.corporateName
                                  : '-'}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                <TableTooltip
                                  value={
                                    row && row?.position?.title ? row?.position?.title : '-'
                                  }></TableTooltip>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                <TableTooltip
                                  value={
                                    row && row.personal?.firstName
                                      ? `${row?.personal?.firstName} ${
                                          row?.personal?.lastName ? row?.personal?.lastName : ''
                                        }`
                                      : '-'
                                  }></TableTooltip>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                {row && row?.personal?.phoneNumber
                                  ? `${
                                      row?.personal?.countryCode
                                        ? `+${row?.personal?.countryCode}`
                                        : ''
                                    } ${row?.personal?.phoneNumber}`
                                  : '-'}
                              </TableCell>

                              <TableCell
                                sx={{
                                  // textTransform: 'capitalize',
                                  minWidth: '130px',
                                }}>
                                <TableTooltip
                                  value={
                                    row && row?.personal?.email ? row?.personal?.email : '-'
                                  }></TableTooltip>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                {row.approvalStatus != 'parsingFailed'
                                  ? experienceYear === 0 && 'Fresher'
                                  : '-'}
                                {experienceYear > 0 && `${experienceYear} Year `}
                                {experienceMonth > 0 && `${experienceMonth} Month`}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  minWidth: '150px',
                                }}>
                                <TableTooltip
                                  value={
                                    row && row?.personal?.presentLocation
                                      ? row?.personal?.presentLocation
                                      : '-'
                                  }></TableTooltip>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textTransform: 'capitalize',
                                  whiteSpace: 'nowrap',
                                }}>
                                {/* {getResumeStatus(
                                  row?.approvalStatus,
                                  row?.isCvSentToClient,
                                  row?.resumeStatus?.length
                                    ? row?.resumeStatus[0]?.status === 'ISA'
                                      ? row?.resumeStatus[1]?.type
                                      : row?.resumeStatus[0]?.type
                                    : '',
                                  row?.resumeStatus?.length
                                    ? row?.resumeStatus[0]?.status === 'ISA'
                                      ? row?.resumeStatus[1]?.status
                                      : row?.resumeStatus[0]?.status
                                    : '',
                                )} */}
                                <Typography
                                  component={'button'}
                                  className='interview-status-btn'
                                  sx={{
                                    backgroundColor:
                                      row?.approvalStatus === ResumeApprovalStatus.Draft
                                        ? '#ffb366'
                                        : row?.approvalStatus === ResumeApprovalStatus.InReview
                                          ? 'green'
                                          : row?.approvalStatus === 'inProcess'
                                            ? '#ffa64d'
                                            : row?.approvalStatus === 'readyForReview'
                                              ? '#33AAFF'
                                              : row?.approvalStatus === 'parsingFailed'
                                                ? 'red'
                                                : currentResumeStatus(row?.resumeStatus, true),
                                    width: '100% !important',
                                  }}>
                                  <Typography component={'span'}>
                                    {row?.approvalStatus === ResumeApprovalStatus.Draft
                                      ? 'Draft'
                                      : row?.approvalStatus === ResumeApprovalStatus.InReview
                                        ? 'CRM Validation Pending'
                                        : row?.approvalStatus === 'inProcess'
                                          ? 'In-Process'
                                          : row?.approvalStatus === 'readyForReview'
                                            ? 'Ready For Review'
                                            : row?.approvalStatus === 'parsingFailed'
                                              ? 'Parsing Failed'
                                              : currentResumeStatus(row?.resumeStatus)}
                                  </Typography>
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '120px' }}>
                                <TableTooltip
                                  value={
                                    row && row.user?.firstName
                                      ? `${row?.user?.firstName} ${
                                          row?.user?.lastName ? row?.user?.lastName : ''
                                        }`
                                      : '-'
                                  }
                                />
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '120px' }}>
                                <TableTooltip value={row?.position?.resumeType} />
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '120px' }}>
                                {row && row?.createdAt
                                  ? moment(row?.createdAt).format('DD-MM-YYYY')
                                  : '-'}
                              </TableCell>
                              <TableCell sx={{ textTransform: 'capitalize', minWidth: '120px' }}>
                                {row && row?.updatedAt
                                  ? moment(row?.updatedAt).format('DD-MM-YYYY')
                                  : '-'}
                              </TableCell>
                              <TableCell align='center'>
                                <img
                                  src={ThreedotIcon}
                                  alt='ThreedotIcon'
                                  style={{
                                    cursor: !['inProcess'].includes(row.approvalStatus)
                                      ? 'pointer'
                                      : 'not-allowed',
                                  }}
                                  onClick={(e: any) => {
                                    if (!['inProcess'].includes(row.approvalStatus)) {
                                      handleClick(e, row);
                                    }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={14} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Resumes added</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
        </Grid>
        {resumeUploadopen.open && (
          <AddResumeNew
            setResumeUploadOpen={setResumeUploadOpen}
            resumeUploadopen={resumeUploadopen}
            id={resumeRowData?.id}
            listdataapi={listdataapi}
            setResumeRowData={setResumeRowData}
            reupload={reupload}
            setReupload={setReupload}
          />
        )}
        {addResumeModalOpen.open && (
          <EditResume
            addResumeModalOpen={addResumeModalOpen}
            setAddResumeModalOpen={setAddResumeModalOpen}
            id={resumeRowData?.id}
            fileId={resumeRowData?.fileId}
            resumeStatus={resumeRowData}
            // isIgnore={isIgnore}
            setResumeRowData={setResumeRowData}
            listdataapi={listdataapi}
            view={false}
          />
        )}
        <ResumeFilterDrawer
          setParams={setParams}
          isOpen={dweropen}
          closeDrawer={dwerclose}
          callback={handleFilterPag}
          listdataapi={listdataapi}
          setcommonstate={setcommonstate}
          setpagetotal={setpagetotal}
          setLd={setLd}
          Defaultparam={Defaultparam}
        />
        {!_.isEmpty(duplication?.data) && openDuplicate && (
          <ResumeDuplication
            open={openDuplicate}
            data={duplication?.data}
            message={duplication?.message}
            duplicationStatus={duplication?.duplicationStatus}
            handleClose={handleDuplicateClose}
            setResumeUploadOpen={setResumeUploadOpen}
            setAddResumeModalOpen={setAddResumeModalOpen}
            // setIsIgnore={setIsIgnore}
            id={resumeRowData?.id}
          />
        )}
        <Popover
          className='profile-drop'
          id={paid}
          anchorEl={panchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{ width: '210px !important' }}>
          <Typography component={'div'} className='menu-box'>
            {!['inProcess', 'parsingFailed'].includes(resumeRowData.approvalStatus) ? (
              <Typography
                className='menu-item'
                onClick={() => {
                  resumeRowData.createdBy === Useruniqueid ? resumeCreateAccess() : '';
                }}
                style={{
                  padding: '0px 0px 0px 15px',
                  cursor:
                    resumeRowData.createdBy === Useruniqueid &&
                    resumeRowData.approvalStatus != 'inProcess'
                      ? 'pointer'
                      : 'not-allowed',
                }}>
                <img src={Edit} alt='Edit' />
                Edit
              </Typography>
            ) : (
              ''
            )}
            {['parsingFailed'].includes(resumeRowData.approvalStatus) ? (
              <Typography
                className='menu-item'
                onClick={() => {
                  if (resumeRowData.createdBy === Useruniqueid) {
                    setResumeUploadOpen({ open: true, id: resumeRowData?.id });
                    handleClose();
                    setReupload(true);
                  }
                }}
                style={{
                  padding: '0px 0px 0px 15px',
                  cursor: resumeRowData.createdBy === Useruniqueid ? 'pointer' : 'not-allowed',
                }}>
                <img src={Upload} alt='Upload' />
                Re-Upload
              </Typography>
            ) : (
              ''
            )}
            {!['inProcess', 'readyForReview', 'parsingFailed', 'draft'].includes(
              resumeRowData.approvalStatus,
            ) ? (
              <>
                <Typography
                  className='menu-item'
                  onClick={viewResume}
                  style={{ padding: '0px 0px 0px 15px' }}>
                  <img src={View} alt='Snapshot' />
                  View Resume
                </Typography>
                <Typography
                  className='menu-item'
                  onClick={downloadResume}
                  style={{ padding: '0px 0px 0px 15px' }}>
                  <img src={Download} alt='Download' />
                  Download Resume
                </Typography>
                <Typography
                  className='menu-item'
                  onClick={viewSnapshot}
                  style={{ padding: '0px 0px 0px 15px' }}>
                  <img src={Snapshot} alt='View' />
                  View Snapshot
                </Typography>
              </>
            ) : (
              ''
            )}
          </Typography>
        </Popover>
        <ConfirmDialog
          title='Confirm'
          floatingBtnShow={true}
          open={confirmOpen.show}
          close={() =>
            setConfirmOpen({
              show: false,
              id: '',
            })
          }
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? deletebtn(confirmOpen.id)
              : setConfirmOpen({
                  show: false,
                  id: '',
                })
          }
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default ResumeList;
