import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import { styled } from '@mui/material/styles';
import ClientCard from 'components/Clientcard/ClientCard';
import { useEffect, useState } from 'react';
import Modalclient from '../Modal';
import { useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Loader from 'components/loader';
import { getAllListData } from 'services/CommonServices';
import { ClientsENDPOINT } from 'utils/Constant';
import moment from 'moment';
import { ClientFilterDrawer } from '../ClientFilter/ClientFilterDrawer';
// import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import { useAppSelector } from 'store/hooks';
import { useDebounce } from 'hooks';
import Leftpaginaed from 'assets/png/leftpaginationarrow.svg';
import Rightpaginaed from 'assets/png/rightpaginationarrow.svg';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
import { State } from 'country-state-city';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const CLIENTAPPROVE = () => {
  const {
    userInfo: { organizationId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [clientData, setClientData] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const handleOpen = (clientId: string) => {
    setSelectedClient(clientId);
    setOpen(true);
  };

  const Defaultparam = {
    search: null,
    order: null,
    skip: 0,
    sortby: null,
    status: null,
    take: 8,
    from: null,
    to: null,
    crm: null,
    approvalStatus: 'inReview',
    industry: null,
    location: null,
  };
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  const handleClose = () => {
    setOpen(false);
    setSelectedClient(null);
  };
  const navigate = useNavigate();

  const getClients = () => {
    const searcharr = [
      `${organizationId ? `organizationId=${organizationId}` : null}`,
      `${
        debouncedParams.approvalStatus ? `approvalStatus=${debouncedParams.approvalStatus}` : null
      }`,
      `${debouncedParams.userId ? `userId=${debouncedParams.userId}` : null}`,
      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${debouncedParams.industry ? `industryId=${debouncedParams.industry}` : null}`,
      `${debouncedParams.location ? `location=${debouncedParams.location}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      'screenType=approve',
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoader(true);
    getAllListData(
      `${ClientsENDPOINT.Client}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((res: any) => {
        setTotal(res.total);
        setClientData(res.data);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };

  function handlePag(sk: string) {
    if (sk === 'next') {
      setParams((prev: any) => ({
        ...prev,
        skip: Number(prev.skip) + 8,
      }));
    }
    if (sk !== 'next') {
      setParams((prev: any) => ({
        ...prev,
        skip: Number(prev.skip) - 8,
      }));
    }
  }

  useEffect(() => {
    getClients();
  }, [debouncedParams]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href={AdminPrivateRoutes.CLIENTS.CLIENTVIEW}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Clients
            </Link>
            {/* <Link
              underline='hover'
              color='inherit'
              href=''
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.USERS.USERSLIST)}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Users
            </Link> */}
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Approve Client
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Approve Client</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Typography
              component={'div'}
              className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con mr-10'
              onClick={dweropn}>
              <img src={Funnelimg} alt='filter funnel icon' />
            </Typography>
            <Button
              className='go-to-clnt-list-btn'
              type='submit'
              form={'user-add'}
              onClick={() => navigate(AdminPrivateRoutes.CLIENTS.CLIENTVIEW)}
              sx={{ textTransform: 'none !important' }}>
              Go to Client List
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box apruv-whitebox'>
            <img
              src={Leftpaginaed}
              alt='left pagination arrow'
              className='leftpaginationscg'
              style={{
                cursor:
                  Number(params.skip) && total > Number(params.skip) - 6
                    ? 'pointer'
                    : 'not-allowed',
              }}
              onClick={() =>
                Number(params.skip) && total > Number(params.skip) - 6 ? handlePag('inext') : ''
              }
            />

            <img
              src={Rightpaginaed}
              alt='right pagination arrow'
              className='rightpaginationscg'
              style={{
                cursor:
                  Number(params.skip) >= 0 && total > Number(params.skip) + 6
                    ? 'pointer'
                    : 'not-allowed',
              }}
              onClick={() =>
                Number(params.skip) >= 0 && total > Number(params.skip) + 6 ? handlePag('next') : ''
              }
            />
            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              sx={{ display: 'flex', flexDirection: 'row', padding: '32px' }}>
              {
                Array.isArray(clientData) &&
                  clientData.length > 0 &&
                  clientData.map((client: any, i) => {
                    const clientState = State?.getStatesOfCountry(client?.country).filter(
                      (state: any) => state?.isoCode === client.state,
                    );
                    return (
                      <Grid item md={3} sm={3} xs={3} key={i}>
                        <Typography
                          component={'div'}
                          className='client-aprv-grid-container'
                          sx={{ marginBottom: '30px' }}>
                          <ClientCard
                            profileimg={client.logoId}
                            created={moment(client.createdAt).format('DD MMM YYYY')}
                            support={client.corporateName}
                            location={`${client.city}, ${clientState[0]?.name}`}
                            createdby={client.createdBy.firstName}
                            onClick={() => handleOpen(client.id)}
                          />
                        </Typography>
                      </Grid>
                    );
                  })
                // : (
                //   <div
                //     style={{
                //       display: 'flex',
                //       justifyContent: 'center',
                //       flexDirection: 'column',
                //       alignItems: 'center',
                //       marginBottom: '50px',
                //     }}>
                //     <NCAdedIcon />
                //     <Typography className='no-client'>No Client Found</Typography>
                //   </div>
                // )
              }
            </Grid>
          </Typography>
        </Typography>
        {selectedClient && (
          <Modalclient
            clientData={clientData.find((c: any) => c.id === selectedClient)}
            open={open}
            handleClose={handleClose}
            setLoading={setLoader}
            Approved={false}
          />
        )}
        <ClientFilterDrawer
          isOpen={dweropen}
          closeDrawer={dwerclose}
          setcommonstate={setClientData}
          setLoading={setLoader}
          listdataapi={getClients}
          setParams={setParams}
        />
      </Grid>
    </Grid>
  );
};

export default CLIENTAPPROVE;
