import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { businessInfoDetailSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Dropzone from 'react-dropzone';
import { fileTypewordPDF } from 'utils/helper';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import Fileupload from 'assets/png/upload-cloud.svg';
import Wordpng from 'assets/png/Word.png';
import Deletebucket from 'assets/png/Deletebucket.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addNewData, getAllListData, getSingleData } from 'services/CommonServices';
import { ClientsENDPOINT } from 'utils/Constant';
import Loader from 'components/loader';
import CloseIcon from 'assets/png/close.svg';
import PDFpng from 'assets/png/pngimg.png';

const businessInfoDetail = ({ setformview, formview, setFormfinish }: any) => {
  const [fileUpload, setfileupload] = useState<any>();
  const [proposalVersion, setProposalVersion] = useState<any>();
  const [Ld, setLd] = useState(false);
  const [searchParams]: any = useSearchParams();
  const hiddenFileInput: any = useRef(null);
  const [code, setCode] = useState<any>('');
  const navigate = useNavigate();
  const [removeId, setRemoveId] = useState<any>([]);
  const [resumeFileId, setResumeFileId] = useState<any>('');
  const [model, setModel] = useState<any>(false);
  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    // getValues,
    // trigger,
    // setError,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      sowFinalized: false,
      sowDetails: '',
      proposalSubmitted: false,
      projectName: '',
      projectDate: '',
      proposalApproved: false,
      proposalVersion: '',
      id: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(businessInfoDetailSchema),
  });
  const handleOpen = () => {
    setModel(true);
  };
  const handleClose = () => {
    setModel(false);
  };
  const onSubmit = () => {
    setLd(true);
    if (code !== '') {
      const obj = {
        version: code,
        status: true,
      };

      addNewData(obj, `${ClientsENDPOINT.ClientProposalVersion}`, {}, true)
        .then(() => {
          setLd(false);
          versionData();
          handleClose();
          setCode('');
        })
        .catch((e) => {
          setLd(false);
          console.log(e);
        });
    } else {
      toast.error('Field is required', {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
      });
    }
  };
  const onFileChange = (file: FileList) => {
    if (file[0] && file[0].size) {
      if (fileTypewordPDF.includes(file[0].type)) {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 10240) {
          toast.error('File is too big, Please select a file less than 10MB', alertOptions);
          return;
        }
      } else {
        toast.error('You can upload documents of types  DOCX, DOC and PDF only', alertOptions);
        return;
      }
    }
    setfileupload(file);
    hiddenFileInput.current.value = '';
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);

  const handledelete = () => {
    setRemoveId(resumeFileId ? [resumeFileId] : '');
    setfileupload('');
  };

  function onSubmitForm(data: any) {
    // if (!fileUpload && data.proposalApproved) {
    //   setLd(false);
    //   toast.error('File is required', {
    //     autoClose: 3000,
    //     closeOnClick: true,
    //     draggable: true,
    //     hideProgressBar: false,
    //     pauseOnHover: true,
    //     position: 'top-right',
    //     progress: undefined,
    //   });
    //   return;
    // }
    const clientBusinessInfo = {
      isSOwFinalized: data.sowFinalized,
      ...(data.sowFinalized
        ? {
            sowDetails: data.sowDetails ? data.sowDetails : '',
            ...(data.proposalSubmitted
              ? {
                  isProposalSubmitted: data.proposalSubmitted
                    ? Boolean(data.proposalSubmitted)
                    : '',
                  projectName: data.projectName ? data.projectName : '',
                  proposalDate: data.projectDate ? data.projectDate : '',
                  proposalVersionId: data.proposalVersion ? data.proposalVersion : '',
                  isProposalApproved: Boolean(data.proposalApproved),
                }
              : {
                  isProposalSubmitted: data.proposalSubmitted ?? Boolean(data.proposalSubmitted),
                }),
          }
        : {}),
      ...(data.id ? { id: data.id } : {}),
    };

    const formdata: any = new FormData();
    formdata.append('clientTab', 'businessInfo');
    formdata.append('clientId', searchParams.get('clientid') || formview.clientId);
    formdata.append('clientBusinessInfo', JSON.stringify(clientBusinessInfo));
    if (fileUpload && fileUpload.length) {
      formdata.append('file', fileUpload ? fileUpload[0] : '');
    }
    if (removeId.length) {
      formdata.append('removeBusinessInfoFileIds', JSON.stringify(removeId));
    }
    setLd(true);
    addNewData(
      formdata,
      `${ClientsENDPOINT.Client}`,
      {
        'Content-Type': 'multipart/form-data',
      },
      true,
    )
      .then((res: any) => {
        setformview((prev: any) => ({
          ...prev,
          crm: false,
          spoc: false,
          clientdetail: false,
          termsCondition: true,
          businessDetail: false,
          clientId: res && res?.id,
        }));
        setFormfinish((prev: any) => ({
          ...prev,
          crm: true,
          spoc: true,
          clientdetail: true,
          termsCondition: false,
          businessDetail: true,
        }));
        setLd(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(e);
          navigate('/dashboard');
        }
        setLd(false);
        console.log(e);
      });
  }
  useEffect(() => {
    if (watch('sowFinalized') === 'false') {
      setValue('proposalSubmitted', false);
      setValue('sowDetails', '');
      setValue('projectName', '');
      setValue('projectDate', '');
      setValue('proposalVersion', '');
      setValue('proposalApproved', false);
    }
  }, [watch('sowFinalized')]);

  useEffect(() => {
    if (watch('proposalSubmitted') === 'false') {
      setValue('projectName', '');
      setValue('projectDate', '');
      setValue('proposalVersion', '');
      setValue('proposalApproved', false);
    }
  }, [watch('proposalSubmitted')]);

  const getInitialStatus = async () => {
    if (searchParams.get('clientid') || formview.clientId) {
      setLd(true);
      await getSingleData(searchParams.get('clientid') || formview.clientId, ClientsENDPOINT.Client)
        .then((resp: any) => {
          const data =
            resp.data &&
            resp.data[0]?.clientsBusinessInfo.length &&
            resp.data[0]?.clientsBusinessInfo[0];
          setValue('sowFinalized', String(data.isSOwFinalized));
          setValue('proposalSubmitted', String(data.isProposalSubmitted));
          setValue('sowDetails', data.sowDetails);
          setValue('projectName', data.projectName);
          setValue('projectDate', data.proposalDate);
          setValue('proposalVersion', data.proposalVersionId);
          setValue('proposalApproved', String(data.isProposalApproved));
          setfileupload(data?.file ? [data?.file] : '');
          setResumeFileId(data?.attachmentId);
          setValue('id', data.id);
          setFormfinish((prev: any) => ({
            ...prev,
            crm: resp?.data[0]?.clientsCrm.length ? true : false,
            spoc: resp?.data[0]?.clientsSpoc.length ? true : false,
            clientdetail: resp?.data[0]?.corporateName ? true : false,
            termsCondition: resp?.data[0]?.clientsAgreement.length ? true : false,
            businessDetail: resp?.data[0]?.clientsBusinessInfo.length ? true : false,
          }));
          setLd(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(e);
            navigate('/dashboard');
          }
          setLd(false);
          console.log(e);
        });
    }
  };
  useEffect(() => {
    getInitialStatus();
  }, []);

  const versionData = () => {
    setLd(true);
    getAllListData(`${ClientsENDPOINT.ClientProposalVersion}?status&order&sortby&skip&take`, true)
      .then((res: any) => {
        if (res && res.data && res.data.length) {
          const options: any = [];
          res.data.map((e: any) => {
            options.push({
              label: e.version ? `Version ${Math.round(e.version)}` : '',
              value: e.id,
            });
          });
          options.push({
            label: '+ Add Version',
            value: 'AddPercentage',
            isDisabled: true,
            isFixed: true,
            isSelectable: false,
            custmdwn: true,
          });
          setProposalVersion(options);
        }
        setLd(!true);
      })
      .catch(() => {
        // console.log(e);
        setLd(!true);
      });
  };
  useEffect(() => {
    versionData();
  }, []);
  return (
    <Grid item md={12} sm={8} xs={8}>
      {Ld && <Loader />}
      <Typography component={'div'} className='form-box'>
        <Typography component={'div'} className='client-form-photo-area'>
          <Typography component={'p'} className='side-client-text'>
            Business Info Detail
          </Typography>
        </Typography>
        <form className='private-form' id={'user-add'} onSubmit={handleSubmit(onSubmitForm)}>
          <Grid item md={4} sm={12} xs={12}>
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}>
                SOW Finalized <span>*</span>
              </Typography>
              <Controller
                control={control}
                name='sowFinalized'
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <RadioGroup
                      className={`custom-radio ${
                        errors.sowFinalized && errors.sowFinalized.message ? 'custom-radio' : ''
                      }`}
                      row
                      aria-labelledby=''
                      name={name}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}>
                      <FormControlLabel value={true} control={<Radio />} label='Yes' />
                      <FormControlLabel value={false} control={<Radio />} label='No' />
                    </RadioGroup>
                  );
                }}
              />
              {errors && errors.sowFinalized && errors?.sowFinalized?.message && (
                <div className='Custom-error '>{String(errors?.sowFinalized?.message)}</div>
              )}
            </Typography>
          </Grid>
          {watch('sowFinalized') === 'true' && (
            <>
              <Grid item md={6} sm={12} xs={12} className='mt-25'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    SOW Details <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='sowDetails'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          className='custom-input'
                          placeHolder='SOW Details'
                          error={errors.sowDetails?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className='mt-25'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Proposal Submitted<span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name='proposalSubmitted'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <RadioGroup
                          className={`custom-radio ${
                            errors.proposalSubmitted && errors.proposalSubmitted.message
                              ? 'custom-radio'
                              : ''
                          }`}
                          row
                          aria-labelledby=''
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}>
                          <FormControlLabel value={true} control={<Radio />} label='Yes' />
                          <FormControlLabel value={false} control={<Radio />} label='No' />
                        </RadioGroup>
                      );
                    }}
                  />
                  {errors && errors.proposalSubmitted && errors?.proposalSubmitted?.message && (
                    <div className='Custom-error '>
                      {String(errors?.proposalSubmitted?.message)}
                    </div>
                  )}
                </Typography>
              </Grid>
            </>
          )}
          {watch('proposalSubmitted') === 'true' && (
            <Grid container columnSpacing={3}>
              <Grid item md={6} sm={12} xs={12} className='mt-25'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Project Name <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name='projectName'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          value={value}
                          name={name}
                          className='custom-input'
                          placeHolder=' Project Name'
                          error={errors.projectName?.message}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={6} sm={12} xs={12} className='mt-25'>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Proposal Date <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name={'projectDate'}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <>
                          <TextField
                            value={value}
                            name={name}
                            id='date'
                            type='date'
                            placeholder={'Proposal Date'}
                            className={`clientfilter-time-from-to ${
                              errors.projectDate && errors.projectDate.message
                                ? 'clientfilter-time-from-to-error'
                                : ''
                            }`}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                          {errors && errors.projectDate && errors?.projectDate?.message && (
                            <div className='Custom-error '>
                              {String(errors?.projectDate?.message)}
                            </div>
                          )}
                        </>
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Proposal Version <span>*</span>
                  </Typography>
                  <Controller
                    control={control}
                    name='proposalVersion'
                    render={({ field: { onChange, value, name } }) => {
                      const dat =
                        proposalVersion && proposalVersion.length
                          ? proposalVersion
                          : [
                              {
                                label: '+ Add Version',
                                value: 'AddPercentage',
                                isDisabled: true,
                                isFixed: true,
                                isSelectable: false,
                                custmdwn: true,
                              },
                            ];
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={dat}
                          name={name}
                          custmfn={() => handleOpen()}
                          value={value ? value : null}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Select'
                          error={errors.proposalVersion?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Proposal Approved <span>*</span>
                  </Typography>
                  <div className='mt-15'>
                    <Controller
                      control={control}
                      name='proposalApproved'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <RadioGroup
                            className={`custom-radio ${
                              errors.proposalApproved && errors.proposalApproved.message
                                ? 'custom-radio'
                                : ''
                            }`}
                            row
                            aria-labelledby=''
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}>
                            <FormControlLabel value={true} control={<Radio />} label='Yes' />
                            <FormControlLabel value={false} control={<Radio />} label='No' />
                          </RadioGroup>
                        );
                      }}
                    />
                  </div>
                  {errors && errors.proposalApproved && errors?.proposalApproved?.message && (
                    <div className='Custom-error '>{String(errors?.proposalApproved?.message)}</div>
                  )}
                </Typography>
              </Grid>
              {watch('proposalApproved') === 'true' && (
                <Grid item md={12} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Attach Proposal</Typography>
                    <Dropzone onDrop={onDrop}>
                      {({ getRootProps, getInputProps }) => {
                        return (
                          <div
                            {...getRootProps({
                              className: 'dropzone container container-position pointer',
                              onClick: () => {
                                hiddenFileInput.current?.click();
                              },
                            })}>
                            <div>
                              <input {...getInputProps()} />
                              <p className='img-container'>
                                <img src={Fileupload} alt='file upload' />
                              </p>
                              <div className='fil-uplod-desc'>
                                <p className='first-heading'>
                                  <span className='frst-span' role='button'>
                                    Click to upload
                                  </span>{' '}
                                  <span className='drago'>or drag and drop</span>
                                </p>
                                <p className='docx'>DOC, DOCX, PDF (up to 10MB)</p>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </Typography>
                  <div>
                    {fileUpload && fileUpload.length > 0 ? (
                      <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                        <Typography component={'div'} className='custom-field file-view-box'>
                          <Typography
                            component={'p'}
                            className='first-children first-green-children'>
                            <div className='image-container'>
                              <span className='image image-success'>
                                {fileUpload[0]?.type === 'application/pdf' ||
                                fileUpload[0]?.mimetype === 'application/pdf' ? (
                                  <img
                                    src={PDFpng}
                                    alt='PDFpng'
                                    style={{ height: '20px', width: '20px' }}
                                  />
                                ) : (
                                  <img src={Wordpng} alt='Wordpng' />
                                )}
                              </span>
                              <span className={'texted texted-success'}>
                                <TableTooltip
                                  value={
                                    fileUpload && fileUpload[0]
                                      ? fileUpload[0]?.name || fileUpload[0]?.fileName
                                      : fileUpload
                                        ? fileUpload.fileName
                                        : ''
                                  }
                                  positionfile={true}></TableTooltip>
                              </span>
                            </div>
                            <div
                              className='Crossicon Crossicon-deletebucket'
                              onClick={handledelete}>
                              <img src={Deletebucket} alt='Crossicon' />
                            </div>
                          </Typography>
                        </Typography>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </form>
      </Typography>
      <Modal
        sx={{ zIndex: 11000 }}
        open={model}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box className='Degree-modal' sx={{ width: '40%' }}>
          <Typography className='text-right'>
            <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Typography>
          <div style={{ padding: '15px 70px 30px 70px' }}>
            <Typography className='l-title'>Add New Version!</Typography>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <form className='private-form'>
                    <div style={{ height: '10px' }}></div>
                    <Grid container>
                      <Grid item md={12} sm={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} sm={12} xs={12}>
                            <Grid container>
                              <Grid item md={12} sm={12} xs={12}>
                                <Typography component={'div'} className='custom-field'>
                                  <Typography component={'p'}>
                                    New Version
                                    <span>*</span>
                                  </Typography>

                                  <CustomInput
                                    className='custom-input'
                                    onChange={(e) => {
                                      setCode(e.target.value);
                                      console.log(e);
                                    }}
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </Grid>
            </Grid>
            <Typography className='d-flex-ja mt-15'>
              <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                Cancel
              </Button>
              <Button className='l-save-btn' type='button' onClick={() => onSubmit()}>
                save
              </Button>
            </Typography>
          </div>
        </Box>
      </Modal>
    </Grid>
  );
};
export default businessInfoDetail;
