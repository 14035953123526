/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  // Pagination,
  // PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import CloseIcon1 from 'assets/png/close.svg';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EditIcon from 'assets/png/edit.svg';
import DeleteIcon from 'assets/png/delete.svg';
import AddIcon from 'assets/png/addplus.svg';
import SearchIcon from 'assets/png/nsearch.svg';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

import { deleteData, getAllListData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { useDebounce } from 'hooks';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
// import { toast } from 'react-toastify';
import Paginate from 'components/Pagination/Pagination';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import _ from 'lodash';
import { toast } from 'react-toastify';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { AccessDenied } from 'utils/helper';
import ImportFunctionality from '../Common/import';
// import { alertOptions } from 'utils/Config';

interface Statetype {
  search?: string | null;
  order?: string | null;
  skip?: number | null;
  sortby?: string | null;
  status?: string | null;
  take?: number | null;
  address?: string | null;
  pincode?: string | null;
  type?: string | null;
}

const Defaultparam = {
  search: null,
  order: 'asc',
  skip: 0,
  sortby: 'qualification',
  status: null,
  take: 50,
  address: null,
  pincode: null,
  type: null,
};

const Qualification = () => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [importUpload, setImportUpload] = useState<any>(false);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [loader, setLoader] = useState<any>(false);
  const [qualificationGetData, setQualificationGetData] = useState<any>([]);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const [specialModalOpen, setSpecialModalOpen] = React.useState<any>({
    open: false,
    data: [],
  });
  const [params, setParams] = useState<Statetype>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);

  const handleModalClose = () => {
    setSpecialModalOpen((prev: any) => ({
      ...prev,
      open: false,
      data: [],
    }));
  };
  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    getAllListData(
      `${SETTING_ENDPOINT.Qualificationdetails}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res) => {
        setpagetotal(Number(res.total));
        setQualificationGetData(res && res.data);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };
  const deleteQualification = (id: any) => {
    setLoader(true);

    deleteData(id, `${SETTING_ENDPOINT.Qualification}`, true)
      .then(() => {
        getList();
        setConfirmOpen((pre: any) => ({
          ...pre,
          show: false,
        }));
        // toast.success('Qualification deleted successfully', alertOptions);
        setLoader(false);
      })
      .catch(() => {
        // console.log(err);
        setLoader(false);
      });
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };
  useEffect(() => {
    getList();
  }, [debouncedParams]);

  console.log(specialModalOpen);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Qualification
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Qualification</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <div className='expot-icon'>
              <span>
                <ExcelIcon
                  url={'qualification/list?type=export&order=asc&sortby=qualification'}
                  fileName={'Qualification'}
                  setLoader={setLoader}
                />
              </span>
            </div>{' '}
            <Button
              className='add-btn'
              onClick={() => setImportUpload(true)}
              style={{ marginRight: '20px' }}>
              Import
            </Button>
            <Button className='add-btn' onClick={() => navigate('/settings/qualification/add')}>
              <img src={AddIcon} alt='plus' /> Add Qualification
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <TextField
              className='custom-search'
              placeholder='Search'
              id='outlined-start-adornment'
              value={params.search}
              sx={{ width: '320px' }}
              onChange={(e: any) =>
                setParams((prev: any) => ({
                  ...prev,
                  search: e.target.value,
                  skip: 0,
                  take: 50,
                }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={SearchIcon} alt='search' />
                  </InputAdornment>
                ),
                endAdornment: params.search ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={() => {
                        setParams((prev: any) => ({ ...prev, ...Defaultparam, search: '' }));
                      }}>
                      <CloseIcon style={{ height: '20px', width: '20px' }} />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Typography component={'div'} className='mt-32'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Qualification{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('qualification', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('qualification', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Degree{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('degree', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('degree', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Specialization{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('specialization', 'asc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('specialization', 'desc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>
                        Status{' '}
                        <span className='sorting'>
                          <img
                            src={UP}
                            alt='asc'
                            className='up'
                            onClick={() => sortablecliks('status', 'desc')}
                          />
                          <img
                            src={DOWN}
                            alt='desc'
                            className='down'
                            onClick={() => sortablecliks('status', 'asc')}
                          />
                        </span>
                      </TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {qualificationGetData && qualificationGetData.length ? (
                      qualificationGetData.map((row: any, index: any) => {
                        const special =
                          row.specializations && row.specializations.length > 0
                            ? row.specializations.map(
                                (re: any, i: number) =>
                                  `${String(re.specialization)}${
                                    Number(row.specializations.length) - 1 != i ? ', ' : ''
                                  }`,
                              )
                            : '-';
                        return (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ textTransform: 'capitalize' }}>
                              {row.qualification &&
                              row.qualification.name &&
                              row.qualification.name.length > 0
                                ? row.qualification.name
                                : '-'}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize' }}>
                              {row && row.degree ? row.degree : '-'}
                            </TableCell>
                            <TableCell
                              sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
                              onClick={() => {
                                setSpecialModalOpen((prev: any) => ({
                                  ...prev,
                                  open: true,
                                  data: row?.specializations,
                                }));
                              }}>
                              {special.slice(0, 1).concat('...') ?? '-'}
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={row && row.status ? 'active-badge' : 'inactive-badge'}>
                                {row.status === true ? 'Active' : 'Inactive'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <img
                                src={EditIcon}
                                alt='Edit'
                                className='pointer'
                                onClick={() =>
                                  navigate(
                                    `${AdminPrivateRoutes.SETTINGS.QUALIFICATION.QUALIFICATIONEDIT}/${row.id}`,
                                  )
                                }
                              />
                              <img
                                src={DeleteIcon}
                                alt='Delete'
                                className='ml-24 pointer'
                                onClick={() =>
                                  setConfirmOpen((pre: any) => ({
                                    ...pre,
                                    show: true,
                                    id: row.id,
                                  }))
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={5} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
            <Grid container className='mt-24'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='d-flex-a flex-end pagination'>
                  <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
      <Modal open={specialModalOpen.open} onClose={handleModalClose}>
        <Box className='email-modal' sx={{ width: '500px !important', minHeight: '45%' }}>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='l-title' sx={{ display: 'flex' }}>
              {'Specialization List'}
            </Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon1}
                alt='close'
                onClick={handleModalClose}
                style={{ cursor: 'pointer' }}
              />
            </Typography>
          </Typography>
          <Grid item md={12} sm={12} xs={12} sx={{ marginTop: '15px' }}>
            <TableContainer component={Paper}>
              <Table className='custom-table' aria-label='simple table'>
                <TableBody>
                  <>
                    {specialModalOpen ? (
                      specialModalOpen?.data.map((row: any) => (
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          key={row.id}>
                          <TableCell align='center'>{row.specialization}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <tr className={'text-center'}>
                        <td colSpan={1} align={'center'}>
                          No Record(s) Found.
                        </td>
                      </tr>
                    )}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      </Modal>
      <ImportFunctionality
        importUpload={importUpload}
        setImportUpload={setImportUpload}
        name={'qualification'}
        header={['Qualification', 'Degree', 'Specialization', 'Status']}
        getList={getList}
      />
      <ConfirmDialog
        title='Confirm'
        floatingBtnShow={true}
        open={confirmOpen.show}
        close={() =>
          setConfirmOpen({
            show: false,
          })
        }
        visibleClose={true}
        onConfirm={(act: any) =>
          act === 'yes'
            ? deleteQualification(confirmOpen.id)
            : setConfirmOpen({
                show: false,
              })
        }
        autoCloseDisable={true}>
        {'Are you sure do you want to delete?'}
      </ConfirmDialog>
    </Grid>
  );
};

export default Qualification;
