import React, { useEffect, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  TableContainer,
  TableHead,
  Typography,
  Modal,
  Box,
} from '@mui/material';
import zoomIn from 'assets/png/zoomIn.svg';
import zoomOut from 'assets/png/zoom-out.svg';
import Print from 'assets/png/print-blue.svg';
import CloseIcon from 'assets/png/close.svg';

// import { AdminPrivateRoutes } from 'routes/routeConstans';
import ReportIcon from 'assets/iconcomponents/reporticon';
// import CustomBarChart from '../CommonGraph/sideBar';
import Fulllogo from 'assets/png/cthiring_new_logo.png';
import Chart from 'assets/png/chart .svg';
import { useDebounce } from 'hooks';
import { getAllListData } from 'services/CommonServices';
import { REPORT_ENDPOINT } from 'utils/Constant';
import { ReportFilter } from '../Common/filter';
import CustomBarChart from '../Common/sideBar';
import moment from 'moment';
import CvStatusBar from '../Common/CvStatusBar';
import LineGraph from '../Common/lineGraph';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import Loader from 'components/loader';
import PngIcon from 'assets/iconcomponents/pngIcon';
import PdfExport from 'assets/iconcomponents/pdfExport';
import { getRecruitmentStageValue } from 'utils/helper';

const Defaultparam = {
  fromDate: null,
  toDate: null,
  userId: null,
  branchId: null,
  type: null,
  report: null,
  order: null,
  skip: 0,
  sortby: null,
  status: null,
  take: 10,
  organizationId: null,
  createdBy: null,
};
export const CvStatus = () => {
  const [loader, setLoader] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [previewOpen, setPreviewOpen] = useState<any>(false);
  // const zoomRef: any = useRef<any>(null);
  // const [zoomLevel, setZoomLevel] = useState(100); // Percentage zoom level
  const [scale, setScale] = useState(1); // Initial zoom level
  const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));

  const [tableData, setTableData] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [roleData, setRoleData] = useState<any>();
  const [employeeData, setEmployeeData] = useState<any>();
  const [branchData, setBranchData] = useState<any>();
  const [employeeName, setEmployeeName] = useState<any>([]);

  const handleClose = () => {
    setPreviewOpen(false);
  };
  const PrintScreen = () => {
    window.print();
  };

  const listdataapi = () => {
    const searcharr = [
      `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
      `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
      `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
      `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
      `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
      `${debouncedParams.report ? `report=${debouncedParams.report}` : null}`,
      `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoader(true);
    getAllListData(
      `${REPORT_ENDPOINT.cvStatus}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (params.type === 'ctcWise') {
          setTableData(val.data);
          const transformedData = val?.data?.graphData.map((item: any) => ({
            month: item.range,
            cvSentShortlistedPercentage: parseFloat(item.cvSentShortlistedPercentage),
            cvSentInterviewedPercentage: parseFloat(item.cvSentInterviewedPercentage),
            openingWorkedToBilledPercentage: parseFloat(item.openingWorkedToBilledPercentage),
          }));
          setGraphData(transformedData);
        } else {
          setTableData(val.data);
          const transformedData = val?.data?.graphData.map((item: any) => ({
            name: item.corporateName ? item.corporateName : item.month ?? item.month,
            cvSentShortlistedPercentage: parseFloat(item.cvSentShortlistedPercentage),
            cvSentInterviewedPercentage: parseFloat(item.cvSentInterviewedPercentage),
            openingWorkedToBilledPercentage: parseFloat(item.openingWorkedToBilledPercentage),
          }));
          setGraphData(transformedData);
        }
        // setTableData(val.data);
        setLoader(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoader(false);
      });
  };
  const branch = () => {
    getAllListData(`${REPORT_ENDPOINT.branch}`, true)
      .then((res: any) => {
        setLoader(false);
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setBranchData(options);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  useEffect(() => {
    listdataapi();
    branch();
  }, [debouncedParams]);
  useEffect(() => {
    if (roleData) {
      setLoader(true);

      getAllListData(`${REPORT_ENDPOINT.employee}?reportRoles=${roleData}`, true)
        .then((res: any) => {
          const options: any = [];
          res.data.map((e: any) => {
            options.push({ label: e.name, value: e.id, fullName: e.nameWithRole });
          });
          if (roleData != 'recruiter') {
            options.push(
              roleData != 'recruiter' && res?.data?.length > 0
                ? { label: 'All', value: 'all', fullName: 'All' }
                : {},
            );
          }
          setEmployeeData(options);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  }, [roleData]);
  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography color='text.primary'>
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Reports
            </Typography>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' /> CV Status
            </Typography>
          </Breadcrumbs>
        </Typography>
        <ReportFilter
          setParams={setParams}
          setRoleData={setRoleData}
          branchData={branchData}
          employeeData={employeeData}
          setEmployeeName={setEmployeeName}
          setPreviewOpen={setPreviewOpen}
          params={params}
          tableData={setTableData}
          graphData={setGraphData}
        />
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            {!tableData || !graphData ? (
              <ReportIcon />
            ) : (
              <>
                {params.type === 'ctcWise' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              CTC Wise CV Status
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <PngIcon
                              url={`reports/cv-status/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=image',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              setLoader={setLoader}
                            />
                            <PdfExport
                              url={`reports/cv-status/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=pdf',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <CvStatusBar data={graphData} setParams={setParams} type={'CvStatus'} />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {params.type === 'clientWise' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              {'Client Wise CV Status'}
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <PngIcon
                              url={`reports/cv-status/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=image',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              setLoader={setLoader}
                            />
                            <PdfExport
                              url={`reports/cv-status/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=pdf',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <CustomBarChart data={graphData} type={'CvStatus'} setParams={setParams} />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {params.type === 'monthWise' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              {'Client Wise Monthly CV Status'}
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <PngIcon
                              url={`reports/cv-status/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=image',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              setLoader={setLoader}
                            />
                            <PdfExport
                              url={`reports/cv-status/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=pdf',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <LineGraph
                        data={graphData}
                        lineKeys={[
                          {
                            key: 'cvSentShortlistedPercentage',
                            color: '#82ca9d',
                            label: 'CV Sent Shortlisted %',
                          },
                          {
                            key: 'cvSentInterviewedPercentage',
                            color: '#8884d8',
                            label: 'CV Sent Interviewed %',
                          },
                          {
                            key: 'openingWorkedToBilledPercentage',
                            color: '#673147',
                            label: 'Opening Worked to Billed %',
                          },
                        ]}
                      />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {(params.report === 'table' || params.report === 'all') && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className='d-flex flex-sb'
                        sx={{ marginTop: '25px' }}>
                        <Typography
                          component={'div'}
                          sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography component={'span'} className='report-title'>
                            {params.type === 'ctcWise'
                              ? 'CTC Wise CV Status'
                              : params.type === 'clientWise'
                                ? 'Client Wise CV Status'
                                : params.type === 'monthWise' && 'Client Wise Monthly CV Status'}
                          </Typography>
                          <Typography
                            component={'span'}
                            className='report-title-name d-flex-cc'
                            sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                            {employeeName}
                          </Typography>
                        </Typography>

                        <Typography
                          component={'span'}
                          className='report-title-name'
                          sx={{ display: 'flex' }}>
                          Timeline :{' '}
                          <Typography
                            component={'span'}
                            className='report-title'
                            sx={{ marginRight: '30px' }}>
                            {moment(params.fromDate).format('DD/MM/YYYY')} -
                            {moment(params.toDate).format('DD/MM/YYYY')}
                          </Typography>
                          <ExcelIcon
                            url={`reports/cv-status/export${
                              Object.values({ ...params }).length > 0 && '?'
                            }${[
                              `${
                                debouncedParams.fromDate
                                  ? `fromDate=${debouncedParams.fromDate}`
                                  : null
                              }`,
                              `${
                                debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                              }`,
                              `${
                                debouncedParams.branchId
                                  ? `branchId=${debouncedParams.branchId}`
                                  : null
                              }`,
                              `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                              `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                              `${
                                debouncedParams.employee
                                  ? `userId=${debouncedParams.employee}`
                                  : null
                              }`,
                              'exportType=csv',
                              'report=table',
                            ]
                              .filter((ea: any) => ea !== 'null')
                              .join('&')}`}
                            fileName={'CV Status'}
                            setLoader={setLoader}
                          />
                          {/* <img
                            src={Pdf}
                            alt='Pdf'
                            style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                          /> */}
                        </Typography>
                      </Typography>
                    </Grid>
                    {params.type === 'ctcWise' ? (
                      <Typography component={'div'} className='mt-32'>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            className='custom-table'
                            aria-label='simple table'>
                            <TableHead>
                              <TableRow>
                                <TableCell>Stage</TableCell>
                                {tableData.tableHeaders.slice(1).map((header: string) => (
                                  <TableCell key={header} sx={{ textTransform: 'capitalize' }}>
                                    {header}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(tableData.tableData[0])
                                .filter((key) => key !== 'range')
                                .map((stage: string) => (
                                  <TableRow key={stage}>
                                    <TableCell>{getRecruitmentStageValue(stage)}</TableCell>
                                    {tableData.tableHeaders.slice(1).map((header: string) => {
                                      const rowForHeader = tableData.tableData.find(
                                        (row: any) => row.range === header,
                                      );
                                      return (
                                        <TableCell key={`${stage}-${header}`}>
                                          {rowForHeader ? rowForHeader[stage] ?? '-' : '-'}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='mt-32'>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            className='custom-table'
                            aria-label='simple table'>
                            <TableHead>
                              <TableRow>
                                <TableCell>Stage</TableCell>
                                {tableData.tableHeaders.slice(1).map((header: string) => (
                                  <TableCell key={header} sx={{ textTransform: 'capitalize' }}>
                                    {header}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            {params.type === 'clientWise' ? (
                              <TableBody>
                                {Object.keys(tableData.tableData[0])
                                  .filter((key) => key !== 'id' && key !== 'corporateName')
                                  .map((stage: string) => (
                                    <TableRow key={stage}>
                                      <TableCell>{getRecruitmentStageValue(stage)}</TableCell>
                                      {tableData.tableHeaders.slice(1).map((header: string) => {
                                        const rowForHeader = tableData.tableData.find(
                                          (row: any) => row.corporateName === header,
                                        );
                                        return (
                                          <TableCell key={`${stage}-${header}`}>
                                            {rowForHeader ? rowForHeader[stage] ?? '-' : '-'}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            ) : (
                              <TableBody>
                                {Object.keys(tableData.tableData[0])
                                  .filter((key) => key !== 'month')
                                  .map((stage: string) => (
                                    <TableRow key={stage}>
                                      <TableCell>{getRecruitmentStageValue(stage)}</TableCell>
                                      {tableData.tableHeaders.slice(1).map((header: string) => {
                                        const rowForHeader = tableData.tableData.find(
                                          (row: any) => row.month === header,
                                        );
                                        return (
                                          <TableCell key={`${stage}-${header}`}>
                                            {rowForHeader ? rowForHeader[stage] ?? '-' : '-'}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer>
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}
          </Typography>
        </Typography>
      </Grid>
      <Modal
        sx={{ zIndex: 1300 }}
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          className='email-modal'
          sx={{
            width: '100% !important',
            minHeight: '100vh !important',
            padding: '0px !important',
          }}>
          <Typography
            className='d-flex-a flex-sb'
            sx={{ backgroundColor: '#F2F4F7', height: '53px', padding: '20px' }}>
            <Typography component={'span'} className='report-title'>
              {'CV Status'}
            </Typography>
            <Typography component={'div'} className='d-flex-cc'>
              <img
                src={zoomIn}
                alt='zoomIn'
                style={{ width: '35px', height: '35px', marginRight: '25px', cursor: 'pointer' }}
                onClick={handleZoomIn}
              />
              <div
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#f0f0f0',
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}>
                {Math.round(scale * 100)}%
              </div>
              <img
                src={zoomOut}
                alt='zoomIn'
                style={{ width: '35px', height: '35px', marginLeft: '25px', cursor: 'pointer' }}
                onClick={handleZoomOut}
              />
            </Typography>
            <Typography component={'div'} className='d-flex'>
              <Typography component={'div'} className='d-flex'>
                <PngIcon
                  url={`reports/cv-status/export${Object.values({ ...params }).length > 0 && '?'}${[
                    `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
                    `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
                    `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
                    `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                    `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                    `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
                    'exportType=image',
                    'report=graph',
                    `names=${employeeName.map((item: any) => item.replace(/^\(|\)$/g, ''))}`,
                  ]
                    .filter((ea: any) => ea !== 'null')
                    .join('&')}`}
                  setLoader={setLoader}
                />
                <PdfExport
                  url={`reports/cv-status/export${Object.values({ ...params }).length > 0 && '?'}${[
                    `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
                    `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
                    `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
                    `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                    `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                    `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
                    'exportType=pdf',
                    'report=graph',
                    `names=${employeeName.map((item: any) => item.replace(/^\(|\)$/g, ''))}`,
                  ]
                    .filter((ea: any) => ea !== 'null')
                    .join('&')}`}
                  setLoader={setLoader}
                />
                <img
                  src={Print}
                  alt='Print'
                  style={{ width: '35px', height: '35px', marginRight: '25px', marginLeft: '20px' }}
                  onClick={PrintScreen}
                />
              </Typography>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Typography>
          </Typography>
          <Typography
            component={'div'}
            className='main-content'
            sx={{
              padding: '0px',
              overflow: 'auto',
              position: 'relative',
            }}>
            <Typography component={'div'} className='white-box'>
              {!tableData || !graphData ? (
                <ReportIcon />
              ) : (
                <>
                  {params.type === 'ctcWise' &&
                    (params.report === 'graph' || params.report === 'all') && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',
                          overflow: 'auto',
                          margin: '0 auto',
                          transform: `scale(${scale})`,
                          transition: 'transform 0.3s ease',
                        }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex flex-sb'>
                            <img
                              src={Fulllogo}
                              alt='Carrertree'
                              style={{ width: '142px', height: '21.25px' }}
                            />
                            <Typography component={'div'}>
                              <Typography component={'span'} className='report-title'>
                                CTC Wise Monthly
                                <Typography component={'span'} className='report-title-name'>
                                  {employeeName}
                                </Typography>
                              </Typography>
                            </Typography>
                            <Typography
                              component={'span'}
                              className='page-title d-flex-cc'
                              style={{ marginRight: '25px' }}>
                              <img
                                src={Chart}
                                alt='Chart'
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              />
                              Graph & Table View
                            </Typography>
                          </Typography>
                          <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                        </Grid>
                        <CvStatusBar data={graphData} setParams={setParams} type={'CvStatus'} />
                        <Grid item md={12} sm={12} xs={12}>
                          <Divider
                            sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                          />
                        </Grid>
                      </div>
                    )}
                  {params.type === 'clientWise' &&
                    (params.report === 'graph' || params.report === 'all') && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',
                          overflow: 'auto',
                          margin: '0 auto',
                          transform: `scale(${scale})`,
                          transition: 'transform 0.3s ease',
                        }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex flex-sb'>
                            <img
                              src={Fulllogo}
                              alt='Carrertree'
                              style={{ width: '142px', height: '21.25px' }}
                            />
                            <Typography component={'div'}>
                              <Typography component={'span'} className='report-title'>
                                {params.type === 'clientWise'
                                  ? 'Client Wise CTC'
                                  : params.type === 'monthWise' && 'Client Wise Monthly'}
                                <Typography component={'span'} className='report-title-name'>
                                  {employeeName}
                                </Typography>
                              </Typography>
                            </Typography>
                            <Typography
                              component={'span'}
                              className='page-title d-flex-cc'
                              style={{ marginRight: '25px' }}>
                              <img
                                src={Chart}
                                alt='Chart'
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              />
                              Graph & Table View
                            </Typography>
                          </Typography>
                          <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                        </Grid>
                        <CustomBarChart data={graphData} type={'CvStatus'} setParams={setParams} />
                        <Grid item md={12} sm={12} xs={12}>
                          <Divider
                            sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                          />
                        </Grid>
                      </div>
                    )}
                  {params.type === 'monthWise' &&
                    (params.report === 'graph' || params.report === 'all') && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',
                          overflow: 'auto',
                          margin: '0 auto',
                          transform: `scale(${scale})`,
                          transition: 'transform 0.3s ease',
                        }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex flex-sb'>
                            <img
                              src={Fulllogo}
                              alt='Carrertree'
                              style={{ width: '142px', height: '21.25px' }}
                            />
                            <Typography component={'div'}>
                              <Typography component={'span'} className='report-title'>
                                {params.type === 'clientWise'
                                  ? 'Client Wise CTC'
                                  : params.type === 'monthWise' && 'Client Wise Monthly'}
                                <Typography component={'span'} className='report-title-name'>
                                  {employeeName}
                                </Typography>
                              </Typography>
                            </Typography>
                            <Typography
                              component={'span'}
                              className='page-title d-flex-cc'
                              style={{ marginRight: '25px' }}>
                              <img
                                src={Chart}
                                alt='Chart'
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              />
                              Graph & Table View
                            </Typography>
                          </Typography>
                          <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                        </Grid>
                        <LineGraph
                          data={graphData}
                          lineKeys={[
                            {
                              key: 'cvSentShortlistedPercentage',
                              color: '#82ca9d',
                              label: 'CV Sent Shortlisted %',
                            },
                            {
                              key: 'cvSentInterviewedPercentage',
                              color: '#8884d8',
                              label: 'CV Sent Interviewed %',
                            },
                            {
                              key: 'openingWorkedToBilledPercentage',
                              color: '#673147',
                              label: 'Opening Worked to Billed %',
                            },
                          ]}
                        />
                        <Grid item md={12} sm={12} xs={12}>
                          <Divider
                            sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                          />
                        </Grid>
                      </div>
                    )}
                  {(params.report === 'table' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography
                          component={'div'}
                          className='d-flex flex-sb'
                          sx={{ marginTop: '25px' }}>
                          <Typography component={'div'}>
                            <Typography component={'span'} className='report-title'>
                              {params.type === 'ctcWise'
                                ? 'CTC Wise Monthly'
                                : params.type === 'clientWise'
                                  ? 'Client Wise CTC'
                                  : params.type === 'monthWise' && 'Client Wise Monthly'}
                              <Typography component={'span'} className='report-title-name'>
                                {employeeName}
                              </Typography>
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='report-title-name'
                            sx={{ display: 'flex' }}>
                            Timeline :{' '}
                            <Typography
                              component={'span'}
                              className='report-title'
                              sx={{ marginRight: '30px' }}>
                              {moment(params.fromDate).format('DD/MM/YYYY')} -
                              {moment(params.toDate).format('DD/MM/YYYY')}
                            </Typography>
                            <ExcelIcon
                              url={`reports/cv-status/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=csv',
                                'report=table',
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'CV Status'}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                      </Grid>
                      {params.type === 'ctcWise' ? (
                        <Typography component={'div'} className='mt-32'>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              className='custom-table'
                              aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Stage</TableCell>
                                  {tableData.tableHeaders.slice(1).map((header: string) => (
                                    <TableCell key={header} sx={{ textTransform: 'capitalize' }}>
                                      {header}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(tableData.tableData[0])
                                  .filter((key) => key !== 'range')
                                  .map((stage: string) => (
                                    <TableRow key={stage}>
                                      <TableCell>{getRecruitmentStageValue(stage)}</TableCell>
                                      {tableData.tableHeaders.slice(1).map((header: string) => {
                                        const rowForHeader = tableData.tableData.find(
                                          (row: any) => row.range === header,
                                        );
                                        return (
                                          <TableCell key={`${stage}-${header}`}>
                                            {rowForHeader ? rowForHeader[stage] ?? '-' : '-'}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Typography>
                      ) : (
                        <Typography component={'div'} className='mt-32'>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              className='custom-table'
                              aria-label='simple table'>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Stage</TableCell>
                                  {tableData.tableHeaders.slice(1).map((header: string) => (
                                    <TableCell key={header} sx={{ textTransform: 'capitalize' }}>
                                      {header}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>

                              {params.type === 'clientWise' ? (
                                <TableBody>
                                  {Object.keys(tableData.tableData[0])
                                    .filter((key) => key !== 'id' && key !== 'corporateName')
                                    .map((stage: string) => (
                                      <TableRow key={stage}>
                                        <TableCell>{getRecruitmentStageValue(stage)}</TableCell>
                                        {tableData.tableHeaders.slice(1).map((header: string) => {
                                          const rowForHeader = tableData.tableData.find(
                                            (row: any) => row.corporateName === header,
                                          );
                                          return (
                                            <TableCell key={`${stage}-${header}`}>
                                              {rowForHeader ? rowForHeader[stage] ?? '-' : '-'}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    ))}
                                </TableBody>
                              ) : (
                                <TableBody>
                                  {Object.keys(tableData.tableData[0])
                                    .filter((key) => key !== 'month')
                                    .map((stage: string) => (
                                      <TableRow key={stage}>
                                        <TableCell>{getRecruitmentStageValue(stage)}</TableCell>
                                        {tableData.tableHeaders.slice(1).map((header: string) => {
                                          const rowForHeader = tableData.tableData.find(
                                            (row: any) => row.month === header,
                                          );
                                          return (
                                            <TableCell key={`${stage}-${header}`}>
                                              {rowForHeader ? rowForHeader[stage] ?? '-' : '-'}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    ))}
                                </TableBody>
                              )}
                            </Table>
                          </TableContainer>
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </Typography>
          </Typography>
        </Box>
      </Modal>
    </Grid>
  );
};
